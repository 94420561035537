@import "../var";
@import "../mix";

.about-banner {
    background: url(../img/about-banner.png) no-repeat;
    padding-top: 100px;
    padding-bottom: 94px;

    height: 100vh;

 


    @include respond-to(lg) {

        display: block;

        padding-top: 80px;
        padding-bottom: 80px;
        height: unset;
    }
    @include respond-to(md) {
        padding-top: 70px;
        background: url(../img/about-banner-m.png) no-repeat;

    }
    background-size: cover !important;

    .cnt {
        // max-width: 1236px;
        
        @media (min-width: 1024px) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            height: 100%;
        }
    }

    &__img {
        @include respond-to(lg) {
            width: 356px;
        }
        @include respond-to(md) {
            width: 238px;
        }
    }
    &__info {
        margin-top: 350px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include respond-to(lg) {
            margin-top: 260px;
            align-items: flex-start;
        }
        @include respond-to(md) {
            flex-wrap: wrap;
            justify-content: unset;
            align-items: unset;
        }
    }
    &__dsc {
        letter-spacing: -0.01em;
        @include font-size-get('p4');
        color: $_white;
        max-width: 740px;
        padding-right: 40px;
        @include respond-to(md) {
            padding-right: 0;
            margin-bottom: 32px;
        }
    }
    .button {

    }
}