@import "../../var";
@import "../../mix";

.certificate-row {
    margin-top: 40px;
}

.certificate-card {
    background: $_gray_20;
    border-radius: 15px;
    min-height: 340px;
    display: block;
    padding: 30px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: unset;



    &:hover {
        .download-icon {
            background: $_main_blue;
            transition: .3s ease;
            &::before {
                @media (min-width: 1024px) {
                    animation: arrowAnimation2 1s forwards;
                }
            }
        }
    }


    @include respond-to(lg) {
        min-height: 252px;
        padding: 24px;
        margin-bottom: 16px;
    }
    @include respond-to(md) {
        min-height: 236px;
        padding: 20px;
        margin-bottom: 12px;
    }
    &__img {
        width: 130px;
        height: 60px;

        @include respond-to(lg) {
            width: 117px;
            height: 54px;
        }
        @include respond-to(md) {
            width: 104px;
            height: 48px;
        }

        img {
            display: block;
            object-fit: contain;
            width: 100%;
            height: 100%;
            object-position: left;
        }
    }
    &__title {
        margin-top: 20px;
        @include font-size-get(p2);
        margin-bottom: 20px;

        span {
            color: #7E7E7F;
        }

        @include respond-to(lg) {
            margin-top: 8px;
        }
    }
}