@import "var";
@import "mix";


.button {
    border-radius: 15px;
    border: 2px solid $_main_blue;
    background: $_main_blue;
    padding: 19px 29px 16px 29px;
    @include font-size-get('p5');
    color: $_white;
    font-weight: 500;
    white-space: nowrap;
    

    cursor: pointer;
    display: inline-block;

    transition: .3s;


    &:hover {
       @media (min-width: 1024px) {
        background: $_dark_blue;
        border-color: $_dark_blue;
        color: #fff;
       }
    }

    @include respond-to(lg) {
        padding: 19px 25px 16px 25px;
        border-radius: 13px;
    }
    @include respond-to(md) {
        border-radius: 13px;
        width: 100%;
        text-align: center;
    }


    &--stroke {
        background: transparent;
        color: $_main_blue;
    }

    &--white {
        border: 2px solid $_white;
        background: $_white;
        color: $_black;
    }
    &--small {
        @include font-size-get('p1');
        padding-top: 12px;
        padding-bottom: 10px;
        border-radius: 11px;
        padding-left: 20px;
        padding-right: 20px;

        @include respond-to(lg) {
            padding-left: 18px;
            padding-right: 18px;
            padding-top: 10px;
            padding-bottom: 8px;
        }

    }

}

.button-next {

}

.button-next--disabled {
    background: #C9DFFF;
    border: 2px solid #C9DFFF;
    color: #F4F4F4;
    pointer-events: none;
}