@import "../../var";
@import "../../mix";

@import "../../var";
@import "../../mix";


#competentionSlider {
    @include respond-to(md) {
        overflow: visible;

        .row {
            margin: 0;
        }
        .swiper-wrapper {
            height: unset;
        }
        .swiper-slide {
            padding: 0 !important;
            max-width: 98% !important;
            height: unset !important;
        }
        .swiper-pagination {
            margin-top: 20px;
        }
        
    }
    .swiper-notification {
        display: none;
    }
}

.skills-card {
    border-radius: 15px;
    background: $_gray_20;
    margin-bottom: 20px;
    padding: 35px;
    padding-bottom: 30px;
    padding-left: 30px;
    width: 100%;
    height: unset;
    min-height: 470px;

    @include respond-to(lg) {
        padding: 30px;
        min-height: 376px;
    }

    @include respond-to(md) {
        padding: 20px;
    }

    &__title {
        @include font-size-get('h5');
        letter-spacing: -0.02em;

    }

    &__dsc {
        margin-top: 20px;
        @include font-size-get('p1');
        max-width: 420px;
        @include respond-to(lg) {
            margin-top: 12px;
        }
    }

    &-img {
        margin-top: 45px;
        display: flex;
        flex-wrap: wrap;

        margin-left: -10px;
        margin-right: -10px;

        @include respond-to(lg) {
            margin-left: -8px;
            margin-right: -8px;
            margin-top: 32px;
        }


        @include respond-to(md) {
            margin-left: -16px;
            margin-right: -16px;
            margin-top: 30px;
        }

        div {
            flex-basis: 132px;
            // margin-right: 10px;
            @include flex-c-c;
            margin-bottom: 10px;

            padding-right: 10px;
            padding-left: 10px;

            filter: grayscale(1);
            transition: .3s;

            @media (max-width: 1024px) {
                filter: unset;
            }

            &:hover {
                filter: grayscale(0);
                transition: .3s;
            }


            @include respond-to(lg) {
                padding-right: 4px;
                padding-left: 4px;
                flex-basis: 84px;
            }


            @include respond-to(md) {
                flex-basis: 104px;
                padding-right: 16px;
                padding-left: 16px;
                margin-bottom: 20px;
            }
            
            img {
                display: block;
            }
        }
    }

}
