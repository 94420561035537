@import "../../var";
@import "../../mix";


.tarifs {
    animation: .6s forwards fadeIn;
}

.tariff-calc {
    background: #282829;
    border-radius: 15px 15px 0px 0px;
    overflow: hidden;
    padding: 40px;
    padding-right: 124px;
    padding-bottom: 25px;
    display: flex;

    margin-top: 50px;

    @include respond-to(lg) {
        flex-wrap: wrap;
        padding: 36px;
    }
    @include respond-to(md) {
        padding: 24px;
        padding-bottom: 48px;
        border-radius: 15px;

    }


    &__subtitle {
        @include font-size-get(p1);
        font-weight: 500;
        color: $_gray_60;
    }
    &__title {
        @include font-size-get(p6);
        letter-spacing: -0.01em;
        color: #FFFFFF;
        max-width: 228px;
        margin-bottom: 18px;
        @include respond-to(lg) {
            max-width: 100%;
            margin-top: 20px;
        }
        @include respond-to(md) {
            margin-bottom: 0;
        }

    }

    &-col {
        &:first-child {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            flex-basis: 37%;
            max-width: 37%;
            @include respond-to(lg) {
                flex-basis: 100%;
                max-width: 100%;
            }
        

        }
        &:last-child {
            flex-basis: 63%;
            max-width: 63%;
            @include respond-to(lg) {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 45px;

            }
        
        }
    }

    .range-header__title {
        color: #F4F4F4;
        @include font-size-get(p1);
        @include respond-to(lg) {
            margin-bottom: 12px !important;
        }

    }
    .range-header__count {
        color: #F4F4F4;
    }

    .range-row {
        margin: 0 -25px;
        @include respond-to(md) {
            margin: 0;
        }
    }

    .range-row .col {
        padding: 0  25px;
        @include respond-to(md) {
            padding: 0;
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .range-footer div:first-child span {
        margin-right: 4px;
        @include respond-to(lg) {
            margin-right: 9px;
        }
        @include respond-to(md) {
            margin-right: calc((100vw - 335px)/ 10);
        }
    }
    .range-header__title {
        margin-bottom: 6px;
    }
    .days {
        width: 40px;
        height: 40px;
        @include respond-to(lg) {
            width: 48px;
            height: 48px;
            margin-right: 16px;
            @include flex-c-c;
        }
        @include respond-to(md) {
            margin-right: 14px;
        }
        input {
            display: none;
        }
        label {
            
            
        }
    }

    .col {
        &:last-child {
            .range-wrap {
                margin-top: 6px;
                @include respond-to(md) {
                    margin-top: 2px;
                    margin-bottom: 0;
                }
            }
        }
    }


    .range-footer {
        @include respond-to(lg) {
            margin-top: 14px !important;
        }
        @include respond-to(md) {
            margin-top: 20px !important;
        }
    }        
}

.table.mt-50 {
    margin-top: 50px;
}


.table-title {
    &+.table-wrap .table {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
    }
}