@import "../../var";
@import "../../mix";


.category {

    margin-left: 0 !important;

    position: relative;

    margin-top: 50px;

    @include respond-to(md) {
        margin-top: 36px;
    }


    .swiper-container {
        border-radius: 50px;
        background: #F4F4F4 !important;
        width: calc(100% - 26px);
        margin-left: 0 !important;
        padding-right: 46px;
        @include respond-to(md) {
            width: 100%;
            padding-right: 20px;
        }
    }
    
    .swiper-wrapper {
        padding: 5px;

    }

    &__item {
        display: inline-block !important;

        font-weight: 500;
        @include font-size-get(p1);
        border-radius: 50px;
        padding: 14px 18px 14px 18px;

        color: $_gray_40;
        background: $_gray_20;

        flex-shrink: inherit !important;

        white-space: nowrap;

        width: fit-content;

        transition: .3s;
        
        &:hover {
            color: $_main_blue;
        }

        &--active {
            background: $_main_blue;
            color: #fff !important;
        }

    }

    &__next-btn {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        z-index: 2;
        height: 58px;
        display: flex;
        align-items: center;
        background: #FFFFFF;

        outline: unset !important;

        @include respond-to(md) {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            left: -26px;
            width: 26px;
            height: 100%;
            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        }

    }
    &__prev-btn {
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        z-index: 2;
        height: 58px;
        display: flex;
        align-items: center;
        background: #FFFFFF;

        outline: unset !important;

        @include respond-to(md) {
            display: none;
        }

        &::after {
            content: '';
            position: absolute;
            right:  -26px;
            width: 26px;
            height: 100%;
            background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            transform: scale(-1,-1);

        }

    }

    .swiper-notification {
        display: none;
    }


}

.category-slider-init {
    .swiper-container {
        @include respond-to(md) {
            width: calc(100% + 16px);
            max-width: calc(100% + 16px);
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}
.category__prev-btn.swiper-button-disabled,
.category__next-btn.swiper-button-disabled {
    display: none;
}