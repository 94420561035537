@import "../var";
@import "../mix";


.services-card {
    border-radius: 15px;
    background: $_black;
    padding-left: 30px;
    height: 360px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;

    display: block;

    @include respond-to(lg) {
        height: 460px;

    }
    @include respond-to(md) {
        height: 400px;
        padding-left: 24px;
        margin-bottom: 8px;
        padding-right: 20px;
    }

    &:hover {
        .arrow-icon {
            background: $_main_blue;
            transition: .3s ease;
            &::after {
                @media (min-width: 1024px) {
                    animation: arrowAnimation 1s forwards;
                }
            }
        }
    }

    .arrow-icon {
        position: absolute;
        left: 30px;
        bottom: 30px;
        @include respond-to(md) {
            left: 24px;
            bottom: 24px;

        }
    }
    
    &__title {
        @include font-size-get(h5);
        letter-spacing: -0.02em;
        color: $_white;
        max-width: 330px;
        padding-top: 35px;
        z-index: 2;
        position: relative;
        @include respond-to(md) {
            padding-top: 24px;
        }
    }
    &__dsc {
        max-width: 330px;
        margin-top: 20px;
        color: $_gray_60;
        z-index: 2;
        position: relative;
        @include font-size-get(p1);
        @include respond-to(lg) {
            margin-top: 12px;
        }
        @include respond-to(md) {

        }
    }
    &__img {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 40%;

        @include respond-to(lg) {
            width: 60%;
        }
        @include respond-to(md) {
            width: 245px;
        }

    }
}