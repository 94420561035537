@import "var";
@import "mix";

.header {

    height: 70px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;

    z-index: 5;

    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04);

    transition: .3s ease;

    &-main-page {
        background: transparent;
        box-shadow: unset;
    }


    @include respond-to(lg) {
        height: 50px
    }

    @include respond-to(md) {
        height: 48px;
    }

    .cnt {
        @include flex-c-sb;
        height: inherit;
        position: relative;
    }

    &__logo {
        display: flex;



        @include respond-to(lg) {
            width: 74px;
        }

        @include respond-to(md) {
            width: 80px;
        }
    }

    &__links {
        margin-left: 50px;
        margin-right: auto;
        margin-top: 4px;
        display: flex;
        position: relative;

        @media (max-width: 900px) {
            margin-left: 20px;
        }


        &-col {
            &:nth-child(2) {

                @include respond-to(lg) {
                    position: absolute;
                    right: -154px;
                    top: 37px;
                    background: #FFFFFF;
                    border-radius: 15px;
                    padding: 10px 15px;
                    min-width: 196px;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

                    display: none;

                    a {
                        display: block;
                        margin-bottom: 20px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @include respond-to(lg) {
            margin-left: 30px;
        }

        @include respond-to(md) {
            display: none;
        }

    }

    &__link {
        @include font-size-get(p1);
        margin-right: 24px;
        font-weight: 500;
        transition: .3s ease;

        &:hover {
            color: $_main_blue !important;
            transition: .3s ease;
            path {
                fill: $_main_blue !important;
                transition: .3s ease;

            }
        }

        @media (max-width: 900px) {
            margin-right: 18px;
        }

        &:last-child {}

        &--services {
            display: inline-flex;
            align-items: center;

            svg {
                margin-left: 4px;
            }
        }
    }

    &__phone {

        transition: .3s ease;
        margin-right: 32px;

        @include respond-to(lg) {
            margin-right: 22px;
        }


        @include respond-to(md) {
            display: none;
        }

        &-title {
            font-size: 12px;
            line-height: 14px;
            color: $_black;
            opacity: 0.8;
            display: block;
        }

        &-link {
            @include font-size-get(p1);
            font-weight: 500;
            color: $_main_blue;
            opacity: 0.8;
            display: block;
            margin-top: 1px;
        }
    }

    &__mobile-menu-btn {
        display: none;

        @include respond-to(md) {
            display: flex;
        }
    }


    &__dropdown {
        position: absolute;
        top: 77px;
        left: 152px;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
        border-radius: 15px;
        padding: 20px;
        max-width: 931px;
        width: 100%;
        flex-wrap: wrap;
        display: none;

        @include respond-to(lg) {
            max-width: 756px;
            top: 57px;
            left: 60px;
        }

        @media (max-width: 850px) {
            max-width: calc(100% - 80px);
        }

        @include respond-to(md) {
            display: none;
        }

        .row {
            margin: 0 -10px;
            flex-wrap: wrap;

            .col {
                padding: 0 10px;
                flex-basis: 50%;
                max-width: 50%;
            }
        }

        &-link {
            // width: 100%;
            flex-basis: 50%;
            max-width: 50%;
            padding: 15px;
            display: block;
            border-radius: 10px;

            &:hover {
                background: $_gray_20;
            }

            span {
                display: block;

                &:first-child {
                    @include font-size-get(p5);
                    font-weight: 500;
                }

                &:last-child {
                    @include font-size-get(p1);
                    color: $_gray_60;
                    margin-top: 2px;
                }
            }
        }



    }

    &__btn {
        cursor: pointer;
        display: none;

        @include respond-to(lg) {
            @include flex-c;
        }

    }


    &—black {
        background: $_black;
        box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04);

        .header__link {
            color: #FFFFFF;

            path {
                fill: #fff;
            }
        }

        .header__phone-title {
            color: #fff;
        }

        .logo-letter {
            fill: #fff
        }
        .header__mobile-menu-btn {
            line {
                stroke:  #fff;
            }
        }
        .header__btn {
            circle {
                fill:  #fff;
            }
        }
    }


    .button--small {
        @include respond-to(lg) {
            padding-left: 10px;
            padding-right: 10px;
        }
        @include respond-to(md) {
            display: none !important;
        }
    }
}

.header__link--services {
    svg {
        transition: .3s;
    }
}

.header__link--services.header-link-active {
    svg {
        transform: rotate(180deg);
        transition: .3s;
    }
}

.show-dropdown {
    display: flex;
    animation: showDropdown .3s forwards;
}

@keyframes showDropdown {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.header-main-page {
    .button--small {
        position: absolute;
        right: 40px;
        top: 12px;
        opacity: 0;
        visibility: hidden;

        @include respond-to(lg) {
            top: 5px;
        }

        @media (max-width: 850px) {
            right: 20px;
        }
    }
}


.header-main-page.scroll {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);

    .button--small {
        display: block;

        visibility: visible;
        opacity: 1;
        transition: .1s .4s;

        // transition-property: transform; 
    }

    .header__phone {
        transform: translateX(-215px);
        transition: .4s ease;

        @media (max-width: 900px) {
            transform: translateX(-176px);
        }
    }
}

.header.scroll {
    background: #FFFFFF;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04);

    .header__link {
        color: $_black;

        path {
            fill: $_black;
        }
    }

    .header__phone-title {
        color: $_black;
    }

    .logo-letter {
        fill: $_black
    }
}

.header—black.scroll {
    .header__mobile-menu-btn line {
        stroke: #090207;
    }
     .header__btn circle {
        fill: #090207 ;
    }
}

.header__links-col.show {
    .header__link {
        color: $_black;
    }
}


.header__link.active {
    color: #247cff !important;
    path {
        fill: #247cff !important;
    }
}