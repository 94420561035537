@import "../../var";
@import "../../mix";


.principles-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include respond-to(lg) {
        margin-top: 60px;
    }
    @include respond-to(md) {
        margin-top: 40px;
    }

    .col {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 10px;
        margin-top: 80px;

        @include respond-to(lg) {
            margin-top: 50px;
        }

        @include respond-to(md) {
            flex-basis: 100%;
            max-width: 100%;
        }

        &:first-child,
        &:nth-child(2) {
            margin-top: 0;
        }
        @include respond-to(md) {
            margin-top: 28px;
            &:nth-child(2) {
                margin-top: 28px;
            }
        }
    }
}

.values-card {

    @include respond-to(lg) {
        display: flex;
    }

  
    &__img {
        width: 80px;
        height: 80px;
        border-radius: 15px;

        @include respond-to(lg) {
            width: 76px;
            height: 76px;
            margin-right: 20px;

        }
        @include respond-to(md) {
            width: 72px;
            height: 72px;
        }
    }
    &__title {
        @include font-size-get(p2);
        font-weight: 500;
        margin-top: 40px;

        @include respond-to(lg) {
            margin-top: 0;
        }
        
    }
    &__dsc {
        margin-top: 10px;
        @include font-size-get(p1);

    }
}