@import "../../var";
@import "../../mix";

.stat {
    .col-xs-9 {
        padding-right: 100px;
        @include respond-to(lg) {
            orphans: 0;
        }
    }
}

.stat-item {
    border-top: 1px solid $_gray_40;
    margin-bottom: 42px;
    @include respond-to(lg) {
        margin-bottom: 34px;
        max-width: 190px;
        margin-left: auto;
    }

    @include respond-to(md) {
        margin-bottom: 24px;
        max-width: 100%;
        &:first-child {
            margin-top: 40px;
        }
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__count {
        @include font-size-get(h3);
        letter-spacing: -0.02em;
        font-weight: 500;
        margin-top: 20px;
        @include respond-to(lg) {
            margin-top: 16px;
        }
        @include respond-to(md) {
            margin-top: 12px;
        }
    }
    &__dsc {
        @include font-size-get(p1);
        color: $_gray_60;
        @include respond-to(lg) {
            margin-top: 4px;
        }
        @include respond-to(md) {
            margin-top: 0;
        }
    }
}