@charset "UTF-8";
.row {
  margin: 0 -10px; }

.row-offset-10 {
  margin: 0 -5px; }
  .row-offset-10 .col-xs-1,
  .row-offset-10 .col-xs-2,
  .row-offset-10 .col-xs-3,
  .row-offset-10 .col-xs-4,
  .row-offset-10 .col-xs-5,
  .row-offset-10 .col-xs-6,
  .row-offset-10 .col-xs-7,
  .row-offset-10 .col-xs-8,
  .row-offset-10 .col-xs-9,
  .row-offset-10 .col-xs-10,
  .row-offset-10 .col-xs-11,
  .row-offset-10 .col-xs-12 {
    padding: 0 5px; }

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  padding: 0 10px; }

@font-face {
  font-family: 'Suisse Int';
  src: url("../fonts/SuisseIntl-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Suisse Int';
  src: url("../fonts/SuisseIntl-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Suisse Int';
  src: url("../fonts/SuisseIntl-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

.arrow-icon {
  width: 40px;
  height: 40px;
  border-radius: 13px;
  background: #282829;
  position: relative;
  transition: .3s ease; }
  @media (max-width: 1139px) {
    .arrow-icon {
      width: 50px;
      height: 50px; } }
  .arrow-icon::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: url(../img/icoon-btn-arrow-1.svg) no-repeat;
    background-position: center !important; }
    @media (max-width: 1139px) {
      .arrow-icon::after {
        top: 10px;
        left: 10px;
        background: url(../img/icoon-btn-arrow-2.svg) no-repeat; } }

@keyframes arrowAnimation {
  0% {
    transform: rotate(0deg) translate(0, 0);
    opacity: 1; }
  50% {
    transform: rotate(0deg) translate(10px, 0);
    opacity: 0; }
  51% {
    opacity: 0;
    transform: rotate(-45deg) translate(-10px, 0px); }
  100% {
    opacity: 1;
    transform: rotate(-45deg) translate(0, 0); } }

.download-icon {
  width: 40px;
  height: 40px;
  border-radius: 13px;
  background: #535354;
  position: relative;
  transition: .3s ease; }
  @media (max-width: 1139px) {
    .download-icon {
      width: 50px;
      height: 50px; } }
  .download-icon::after {
    content: '';
    position: absolute;
    bottom: 10px;
    left: 9px;
    width: 23px;
    height: 9px;
    background: url(../img/download-icon-2.svg) no-repeat;
    background-position: center !important; }
    @media (max-width: 1139px) {
      .download-icon::after {
        display: none; } }
  .download-icon::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 17px;
    background: url(../img/download-icon-1.svg) no-repeat;
    left: 12px;
    top: 7px;
    background-position: center !important;
    background-size: contain; }
    @media (max-width: 1139px) {
      .download-icon::before {
        width: 50px;
        height: 50px;
        top: 0;
        left: 0;
        background: url(../img/download-icon.svg); } }

@keyframes arrowAnimation2 {
  0% {
    transform: translate(0, 0);
    opacity: 1; }
  50% {
    transform: translate(0px, 10px);
    opacity: 0; }
  51% {
    opacity: 0;
    transform: translate(0px, -10px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

.header {
  height: 70px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04);
  transition: .3s ease; }
  .header-main-page {
    background: transparent;
    box-shadow: unset; }
  @media (max-width: 1139px) {
    .header {
      height: 50px; } }
  @media (max-width: 767px) {
    .header {
      height: 48px; } }
  .header .cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: inherit;
    position: relative; }
  .header__logo {
    display: flex; }
    @media (max-width: 1139px) {
      .header__logo {
        width: 74px; } }
    @media (max-width: 767px) {
      .header__logo {
        width: 80px; } }
  .header__links {
    margin-left: 50px;
    margin-right: auto;
    margin-top: 4px;
    display: flex;
    position: relative; }
    @media (max-width: 900px) {
      .header__links {
        margin-left: 20px; } }
    @media (max-width: 1139px) {
      .header__links-col:nth-child(2) {
        position: absolute;
        right: -154px;
        top: 37px;
        background: #FFFFFF;
        border-radius: 15px;
        padding: 10px 15px;
        min-width: 196px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07);
        display: none; }
        .header__links-col:nth-child(2) a {
          display: block;
          margin-bottom: 20px; }
          .header__links-col:nth-child(2) a:last-child {
            margin-bottom: 0; } }
    @media (max-width: 1139px) {
      .header__links {
        margin-left: 30px; } }
    @media (max-width: 767px) {
      .header__links {
        display: none; } }
  .header__link {
    margin-right: 24px;
    font-weight: 500;
    transition: .3s ease; }
    @media (min-width: 350px) {
      .header__link {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .header__link {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .header__link {
        font-size: 15px;
        line-height: 20px; } }
    .header__link:hover {
      color: #247CFF !important;
      transition: .3s ease; }
      .header__link:hover path {
        fill: #247CFF !important;
        transition: .3s ease; }
    @media (max-width: 900px) {
      .header__link {
        margin-right: 18px; } }
    .header__link--services {
      display: inline-flex;
      align-items: center; }
      .header__link--services svg {
        margin-left: 4px; }
  .header__phone {
    transition: .3s ease;
    margin-right: 32px; }
    @media (max-width: 1139px) {
      .header__phone {
        margin-right: 22px; } }
    @media (max-width: 767px) {
      .header__phone {
        display: none; } }
    .header__phone-title {
      font-size: 12px;
      line-height: 14px;
      color: #090207;
      opacity: 0.8;
      display: block; }
    .header__phone-link {
      font-weight: 500;
      color: #247CFF;
      opacity: 0.8;
      display: block;
      margin-top: 1px; }
      @media (min-width: 350px) {
        .header__phone-link {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 768px) {
        .header__phone-link {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 1140px) {
        .header__phone-link {
          font-size: 15px;
          line-height: 20px; } }
  .header__mobile-menu-btn {
    display: none; }
    @media (max-width: 767px) {
      .header__mobile-menu-btn {
        display: flex; } }
  .header__dropdown {
    position: absolute;
    top: 77px;
    left: 152px;
    background: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
    border-radius: 15px;
    padding: 20px;
    max-width: 931px;
    width: 100%;
    flex-wrap: wrap;
    display: none; }
    @media (max-width: 1139px) {
      .header__dropdown {
        max-width: 756px;
        top: 57px;
        left: 60px; } }
    @media (max-width: 850px) {
      .header__dropdown {
        max-width: calc(100% - 80px); } }
    @media (max-width: 767px) {
      .header__dropdown {
        display: none; } }
    .header__dropdown .row {
      margin: 0 -10px;
      flex-wrap: wrap; }
      .header__dropdown .row .col {
        padding: 0 10px;
        flex-basis: 50%;
        max-width: 50%; }
    .header__dropdown-link {
      flex-basis: 50%;
      max-width: 50%;
      padding: 15px;
      display: block;
      border-radius: 10px; }
      .header__dropdown-link:hover {
        background: #F4F4F4; }
      .header__dropdown-link span {
        display: block; }
        .header__dropdown-link span:first-child {
          font-weight: 500; }
          @media (min-width: 350px) {
            .header__dropdown-link span:first-child {
              font-size: 15px;
              line-height: 17px; } }
          @media (min-width: 768px) {
            .header__dropdown-link span:first-child {
              font-size: 15px;
              line-height: 17px; } }
          @media (min-width: 1140px) {
            .header__dropdown-link span:first-child {
              font-size: 16px;
              line-height: 18px; } }
        .header__dropdown-link span:last-child {
          color: #7E7E7F;
          margin-top: 2px; }
          @media (min-width: 350px) {
            .header__dropdown-link span:last-child {
              font-size: 14px;
              line-height: 18px; } }
          @media (min-width: 768px) {
            .header__dropdown-link span:last-child {
              font-size: 14px;
              line-height: 18px; } }
          @media (min-width: 1140px) {
            .header__dropdown-link span:last-child {
              font-size: 15px;
              line-height: 20px; } }
  .header__btn {
    cursor: pointer;
    display: none; }
    @media (max-width: 1139px) {
      .header__btn {
        display: flex;
        align-items: center; } }
  .header—black {
    background: #090207;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04); }
    .header—black .header__link {
      color: #FFFFFF; }
      .header—black .header__link path {
        fill: #fff; }
    .header—black .header__phone-title {
      color: #fff; }
    .header—black .logo-letter {
      fill: #fff; }
    .header—black .header__mobile-menu-btn line {
      stroke: #fff; }
    .header—black .header__btn circle {
      fill: #fff; }
  @media (max-width: 1139px) {
    .header .button--small {
      padding-left: 10px;
      padding-right: 10px; } }
  @media (max-width: 767px) {
    .header .button--small {
      display: none !important; } }

.header__link--services svg {
  transition: .3s; }

.header__link--services.header-link-active svg {
  transform: rotate(180deg);
  transition: .3s; }

.show-dropdown {
  display: flex;
  animation: showDropdown .3s forwards; }

@keyframes showDropdown {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.header-main-page .button--small {
  position: absolute;
  right: 40px;
  top: 12px;
  opacity: 0;
  visibility: hidden; }
  @media (max-width: 1139px) {
    .header-main-page .button--small {
      top: 5px; } }
  @media (max-width: 850px) {
    .header-main-page .button--small {
      right: 20px; } }

.header-main-page.scroll {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.07); }
  .header-main-page.scroll .button--small {
    display: block;
    visibility: visible;
    opacity: 1;
    transition: .1s .4s; }
  .header-main-page.scroll .header__phone {
    transform: translateX(-215px);
    transition: .4s ease; }
    @media (max-width: 900px) {
      .header-main-page.scroll .header__phone {
        transform: translateX(-176px); } }

.header.scroll {
  background: #FFFFFF;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04); }
  .header.scroll .header__link {
    color: #090207; }
    .header.scroll .header__link path {
      fill: #090207; }
  .header.scroll .header__phone-title {
    color: #090207; }
  .header.scroll .logo-letter {
    fill: #090207; }

.header—black.scroll .header__mobile-menu-btn line {
  stroke: #090207; }

.header—black.scroll .header__btn circle {
  fill: #090207; }

.header__links-col.show .header__link {
  color: #090207; }

.header__link.active {
  color: #247cff !important; }
  .header__link.active path {
    fill: #247cff !important; }

.button {
  border-radius: 15px;
  border: 2px solid #247CFF;
  background: #247CFF;
  padding: 19px 29px 16px 29px;
  color: #FFFFFF;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  transition: .3s; }
  @media (min-width: 350px) {
    .button {
      font-size: 15px;
      line-height: 17px; } }
  @media (min-width: 768px) {
    .button {
      font-size: 15px;
      line-height: 17px; } }
  @media (min-width: 1140px) {
    .button {
      font-size: 16px;
      line-height: 18px; } }
  @media (min-width: 1024px) {
    .button:hover {
      background: #0962E5;
      border-color: #0962E5;
      color: #fff; } }
  @media (max-width: 1139px) {
    .button {
      padding: 19px 25px 16px 25px;
      border-radius: 13px; } }
  @media (max-width: 767px) {
    .button {
      border-radius: 13px;
      width: 100%;
      text-align: center; } }
  .button--stroke {
    background: transparent;
    color: #247CFF; }
  .button--white {
    border: 2px solid #FFFFFF;
    background: #FFFFFF;
    color: #090207; }
  .button--small {
    padding-top: 12px;
    padding-bottom: 10px;
    border-radius: 11px;
    padding-left: 20px;
    padding-right: 20px; }
    @media (min-width: 350px) {
      .button--small {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .button--small {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .button--small {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .button--small {
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 10px;
        padding-bottom: 8px; } }

.button-next--disabled {
  background: #C9DFFF;
  border: 2px solid #C9DFFF;
  color: #F4F4F4;
  pointer-events: none; }

.main-banner {
  padding-top: 100px;
  overflow: hidden;
  position: relative; }
  .main-banner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #F4F4F4;
    z-index: -2; }
  .main-banner .cnt {
    position: relative; }
  @media (max-width: 1139px) {
    .main-banner {
      padding-top: 38px; } }
  .main-banner__title {
    margin-top: 32px; }
    @media (min-width: 350px) {
      .main-banner__title {
        font-size: 46px;
        line-height: 44px; } }
    @media (min-width: 768px) {
      .main-banner__title {
        font-size: 90px;
        line-height: 92px; } }
    @media (min-width: 1140px) {
      .main-banner__title {
        font-size: 101px;
        line-height: 101px; } }
    @media (max-width: 1139px) {
      .main-banner__title {
        margin-top: 82px; } }
    @media (max-width: 767px) {
      .main-banner__title {
        margin-top: 55px; } }
  .main-banner__dsc {
    max-width: 700px;
    letter-spacing: -0.02em; }
    @media (min-width: 350px) {
      .main-banner__dsc {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .main-banner__dsc {
        font-size: 28px;
        line-height: 30px; } }
    @media (min-width: 1140px) {
      .main-banner__dsc {
        font-size: 32px;
        line-height: 33px; } }
  .main-banner__buttons {
    display: flex;
    margin-top: 40px;
    padding-bottom: 90px; }
    @media (max-width: 1139px) {
      .main-banner__buttons {
        padding-bottom: 76px; } }
    @media (max-width: 767px) {
      .main-banner__buttons {
        flex-wrap: wrap;
        margin-top: 50px; } }
    .main-banner__buttons .button {
      margin-right: 15px; }
      @media (max-width: 767px) {
        .main-banner__buttons .button {
          margin-right: 0;
          margin-bottom: 8px;
          width: 100%; } }
      .main-banner__buttons .button:last-child {
        margin-right: 0;
        margin-bottom: 0; }
  .main-banner__info {
    margin-top: 216px; }
    @media (max-width: 1139px) {
      .main-banner__info {
        margin-top: 240px; } }
    @media (max-width: 767px) {
      .main-banner__info {
        margin-top: 255px; } }
  .main-banner__svg {
    position: absolute;
    width: 1148px;
    right: -41%;
    bottom: 0;
    display: flex;
    z-index: -1;
    opacity: 0;
    animation: fadeIn 1s .3s forwards; }
    .main-banner__svg picture {
      display: flex; }
    @media (max-width: 1139px) {
      .main-banner__svg {
        width: 647px;
        right: 0; } }
    @media (max-width: 767px) {
      .main-banner__svg {
        width: 316px;
        right: 0;
        bottom: unset;
        top: -90px; } }
  .main-banner .main-banner__path {
    position: absolute;
    left: 166px;
    bottom: 70px;
    opacity: 0;
    visibility: hidden; }
    @media (max-width: 1139px) {
      .main-banner .main-banner__path {
        left: 100px; } }
  .main-banner .animation-item {
    position: absolute;
    width: 822px;
    height: 682px;
    top: -140px;
    left: -150px;
    z-index: -1;
    pointer-events: none; }
    @media (max-width: 767px) {
      .main-banner .animation-item {
        left: unset;
        right: 0;
        width: unset;
        height: unset;
        top: 70px;
        animation: mainPageAnimationItem 4s infinite linear; } }

@keyframes mainPageAnimationItem {
  0% {
    transform: translateY(0px); }
  50% {
    transform: translateY(-250px); }
  100% {
    transform: translateY(0px); } }

.services-card {
  border-radius: 15px;
  background: #090207;
  padding-left: 30px;
  height: 360px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  display: block; }
  @media (max-width: 1139px) {
    .services-card {
      height: 460px; } }
  @media (max-width: 767px) {
    .services-card {
      height: 400px;
      padding-left: 24px;
      margin-bottom: 8px;
      padding-right: 20px; } }
  .services-card:hover .arrow-icon {
    background: #247CFF;
    transition: .3s ease; }
    @media (min-width: 1024px) {
      .services-card:hover .arrow-icon::after {
        animation: arrowAnimation 1s forwards; } }
  .services-card .arrow-icon {
    position: absolute;
    left: 30px;
    bottom: 30px; }
    @media (max-width: 767px) {
      .services-card .arrow-icon {
        left: 24px;
        bottom: 24px; } }
  .services-card__title {
    letter-spacing: -0.02em;
    color: #FFFFFF;
    max-width: 330px;
    padding-top: 35px;
    z-index: 2;
    position: relative; }
    @media (min-width: 350px) {
      .services-card__title {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .services-card__title {
        font-size: 28px;
        line-height: 30px; } }
    @media (min-width: 1140px) {
      .services-card__title {
        font-size: 32px;
        line-height: 33px; } }
    @media (max-width: 767px) {
      .services-card__title {
        padding-top: 24px; } }
  .services-card__dsc {
    max-width: 330px;
    margin-top: 20px;
    color: #7E7E7F;
    z-index: 2;
    position: relative; }
    @media (min-width: 350px) {
      .services-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .services-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .services-card__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .services-card__dsc {
        margin-top: 12px; } }
  .services-card__img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 40%; }
    @media (max-width: 1139px) {
      .services-card__img {
        width: 60%; } }
    @media (max-width: 767px) {
      .services-card__img {
        width: 245px; } }

.advantages-card {
  position: relative;
  padding-top: 20px; }
  @media (max-width: 767px) {
    .advantages-card {
      padding-top: 24px;
      margin-bottom: 36px; } }
  .advantages-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    background: #BFBFBF;
    width: calc(100% - 15px); }
  .advantages-card__title {
    font-weight: 500; }
    @media (min-width: 350px) {
      .advantages-card__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .advantages-card__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .advantages-card__title {
        font-size: 20px;
        line-height: 24px; } }
  .advantages-card__dsc {
    color: #7E7E7F;
    margin-top: 10px; }
    @media (min-width: 350px) {
      .advantages-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .advantages-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .advantages-card__dsc {
        font-size: 15px;
        line-height: 20px; } }

@media (max-width: 767px) {
  .advantages-slider .swiper-slide {
    padding: 0;
    max-width: 244px !important; }
  .advantages-slider .row {
    margin-left: 0;
    margin-right: 0; } }

.about-banner {
  background: url(../img/about-banner.png) no-repeat;
  padding-top: 100px;
  padding-bottom: 94px;
  height: 100vh;
  background-size: cover !important; }
  @media (max-width: 1139px) {
    .about-banner {
      display: block;
      padding-top: 80px;
      padding-bottom: 80px;
      height: unset; } }
  @media (max-width: 767px) {
    .about-banner {
      padding-top: 70px;
      background: url(../img/about-banner-m.png) no-repeat; } }
  @media (min-width: 1024px) {
    .about-banner .cnt {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%; } }
  @media (max-width: 1139px) {
    .about-banner__img {
      width: 356px; } }
  @media (max-width: 767px) {
    .about-banner__img {
      width: 238px; } }
  .about-banner__info {
    margin-top: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 1139px) {
      .about-banner__info {
        margin-top: 260px;
        align-items: flex-start; } }
    @media (max-width: 767px) {
      .about-banner__info {
        flex-wrap: wrap;
        justify-content: unset;
        align-items: unset; } }
  .about-banner__dsc {
    letter-spacing: -0.01em;
    color: #FFFFFF;
    max-width: 740px;
    padding-right: 40px; }
    @media (min-width: 350px) {
      .about-banner__dsc {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .about-banner__dsc {
        font-size: 30px;
        line-height: 34px; } }
    @media (min-width: 1140px) {
      .about-banner__dsc {
        font-size: 38px;
        line-height: 41px; } }
    @media (max-width: 767px) {
      .about-banner__dsc {
        padding-right: 0;
        margin-bottom: 32px; } }

.principles-card {
  border-radius: 15px;
  background: #282829;
  padding: 40px 50px 50px 40px;
  height: 440px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1139px) {
    .principles-card {
      padding: 40px 32px; } }
  @media (max-width: 767px) {
    .principles-card {
      height: 300px;
      padding: 24px;
      padding-right: 32px;
      margin-bottom: 12px; } }
  .principles-card--big {
    margin-bottom: 10px;
    padding-right: 125px;
    flex-direction: unset; }
    @media (max-width: 1139px) {
      .principles-card--big {
        padding-right: 40px;
        height: 400px; } }
    @media (max-width: 767px) {
      .principles-card--big {
        height: unset;
        flex-wrap: wrap;
        padding: 24px;
        padding-right: 32px;
        padding-bottom: 32px; }
        .principles-card--big .principles-card__title {
          margin-top: 24px; } }
    .principles-card--big .col-1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 60%;
      max-width: 60%; }
      @media (max-width: 1139px) {
        .principles-card--big .col-1 {
          flex-basis: 50%;
          max-width: 50%; } }
      @media (max-width: 767px) {
        .principles-card--big .col-1 {
          flex-basis: 100%;
          max-width: 100%; } }
    .principles-card--big .col-2 {
      flex-basis: 40%;
      max-width: 40%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between; }
      @media (max-width: 1139px) {
        .principles-card--big .col-2 {
          flex-basis: 50%;
          max-width: 50%; } }
      @media (max-width: 767px) {
        .principles-card--big .col-2 {
          flex-basis: 100%;
          max-width: 100%;
          margin-top: 86px;
          align-items: flex-start; } }
    .principles-card--big .principles-card__title {
      max-width: 100%; }
  .principles-card__subtitle {
    font-weight: 500; }
    @media (min-width: 350px) {
      .principles-card__subtitle {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .principles-card__subtitle {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .principles-card__subtitle {
        font-size: 15px;
        line-height: 20px; } }
  .principles-card__title {
    letter-spacing: -0.01em;
    max-width: 425px; }
    @media (min-width: 350px) {
      .principles-card__title {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .principles-card__title {
        font-size: 30px;
        line-height: 34px; } }
    @media (min-width: 1140px) {
      .principles-card__title {
        font-size: 38px;
        line-height: 41px; } }
  .principles-card__numbers-title {
    font-weight: 500;
    font-size: 115px;
    line-height: 115px;
    letter-spacing: -0.01em; }
    @media (max-width: 1139px) {
      .principles-card__numbers-title {
        font-size: 82px;
        line-height: 82px; } }
    @media (max-width: 767px) {
      .principles-card__numbers-title {
        font-size: 80px;
        line-height: 80px; } }
  @media (max-width: 767px) {
    .principles-card__numbers:first-child {
      margin-bottom: 40px; } }
  .principles-card__numbers-dsc {
    font-weight: 500;
    color: #7E7E7F; }
    @media (min-width: 350px) {
      .principles-card__numbers-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .principles-card__numbers-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .principles-card__numbers-dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .principles-card__numbers-dsc {
        margin-top: 5px; } }
    @media (max-width: 767px) {
      .principles-card__numbers-dsc {
        margin-top: 0; } }

@media (max-width: 1139px) {
  .principles-card__numbers {
    max-width: 250px;
    margin-left: auto;
    margin-right: 0;
    width: 100%; } }

.client-card {
  border-radius: 15px;
  background: #F4F4F4;
  padding: 54px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 1139px) {
    .client-card {
      padding: 32px; } }
  @media (max-width: 767px) {
    .client-card {
      padding: 24px;
      padding-top: 32px; } }
  .client-card .row:nth-child(2) {
    position: relative;
    padding-top: 20px;
    margin-top: 86px; }
    @media (max-width: 767px) {
      .client-card .row:nth-child(2) {
        padding-top: 0;
        margin-top: 40px; } }
    .client-card .row:nth-child(2)::after {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      width: calc(100% - 30px);
      background: #BFBFBF;
      height: 1px; }
      @media (max-width: 767px) {
        .client-card .row:nth-child(2)::after {
          display: none; } }
  @media (min-width: 350px) {
    .client-card__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .client-card__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .client-card__dsc {
      font-size: 20px;
      line-height: 27px; } }
  @media (max-width: 767px) {
    .client-card__dsc {
      margin-top: 50px; } }
  @media (max-width: 1139px) {
    .client-card__logo {
      padding-right: 20px; } }
  @media (max-width: 767px) {
    .client-card__logo {
      width: 60%; } }
  .client-card-advantages {
    max-width: 230px; }
    @media (max-width: 767px) {
      .client-card-advantages {
        margin-top: 20px; } }
    .client-card-advantages__title {
      font-weight: 700; }
      @media (min-width: 350px) {
        .client-card-advantages__title {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 768px) {
        .client-card-advantages__title {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 1140px) {
        .client-card-advantages__title {
          font-size: 20px;
          line-height: 24px; } }
    .client-card-advantages__dsc {
      color: #7E7E7F;
      margin-top: 10px;
      display: block; }
      @media (min-width: 350px) {
        .client-card-advantages__dsc {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 768px) {
        .client-card-advantages__dsc {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 1140px) {
        .client-card-advantages__dsc {
          font-size: 15px;
          line-height: 20px; } }
      @media (max-width: 767px) {
        .client-card-advantages__dsc {
          margin-top: 6px; } }

.client-slider {
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .client-slider {
      margin-top: 60px; } }
  .client-slider .swiper-slide {
    max-width: 970px;
    transform: scale(0.97);
    transition: 1s ease;
    transform-origin: center;
    display: flex;
    height: unset; }
    @media (max-width: 900px) {
      .client-slider .swiper-slide {
        max-width: 700px; } }
    @media (max-width: 1139px) {
      .client-slider .swiper-slide {
        transform-origin: left; } }
    @media (max-width: 767px) {
      .client-slider .swiper-slide {
        max-width: 98% !important; } }
    @media (max-width: 767px) {
      .client-slider .swiper-slide {
        transform: scale(1); } }
  .client-slider .swiper-slide-active {
    transform: scale(1);
    transition: 1s ease; }
  .client-slider a {
    display: block; }

.how-works-block {
  border-top: 1px solid #BFBFBF;
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .how-works-block {
      margin-top: 60px; } }

.how-works-item {
  display: flex;
  position: relative;
  border-bottom: 1px solid #BFBFBF;
  cursor: pointer; }
  .how-works-item--active .how-works-item__title {
    color: #ff5e3b;
    transition: .3s ease; }
  .how-works-item--active .how-works-item__count {
    color: #ff5e3b;
    transition: .3s ease; }
  .how-works-item--active .how-works-item__icon path {
    fill: #ff5e3b;
    transition: .3s ease; }
  .how-works-item--active .how-works-item__icon svg {
    transition: .3s ease;
    transform: rotate(180deg); }
  .how-works-item .col:first-child {
    flex-basis: 25%;
    max-width: 25%;
    padding-top: 43px;
    padding-bottom: 33px; }
    @media (max-width: 1139px) {
      .how-works-item .col:first-child {
        padding-top: 34px; } }
    @media (max-width: 767px) {
      .how-works-item .col:first-child {
        flex-basis: 34px;
        max-width: 34px; } }
  .how-works-item .col:last-child {
    flex-basis: 75%;
    max-width: 75%;
    padding-top: 30px;
    padding-bottom: 40px; }
    @media (max-width: 1139px) {
      .how-works-item .col:last-child {
        padding-top: 22px;
        padding-bottom: 34px; } }
    @media (max-width: 767px) {
      .how-works-item .col:last-child {
        flex-basis: calc(100% - 34px);
        max-width: calc(100% - 34px);
        padding-top: 25px;
        padding-bottom: 6px; } }
  .how-works-item__count {
    font-weight: 500;
    transition: .3s ease; }
    @media (min-width: 350px) {
      .how-works-item__count {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .how-works-item__count {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .how-works-item__count {
        font-size: 15px;
        line-height: 20px; } }
  .how-works-item__title {
    letter-spacing: -0.01em;
    padding-bottom: 15px;
    padding-right: 40px;
    transition: .3s ease; }
    @media (min-width: 350px) {
      .how-works-item__title {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .how-works-item__title {
        font-size: 30px;
        line-height: 34px; } }
    @media (min-width: 1140px) {
      .how-works-item__title {
        font-size: 38px;
        line-height: 41px; } }
  .how-works-item__icon {
    position: absolute;
    right: 0;
    top: 42px; }
    @media (max-width: 1139px) {
      .how-works-item__icon {
        top: 32px; } }
  .how-works-item__dsc {
    color: #7E7E7F;
    padding-right: 40px;
    max-width: 740px;
    display: none; }
    @media (min-width: 350px) {
      .how-works-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .how-works-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .how-works-item__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 767px) {
      .how-works-item__dsc {
        margin-bottom: 14px; } }
  .how-works-item path,
  .how-works-item svg {
    transition: .3s ease; }

.input-wrap {
  margin-bottom: 28px;
  position: relative; }
  @media (max-width: 767px) {
    .input-wrap {
      margin-bottom: 36px; } }
  .input-wrap .error {
    position: absolute;
    bottom: -14px;
    left: 0;
    font-size: 12px;
    line-height: 12px;
    font-style: normal;
    color: #EB5757; }

.textarea-wrap {
  margin-bottom: 40px; }
  @media (max-width: 1139px) {
    .textarea-wrap {
      margin-bottom: 56px; } }

.input,
.textarea {
  width: 100%;
  border: unset;
  border-radius: unset;
  border-bottom: 1px solid #BFBFBF;
  padding: 9px 0;
  color: #282829; }
  @media (min-width: 350px) {
    .input,
    .textarea {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .input,
    .textarea {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .input,
    .textarea {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    .input,
    .textarea {
      padding: 7px 0; } }
  .input::placeholder,
  .textarea::placeholder {
    color: #BFBFBF; }

.textarea {
  resize: unset;
  height: 45px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .textarea {
      height: 36px; } }

.inputError {
  border-bottom: 1px solid #EB5757; }
  .inputError::placeholder {
    color: #EB5757; }

.form-audit {
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .form-audit {
      margin-top: 60px; } }

.form-audit__title,
.form-feedback__title {
  font-weight: 500;
  margin-bottom: 30px; }
  @media (min-width: 350px) {
    .form-audit__title,
    .form-feedback__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .form-audit__title,
    .form-feedback__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .form-audit__title,
    .form-feedback__title {
      font-size: 20px;
      line-height: 24px; } }

.form-audit .input-wrap,
.form-audit .textarea-wrap,
.form-feedback .input-wrap,
.form-feedback .textarea-wrap {
  padding-right: 90px;
  position: relative; }
  @media (max-width: 1139px) {
    .form-audit .input-wrap,
    .form-audit .textarea-wrap,
    .form-feedback .input-wrap,
    .form-feedback .textarea-wrap {
      padding-right: 70px; } }
  @media (max-width: 767px) {
    .form-audit .input-wrap,
    .form-audit .textarea-wrap,
    .form-feedback .input-wrap,
    .form-feedback .textarea-wrap {
      padding-right: 0; } }

.form-audit__dsc,
.form-feedback__dsc {
  font-size: 12px;
  line-height: 16px;
  color: #BFBFBF;
  transform: translate(0px, -5px); }

.form-button-wrap {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .form-button-wrap {
      align-items: unset;
      flex-wrap: wrap; } }
  @media (max-width: 767px) {
    .form-button-wrap .button {
      width: 100%;
      text-align: center;
      max-width: 100%;
      margin-top: 40px; } }
  .form-button-wrap span {
    padding-left: 20px;
    font-size: 12px;
    line-height: 16px;
    color: #7E7E7F;
    max-width: 282px; }
    @media (max-width: 767px) {
      .form-button-wrap span {
        padding-left: 0;
        margin-top: 10px;
        max-width: 260px; } }
    .form-button-wrap span a {
      color: #247CFF;
      transition: .3s ease; }
      .form-button-wrap span a:hover {
        color: #0962E5;
        transition: .3s ease; }

.modal .form-audit__title {
  font-weight: 500;
  margin-bottom: 20px; }
  @media (min-width: 350px) {
    .modal .form-audit__title {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .modal .form-audit__title {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .modal .form-audit__title {
      font-size: 15px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    .modal .form-audit__title {
      margin-bottom: 24px; } }

.modal .form-audit {
  margin-top: 40px; }

.modal .input-wrap,
.modal .textarea-wrap {
  margin-bottom: 18px; }
  @media (max-width: 1139px) {
    .modal .input-wrap,
    .modal .textarea-wrap {
      margin-bottom: 26px; } }

.modal .form-audit .input-wrap, .modal .form-audit .textarea-wrap {
  padding-right: 20px; }
  @media (max-width: 767px) {
    .modal .form-audit .input-wrap, .modal .form-audit .textarea-wrap {
      padding-right: 0; } }

@media (max-width: 767px) {
  .modal .form-button-wrap .button {
    margin-top: 10px; } }

@media (min-width: 350px) {
  .modal .input, .modal .textarea {
    font-size: 14px;
    line-height: 18px; } }

@media (min-width: 768px) {
  .modal .input, .modal .textarea {
    font-size: 14px;
    line-height: 18px; } }

@media (min-width: 1140px) {
  .modal .input, .modal .textarea {
    font-size: 15px;
    line-height: 20px; } }

.modal .textarea {
  min-height: 38px;
  padding-bottom: 0; }
  @media (max-width: 767px) {
    .modal .textarea {
      height: 32px; } }

.modal .form-button-wrap {
  margin-top: 10px; }

.modal .col-xs-4 {
  padding-left: 30px;
  padding-left: 34px; }
  .modal .col-xs-4 .form-audit__title {
    margin-bottom: 30px; }
    @media (max-width: 1139px) {
      .modal .col-xs-4 .form-audit__title {
        margin-bottom: 26px; } }
    @media (max-width: 767px) {
      .modal .col-xs-4 .form-audit__title {
        margin-bottom: 30px; } }
  @media (max-width: 1139px) {
    .modal .col-xs-4 {
      padding-left: 14px; } }
  @media (max-width: 767px) {
    .modal .col-xs-4 {
      padding-left: 10px;
      margin-top: 24px; } }

.radio-button {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 15px;
  cursor: pointer;
  color: #7E7E7F;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  @media (min-width: 350px) {
    .radio-button {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .radio-button {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .radio-button {
      font-size: 15px;
      line-height: 20px; } }
  .radio-button:hover {
    color: #247CFF; }
    .radio-button:hover .checkmark {
      border-color: #247CFF; }

/* Hide the browser's default radio button */
.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer; }

/* Create a custom radio button */
.radio-button .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #BFBFBF;
  transition: .3s; }
  @media (max-width: 767px) {
    .radio-button .checkmark {
      width: 16px;
      height: 16px; } }

/* On mouse-over, add a grey background color */
/* When the radio button is checked, add a blue background */
.radio-button input:checked ~ .checkmark {
  background-color: #247CFF;
  border: 1px solid #247CFF; }

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-button .checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the indicator (dot/circle) when checked */
.radio-button input:checked ~ .checkmark:after {
  display: block; }

/* Style the indicator (dot/circle) */
.radio-button .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white; }
  @media (max-width: 767px) {
    .radio-button .checkmark:after {
      top: 3px;
      left: 3px; } }

.footer {
  padding-top: 150px;
  margin-top: 200px;
  position: relative; }
  @media (max-width: 1139px) {
    .footer {
      padding-top: 160px; } }
  @media (max-width: 767px) {
    .footer {
      margin-top: 130px;
      padding-top: 80px; } }
  @media (max-width: 767px) {
    .footer .main-banner__path,
    .footer .animation-item {
      display: none !important; } }
  .footer::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -2;
    background: #090207; }
  .footer .cnt {
    position: relative; }
  .footer .row {
    justify-content: space-between; }
  .footer .col {
    padding: 0 10px; }
  .footer .col-1 {
    flex-basis: 274px;
    max-width: 274px; }
    @media (max-width: 1139px) {
      .footer .col-1 {
        flex-basis: 30%;
        max-width: 30%;
        order: 0; } }
    @media (max-width: 767px) {
      .footer .col-1 {
        flex-basis: 50%;
        max-width: 50%; } }
  .footer .col-2 {
    flex-basis: 310px;
    max-width: 310px; }
    @media (max-width: 1139px) {
      .footer .col-2 {
        flex-basis: 40%;
        max-width: 40%;
        order: 1; } }
    @media (max-width: 767px) {
      .footer .col-2 {
        flex-basis: 50%;
        max-width: 50%; } }
  .footer .col-3 {
    flex-basis: 276px;
    max-width: 276px; }
    @media (max-width: 1139px) {
      .footer .col-3 {
        flex-basis: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        order: 3;
        width: 100%;
        padding: 0; } }
  @media (max-width: 1139px) and (max-width: 767px) {
    .footer .col-3 {
      flex-basis: 100%;
      max-width: 100%;
      flex-wrap: wrap;
      margin-top: 40px; } }
    @media (max-width: 1139px) {
        .footer .col-3 .footer-block {
          margin-top: 46px;
          padding: 0 10px; } }
    @media (max-width: 1139px) and (max-width: 1139px) {
      .footer .col-3 .footer-block {
        max-width: 30%;
        flex-basis: 30%; } }
    @media (max-width: 1139px) and (max-width: 767px) {
      .footer .col-3 .footer-block {
        margin-top: 0;
        flex-basis: 50%;
        max-width: 50%;
        margin-bottom: 60px; } }
    @media (max-width: 1139px) {
          .footer .col-3 .footer-block:first-child {
            flex-basis: 30%;
            max-width: 30%; } }
      @media (max-width: 1139px) and (max-width: 767px) {
        .footer .col-3 .footer-block:first-child {
          flex-basis: 50%;
          max-width: 50%;
          order: 1; } }
    @media (max-width: 1139px) {
          .footer .col-3 .footer-block:nth-child(2) {
            flex-basis: 40%;
            max-width: 40%; } }
      @media (max-width: 1139px) and (max-width: 767px) {
        .footer .col-3 .footer-block:nth-child(2) {
          flex-basis: 50%;
          max-width: 50%;
          order: 0; } }
    @media (max-width: 1139px) {
          .footer .col-3 .footer-block:nth-child(3) {
            flex-basis: 30%;
            max-width: 30%; } }
      @media (max-width: 1139px) and (max-width: 767px) {
        .footer .col-3 .footer-block:nth-child(3) {
          flex-basis: 50%;
          max-width: 50%;
          order: 2; } }
    @media (max-width: 1139px) {
          .footer .col-3 .footer-block:nth-child(4) {
            flex-basis: 30%;
            max-width: 30%;
            padding-left: 40px; } }
      @media (max-width: 1139px) and (max-width: 767px) {
        .footer .col-3 .footer-block:nth-child(4) {
          flex-basis: 50%;
          max-width: 50%;
          order: 3;
          padding-left: 0; } }
  .footer .col-4 {
    flex-basis: 160px;
    max-width: 160px; }
    @media (max-width: 1139px) {
      .footer .col-4 {
        flex-basis: 30%;
        max-width: 30%;
        order: 2;
        padding-left: 40px; } }
    @media (max-width: 767px) {
      .footer .col-4 {
        flex-basis: 50%;
        max-width: 50%;
        padding-left: 0; } }
  .footer__title {
    color: #7E7E7F;
    margin-bottom: 30px; }
    @media (min-width: 350px) {
      .footer__title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .footer__title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .footer__title {
        font-size: 15px;
        line-height: 20px; } }
  .footer__link {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 16px;
    transition: .3s ease; }
    .footer__link:hover {
      color: #247CFF !important;
      transition: .3s ease; }
    @media (max-width: 1139px) {
      .footer__link {
        font-size: 16px;
        line-height: 18px; } }
    @media (max-width: 767px) {
      .footer__link {
        margin-bottom: 18px; } }
  .footer-block + .footer-block {
    margin-top: 50px; }
  @media (max-width: 767px) {
    .footer-block {
      margin-top: 54px; } }
  .footer__list-dsc {
    color: #FFFFFF;
    transform: translate(0px, -6px);
    display: block;
    color: #BFBFBF; }
    @media (min-width: 350px) {
      .footer__list-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .footer__list-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .footer__list-dsc {
        font-size: 15px;
        line-height: 20px; } }
  .footer__link-socials a {
    display: flex;
    align-items: center; }
    .footer__link-socials a svg {
      margin-right: 8px; }
  .footer .footer__svg {
    position: absolute;
    width: 134%;
    right: -74%;
    bottom: -30px;
    display: flex;
    z-index: -1; }
    @media (max-width: 1139px) {
      .footer .footer__svg {
        right: -95%;
        width: 180%; } }
    @media (max-width: 767px) {
      .footer .footer__svg {
        right: 0px;
        width: 100%; }
        .footer .footer__svg picture {
          width: 100%; }
        .footer .footer__svg img {
          display: block;
          margin-left: auto;
          object-fit: contain;
          width: 100%; } }
  .footer .main-banner__path-2 {
    position: absolute;
    left: 166px;
    bottom: 70px;
    opacity: 0;
    visibility: hidden; }
  .footer .animation-item {
    position: absolute;
    width: 822px;
    height: 682px;
    top: -140px;
    left: 0px;
    z-index: -1;
    pointer-events: none; }
    @media (max-width: 767px) {
      .footer .animation-item {
        display: none; } }

.copyright-row {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  margin-top: 64px;
  padding-bottom: 30px; }
  @media (max-width: 1139px) {
    .copyright-row {
      margin-top: 160px; } }
  @media (max-width: 767px) {
    .copyright-row {
      margin-top: 0;
      align-items: flex-end; } }
  @media (max-width: 767px) {
    .copyright-row div:first-child {
      display: flex;
      flex-direction: column; } }
  .copyright-row .copyright {
    color: #7E7E7F; }
    @media (max-width: 767px) {
      .copyright-row .copyright {
        order: 1; } }
    .copyright-row .copyright span {
      color: #FFFFFF; }
  .copyright-row .copyright__politic {
    color: #FFFFFF;
    margin-left: 40px; }
    @media (max-width: 767px) {
      .copyright-row .copyright__politic {
        margin-left: 0;
        order: 0;
        margin-bottom: 25px; } }
  .copyright-row .made-in {
    color: #7E7E7F; }
    .copyright-row .made-in a {
      color: #FFFFFF; }

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  font-family: Arial, sans-serif; }

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important; }

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0; }

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0; }

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1; }

.irs-handle.type_last {
  z-index: 2; }

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default; }

.irs-min {
  left: 0; }

.irs-max {
  right: 0; }

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap; }

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; }

.irs-with-grid .irs-grid {
  display: block; }

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000; }

.irs-grid-pol.small {
  height: 4px; }

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000; }

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2; }

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed; }

.irs-disabled {
  opacity: .4; }

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important; }

.irs--flat {
  height: 30px;
  margin-top: -15px; }

.irs--flat.irs-with-grid {
  height: 60px; }

.irs--flat .irs-line {
  top: 25px;
  height: 2px;
  background: #BFBFBF;
  border-radius: 0px; }

.irs--flat .irs-bar {
  top: 25px;
  height: 2px;
  background: #247CFF;
  cursor: pointer; }

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px; }

.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9; }

.irs--flat .irs-handle {
  top: 16px;
  width: 15px;
  height: 15px;
  background-color: transparent;
  cursor: pointer; }

.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 3px;
  left: 0px;
  width: 15px;
  height: 15px;
  margin-left: 0;
  background-color: #247CFF;
  border-radius: 5px; }
  @media (max-width: 1139px) {
    .irs--flat .irs-handle > i:first-child {
      width: 20px;
      height: 20px;
      top: 0px; } }
  .irs--flat .irs-handle > i:first-child::after {
    content: '';
    position: absolute;
    left: -7.5px;
    top: -7.5px;
    width: 30px;
    height: 30px; }
    @media (max-width: 1139px) {
      .irs--flat .irs-handle > i:first-child::after {
        width: 20px;
        height: 20px; } }

.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px;
  display: none; }

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px;
  display: none; }

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565; }

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9; }

.irs--flat .irs-grid-text {
  color: #999; }

.irs--big {
  height: 55px; }

.irs--big.irs-with-grid {
  height: 70px; }

.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: white;
  background: linear-gradient(to bottom, #ddd -50%, white 150%);
  border: 1px solid #ccc;
  border-radius: 12px; }

.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5); }

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px; }

.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66, 139, 202, 0.5); }

.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
  border-radius: 30px; }

.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0, 0, 0, 0.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%); }

.irs--big .irs-min,
.irs--big .irs-max {
  top: 0;
  padding: 1px 5px;
  color: white;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px; }

.irs--big .irs-from,
.irs--big .irs-to,
.irs--big .irs-single {
  color: white;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  border-radius: 3px; }

.irs--big .irs-grid-pol {
  background-color: #428bca; }

.irs--big .irs-grid-text {
  color: #428bca; }

.irs--modern {
  height: 55px; }

.irs--modern.irs-with-grid {
  height: 55px; }

.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px; }

.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom, #20b426 0%, #18891d 100%); }

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px; }

.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209, 214, 224, 0.5); }

.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: white;
  transform: rotate(45deg); }

.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1; }

.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%); }

.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2; }

.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a; }

.irs--modern .irs-min,
.irs--modern .irs-max {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: white;
  background-color: #d1d6e0;
  border-radius: 5px; }

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: white;
  border-radius: 5px; }

.irs--modern .irs-from:before,
.irs--modern .irs-to:before,
.irs--modern .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426; }

.irs--modern .irs-grid {
  height: 25px; }

.irs--modern .irs-grid-pol {
  background-color: #dedede; }

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1; }

.irs--sharp.irs-with-grid {
  height: 57px; }

.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: black;
  border-radius: 2px; }

.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa; }

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px; }

.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5); }

.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2; }

.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2; }

.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: black; }

.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: black; }

.irs--sharp .irs-min,
.irs--sharp .irs-max {
  color: white;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: .4;
  background-color: #a804b2;
  border-radius: 2px; }

.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: white;
  border-radius: 2px; }

.irs--sharp .irs-from:before,
.irs--sharp .irs-to:before,
.irs--sharp .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2; }

.irs--sharp .irs-grid {
  height: 25px; }

.irs--sharp .irs-grid-pol {
  background-color: #dedede; }

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--round {
  height: 50px; }

.irs--round.irs-with-grid {
  height: 65px; }

.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px; }

.irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #006cfa; }

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px; }

.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5); }

.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3); }

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #f0f6ff; }

.irs--round .irs-min,
.irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #006cfa;
  color: white;
  border-radius: 4px; }

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: "";
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #006cfa; }

.irs--round .irs-grid {
  height: 25px; }

.irs--round .irs-grid-pol {
  background-color: #dedede; }

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--square {
  height: 50px; }

.irs--square.irs-with-grid {
  height: 60px; }

.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede; }

.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: black; }

.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede; }

.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid black;
  background-color: white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff; }

.irs--square .irs-min,
.irs--square .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1); }

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: black;
  color: white; }

.irs--square .irs-grid {
  height: 25px; }

.irs--square .irs-grid-pol {
  background-color: #dedede; }

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px; }

.range-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .range-header__title {
    font-size: 14px;
    line-height: 16px;
    color: #535354; }
    @media (max-width: 767px) {
      .range-header__title {
        margin-bottom: 20px; } }
  .range-header__count {
    font-weight: 500;
    position: relative; }
    @media (min-width: 350px) {
      .range-header__count {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .range-header__count {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .range-header__count {
        font-size: 20px;
        line-height: 27px; } }
    .range-header__count::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 4px;
      width: 100%;
      height: 1px;
      background: #247CFF; }
      @media (max-width: 1139px) {
        .range-header__count::after {
          bottom: 3px; } }

.range-row {
  display: flex;
  margin: 0 -25px;
  flex-wrap: wrap; }
  .range-row .col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 24px; }
    @media (max-width: 767px) {
      .range-row .col {
        flex-basis: 100%;
        max-width: 100%; } }
    .range-row .col:last-child {
      transform: translate(0px, -8px); }
      @media (max-width: 767px) {
        .range-row .col:last-child {
          transform: unset; } }

.range-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px; }
  @media (max-width: 767px) {
    .range-footer {
      margin-top: 18px; } }
  .range-footer span {
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7F; }
  .range-footer div:first-child span {
    width: 20px;
    margin-right: 2px; }
    @media (max-width: 767px) {
      .range-footer div:first-child span {
        margin-right: calc((100vw - 335px)/ 10); } }

.range-wrap {
  margin-bottom: 50px; }
  @media (max-width: 767px) {
    .range-wrap {
      margin-bottom: 42px; } }
  @media (max-width: 1139px) {
    .range-wrap .days {
      display: flex;
      align-items: center;
      justify-content: center; } }

.days-row {
  display: flex;
  margin-top: 6px;
  flex-wrap: wrap;
  margin-right: -14px; }
  @media (max-width: 767px) {
    .days-row {
      margin-top: 0; } }

.days {
  height: 36px;
  width: 36px;
  border: 1px solid #7E7E7F;
  border-radius: 10px;
  position: relative;
  display: block;
  margin-right: 4px;
  transition: .3s;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }
  .days input {
    display: none; }
  @media (max-width: 1139px) {
    .days {
      width: 48px;
      height: 48px; } }
  @media (max-width: 767px) {
    .days {
      margin-right: 14px;
      margin-bottom: 14px; } }
  .days label {
    font-size: 14px;
    line-height: 16px;
    color: #7E7E7F;
    transition: .3s;
    pointer-events: none; }
  @media (min-width: 767px) {
    .days:hover {
      border: 1px solid #247CFF;
      transition: .3s; }
      .days:hover label {
        color: #247CFF;
        transition: .3s; } }

.days.active {
  border: 1px solid #247CFF;
  background: #247CFF; }
  .days.active label {
    color: #fff; }

.form-nav .wrap > div {
  display: flex; }

.form-nav {
  margin-top: 50px; }
  @media (max-width: 767px) {
    .form-nav {
      margin-top: 30px; } }
  .form-nav__item {
    font-weight: 500;
    color: #BFBFBF;
    margin-right: 15px;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    transition: .3s; }
    @media (min-width: 350px) {
      .form-nav__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .form-nav__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .form-nav__item {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 767px) {
      .form-nav__item {
        margin-right: 25px; } }
    .form-nav__item::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 100%;
      height: 2px;
      background: #247CFF;
      border-radius: 4px;
      opacity: 0;
      transition: .3s; }
    .form-nav__item span:first-child {
      margin-right: 2px; }
    @media (max-width: 767px) {
      .form-nav__item span:last-child {
        display: none; } }
    .form-nav__item--active {
      color: #247CFF;
      transition: .3s;
      pointer-events: none; }
      @media (max-width: 767px) {
        .form-nav__item--active span:last-child {
          display: inline; } }
      .form-nav__item--active::after {
        opacity: 1;
        transition: .3s; }

.price-tab__dsc {
  color: #535354;
  margin-top: 20px;
  margin-bottom: 50px; }
  @media (min-width: 350px) {
    .price-tab__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .price-tab__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .price-tab__dsc {
      font-size: 15px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    .price-tab__dsc {
      margin-bottom: 32px; } }

.modal-footer {
  display: flex;
  justify-content: space-between; }
  .modal-footer__title {
    color: #535354;
    display: block; }
    @media (min-width: 350px) {
      .modal-footer__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .modal-footer__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .modal-footer__title {
        font-size: 20px;
        line-height: 27px; } }
  .modal-footer__price {
    display: block;
    font-weight: 500;
    margin-top: 1px; }
    @media (min-width: 350px) {
      .modal-footer__price {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .modal-footer__price {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .modal-footer__price {
        font-size: 20px;
        line-height: 27px; } }
  .modal-footer-buttons {
    display: flex;
    align-items: end; }
    @media (max-width: 767px) {
      .modal-footer-buttons {
        transform: unset;
        margin-top: 16px; } }
    .modal-footer-buttons .button-prev {
      margin-right: 10px; }

.modal__container-price {
  position: relative; }
  .modal__container-price .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 767px) {
      .modal__container-price .modal-footer {
        width: calc(100% - 48px);
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 20px; }
        .modal__container-price .modal-footer .button {
          padding-left: 25px;
          padding-right: 25px; } }

.conditions {
  margin-top: 40px; }
  .conditions__dsc {
    padding-left: 22px;
    font-size: 12px;
    line-height: 16px;
    color: #7E7E7F; }
    .conditions__dsc a {
      color: #247CFF; }

.form-price .form-wrap {
  padding-bottom: 80px; }

/* The checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 5px; }

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: #247CFF;
  border: 1px solid #247CFF; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  background: url(../img/check.svg) no-repeat center;
  width: 100%;
  height: 100%; }

.filter {
  background: #F4F4F4;
  border-radius: 50px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 5px;
  max-width: fit-content; }
  .filter .wrap,
  .filter .wrap > div {
    display: flex; }
  .filter__btn {
    white-space: nowrap;
    display: inline-block !important;
    font-weight: 500;
    border-radius: 50px;
    padding: 14px 20px;
    color: #BFBFBF;
    background: #F4F4F4;
    cursor: pointer;
    transition: .3s; }
    @media (min-width: 350px) {
      .filter__btn {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .filter__btn {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .filter__btn {
        font-size: 15px;
        line-height: 20px; } }
    .filter__btn:hover {
      transition: .3s;
      color: #247CFF; }
    .filter__btn--active {
      background: #247cff;
      color: #fff !important; }

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 1200;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  -webkit-overflow-scrolling: touch;
  z-index: 9999;
  padding: 16px;
  padding-top: 0;
  display: none;
  background: #1D1D1D; }
  .mobileMenu::after {
    content: '';
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../img/mobile-menu-bg.png) no-repeat;
    background-position: right bottom;
    background-size: cover;
    z-index: 1; }
  .mobileMenu .mobile-menu-header {
    display: flex;
    z-index: 2;
    margin-bottom: 70px;
    height: 48px;
    position: relative; }
  .mobileMenu .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 4px;
    transform: unset;
    right: 0px; }

.menu-content {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative; }
  .menu-content__link {
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin-bottom: 36px; }
    @media (min-width: 350px) {
      .menu-content__link {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .menu-content__link {
        font-size: 30px;
        line-height: 34px; } }
    @media (min-width: 1140px) {
      .menu-content__link {
        font-size: 38px;
        line-height: 41px; } }
    .menu-content__link svg {
      transition: .3s ease; }
    .menu-content__link-services {
      display: flex;
      align-items: center; }
      .menu-content__link-services svg {
        margin-left: 8px; }
    .menu-content__link--small {
      color: #F4F4F4;
      margin-bottom: 24px; }
      @media (min-width: 350px) {
        .menu-content__link--small {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 768px) {
        .menu-content__link--small {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 1140px) {
        .menu-content__link--small {
          font-size: 20px;
          line-height: 24px; } }
  .menu-content__dropdown {
    margin-top: -10px;
    margin-bottom: 10px;
    display: none; }

.open {
  opacity: 1 !important;
  transition: .3s;
  visibility: visible;
  display: block; }
  .open .menu-content .menu-content__link {
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: fadeInUp; }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

.menu-content__link.active svg {
  transform: rotate(180deg); }

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%; }

.modal {
  --transition-time: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity var(--transition-time), visibility var(--transition-time); }
  .modal__title {
    letter-spacing: -0.02em; }
    @media (min-width: 350px) {
      .modal__title {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .modal__title {
        font-size: 28px;
        line-height: 30px; } }
    @media (min-width: 1140px) {
      .modal__title {
        font-size: 32px;
        line-height: 33px; } }
  .modal__recall {
    width: 100px; }
  .modal .message-success {
    margin-top: 70px; }
    @media (max-width: 1139px) {
      .modal .message-success {
        margin-top: 54px; } }
    @media (max-width: 767px) {
      .modal .message-success {
        margin-top: 96px; } }
    .modal .message-success__title {
      font-weight: 500;
      letter-spacing: -0.01em; }
      @media (min-width: 350px) {
        .modal .message-success__title {
          font-size: 26px;
          line-height: 30px; } }
      @media (min-width: 768px) {
        .modal .message-success__title {
          font-size: 30px;
          line-height: 34px; } }
      @media (min-width: 1140px) {
        .modal .message-success__title {
          font-size: 38px;
          line-height: 41px; } }
    .modal .message-success__dsc {
      margin-top: 15px;
      max-width: 409px; }
      @media (min-width: 350px) {
        .modal .message-success__dsc {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 768px) {
        .modal .message-success__dsc {
          font-size: 14px;
          line-height: 18px; } }
      @media (min-width: 1140px) {
        .modal .message-success__dsc {
          font-size: 15px;
          line-height: 20px; } }
    .modal .message-success .button {
      margin-top: 30px; }

.modal::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 100%; }
  @media (max-width: 767px) {
    .modal::before {
      display: none; } }

.modal__container {
  position: relative;
  width: 1000px;
  margin: 50px auto;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 15px;
  padding: 40px;
  padding-bottom: 50px;
  cursor: default;
  text-align: left;
  display: none; }
  @media (max-width: 767px) {
    .modal__container {
      margin-left: 16px;
      margin-right: 16px;
      width: calc(100% - 32px); } }
  .modal__container-recall {
    width: 955px; }
    @media (max-width: 1139px) {
      .modal__container-recall {
        width: 685px; } }
    @media (max-width: 767px) {
      .modal__container-recall {
        width: calc(100% - 32px); } }
  .modal__container-price {
    width: 682px; }
    @media (max-width: 767px) {
      .modal__container-price {
        width: calc(100% - 32px); } }
  @media (max-width: 1139px) {
    .modal__container {
      padding: 32px; } }
  @media (max-width: 767px) {
    .modal__container {
      padding: 24px; } }

.modal__close {
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  padding: 0;
  width: 35px;
  height: 35px;
  cursor: pointer; }
  .modal__close:hover path {
    stroke: #0962E5; }
  @media (max-width: 1139px) {
    .modal__close {
      right: 10px;
      top: 10px; } }

.modal.is-open {
  opacity: 1;
  visibility: visible;
  transition: opacity var(--transition-time), visibility var(--transition-time); }

.modal__container.modal-open {
  display: inline-block; }

.fade {
  opacity: 0;
  transition: opacity var(--transition-time); }

.fade.animate-open {
  opacity: 1;
  transition: opacity var(--transition-time); }

.fadeInUp {
  opacity: 0;
  transform: translateY(-100px);
  transition: transform var(--transition-time), opacity var(--transition-time); }

.fadeInUp.animate-open {
  opacity: 1;
  transform: translateY(0);
  transition: transform var(--transition-time), opacity var(--transition-time); }

@media (max-width: 767px) {
  .price-tab#tab-2,
  .price-tab#tab-3 {
    padding-bottom: 74px; } }

.modal__container-recall .message-success .title-big {
  font-weight: 500; }
  @media (min-width: 350px) {
    .modal__container-recall .message-success .title-big {
      font-size: 26px;
      line-height: 30px; } }
  @media (min-width: 768px) {
    .modal__container-recall .message-success .title-big {
      font-size: 30px;
      line-height: 34px; } }
  @media (min-width: 1140px) {
    .modal__container-recall .message-success .title-big {
      font-size: 38px;
      line-height: 41px; } }

@media (min-width: 350px) {
  .modal__container-recall .message-success .message-success__dsc {
    font-size: 14px;
    line-height: 18px; } }

@media (min-width: 768px) {
  .modal__container-recall .message-success .message-success__dsc {
    font-size: 14px;
    line-height: 18px; } }

@media (min-width: 1140px) {
  .modal__container-recall .message-success .message-success__dsc {
    font-size: 15px;
    line-height: 20px; } }

section .message-success__dsc {
  display: none; }

.contact__title {
  color: #7E7E7F;
  margin-bottom: 30px; }
  @media (min-width: 350px) {
    .contact__title {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .contact__title {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .contact__title {
      font-size: 15px;
      line-height: 20px; } }

.contact__link {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 16px;
  transition: .3s; }
  .contact__link:hover {
    color: #247CFF;
    transition: .3s; }
  @media (max-width: 1139px) {
    .contact__link {
      font-size: 16px;
      line-height: 18px; } }
  @media (max-width: 767px) {
    .contact__link {
      margin-bottom: 18px; } }

.contact__link-socials a {
  display: flex;
  align-items: center; }
  .contact__link-socials a svg {
    margin-right: 8px;
    transform: translate(0px, -3px); }

.contacts-row {
  max-width: 950px; }
  .contacts-row .contact__link {
    color: #090207; }
  .contacts-row .footer__list-dsc {
    color: #090207; }
  .contacts-row .contact__link-socials path:last-child {
    fill: #FFFFFF; }
  .contacts-row .contact__link-socials path:nth-child(1) {
    fill: #090207; }
  .contacts-row .col-xs-4 {
    margin-bottom: 64px; }
    @media (max-width: 767px) {
      .contacts-row .col-xs-4 {
        margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .contacts-row .col-sm-12:nth-last-child(-n+2) {
      flex-basis: 50% !important;
      max-width: 50% !important; } }
  @media (max-width: 767px) {
    .contacts-row .footer__title {
      margin-bottom: 20px; }
    .contacts-row .contact__link {
      margin-bottom: 8px; } }

.contacts-bg {
  position: absolute;
  right: 0;
  top: -90px;
  z-index: -1;
  pointer-events: none; }
  @media (max-width: 767px) {
    .contacts-bg {
      top: -75px; } }

.footer path {
  transition: .3s; }

.contact__link-socials path {
  transition: .3s; }

.contact__link-socials:hover path {
  transition: .3s; }
  .contact__link-socials:hover path:first-child {
    fill: #247CFF; }

.contact__link:hover {
  color: #247CFF; }

.client-item {
  background: #F4F4F4;
  border-radius: 15px;
  padding: 30px;
  min-height: 260px;
  margin-bottom: 20px;
  height: unset;
  width: 100%; }
  @media (max-width: 1139px) {
    .client-item {
      min-height: 318px;
      padding: 24px; } }
  @media (max-width: 767px) {
    .client-item {
      padding: 20px;
      padding-right: 17px;
      min-height: 328px;
      margin-bottom: 12px; } }
  .client-item__category-row {
    display: flex;
    flex-wrap: wrap; }
  .client-item__category {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.01em;
    color: #247CFF;
    border: 1px solid #247CFF;
    border-radius: 50px;
    padding: 7px 12px;
    margin-right: 5px;
    margin-bottom: 8px; }
    @media (max-width: 1139px) {
      .client-item__category {
        padding: 4px 12px; } }
    .client-item__category + .clients-item__category {
      margin-right: 5px; }
  .client-item__name {
    font-weight: 500;
    margin-top: 12px; }
    @media (min-width: 350px) {
      .client-item__name {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .client-item__name {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .client-item__name {
        font-size: 20px;
        line-height: 24px; } }
  .client-item__dsc {
    margin-top: 10px;
    color: #000000; }
    @media (min-width: 350px) {
      .client-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .client-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .client-item__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .client-item__dsc {
        margin-top: 8px; } }

#map {
  width: 100%;
  height: 650px;
  border-radius: 15px;
  overflow: hidden;
  margin-top: 70px; }
  @media (max-width: 1139px) {
    #map {
      height: 422px;
      margin-top: 50px; } }
  @media (max-width: 767px) {
    #map {
      height: 480px;
      margin-top: 32px; } }

.object-balloon {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  padding: 20px 40px 20px 20px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04); }
  .object-balloon .close {
    position: absolute;
    display: flex;
    top: 10px;
    right: 0px;
    cursor: pointer;
    width: 36px; }
    .object-balloon .close:hover path {
      stroke: #0962E5; }
  .object-balloon_title {
    font-weight: 500; }
    @media (min-width: 350px) {
      .object-balloon_title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .object-balloon_title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .object-balloon_title {
        font-size: 15px;
        line-height: 20px; } }
  .object-balloon_description {
    margin-top: 5px; }
    @media (min-width: 350px) {
      .object-balloon_description {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .object-balloon_description {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .object-balloon_description {
        font-size: 15px;
        line-height: 20px; } }
  .object-balloon::before {
    content: '';
    position: absolute;
    right: 50%;
    bottom: -16px;
    background: #fff;
    border-radius: 4px;
    transform: rotate(45deg) translateX(-50%);
    width: 28px;
    height: 28px;
    bottom: -22px; }

#map.loading {
  position: relative; }
  #map.loading::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background: url(../img/loading-icon.png) no-repeat;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    animation: rotateLoading 2s infinite linear;
    z-index: 2; }
  #map.loading::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #FFFFFF;
    opacity: 0.5;
    z-index: 1; }

@keyframes rotateLoading {
  from {
    transform: translate(-50%, -50%) rotate(0deg); }
  to {
    transform: translate(-50%, -50%) rotate(360deg); } }

.about-page-banner {
  padding-bottom: 150px; }
  @media (max-width: 1139px) {
    .about-page-banner {
      padding-bottom: 120px; } }
  @media (max-width: 767px) {
    .about-page-banner {
      padding-bottom: 80px; } }
  .about-page-banner__img {
    height: 580px; }
    @media (max-width: 1139px) {
      .about-page-banner__img {
        height: 377px; } }
    @media (max-width: 767px) {
      .about-page-banner__img {
        height: 413px; } }
    .about-page-banner__img img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }

.ui-img {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1; }

.person-card {
  display: flex;
  margin-bottom: 10px;
  background: #090207;
  border-radius: 15px;
  overflow: hidden;
  min-height: 360px;
  height: unset;
  width: 100%; }
  @media (max-width: 1139px) {
    .person-card {
      flex-wrap: wrap; } }
  @media (max-width: 767px) {
    .person-card {
      display: block; } }
  .person-card:hover .arrow-icon {
    background: #247CFF;
    transition: .3s ease; }
    @media (min-width: 1024px) {
      .person-card:hover .arrow-icon::after {
        animation: arrowAnimation 1s forwards; } }
  .person-card__img {
    flex-basis: 46.5%;
    max-width: 46.5%;
    display: flex;
    align-items: end; }
    .person-card__img picture {
      height: inherit;
      width: 100%; }
    @media (max-width: 1139px) {
      .person-card__img {
        flex-basis: 100%;
        max-width: 100%;
        height: 219px; }
        .person-card__img img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .person-card__info {
    flex-basis: 53.5%;
    max-width: 53.5%;
    padding: 35px 20px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 1139px) {
      .person-card__info {
        flex-basis: 100%;
        max-width: 100%;
        padding: 24px;
        padding-right: 38px;
        height: calc(100% - 219px); } }
    @media (max-width: 767px) {
      .person-card__info {
        height: unset; } }
  .person-card__expirience {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #7E7E7F; }
    @media (min-width: 350px) {
      .person-card__expirience {
        font-size: 13px;
        line-height: 16px; } }
    @media (min-width: 768px) {
      .person-card__expirience {
        font-size: 13px;
        line-height: 16px; } }
    @media (min-width: 1140px) {
      .person-card__expirience {
        font-size: 15px;
        line-height: 18px; } }
  .person-card__name {
    margin-top: 10px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    font-weight: 400; }
    @media (min-width: 350px) {
      .person-card__name {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .person-card__name {
        font-size: 28px;
        line-height: 30px; } }
    @media (min-width: 1140px) {
      .person-card__name {
        font-size: 32px;
        line-height: 33px; } }
    @media (max-width: 767px) {
      .person-card__name {
        margin-top: 16px; } }
  .person-card__dsc {
    margin-top: 20px;
    color: #F4F4F4;
    margin-bottom: 20px; }
    @media (min-width: 350px) {
      .person-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .person-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .person-card__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .person-card__dsc {
        margin-top: 16px;
        margin-bottom: 32px; } }
    @media (max-width: 767px) {
      .person-card__dsc {
        margin-top: 12px; } }

.vacancy-card {
  width: 100%;
  height: unset;
  min-height: 420px;
  background: #F4F4F4;
  margin-bottom: 20px;
  padding: 35px 30px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .vacancy-card {
      margin-bottom: 12px; } }
  @media (max-width: 767px) {
    .vacancy-card {
      padding: 28px 20px; } }
  .vacancy-card__name {
    font-weight: 500;
    letter-spacing: -0.01em; }
    @media (min-width: 350px) {
      .vacancy-card__name {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 768px) {
      .vacancy-card__name {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 1140px) {
      .vacancy-card__name {
        font-size: 24px;
        line-height: 30px; } }
  .vacancy-card__price {
    font-weight: 500;
    letter-spacing: -0.01em; }
    @media (min-width: 350px) {
      .vacancy-card__price {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 768px) {
      .vacancy-card__price {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 1140px) {
      .vacancy-card__price {
        font-size: 24px;
        line-height: 30px; } }
  .vacancy-card__dsc {
    margin-top: 15px; }
    @media (min-width: 350px) {
      .vacancy-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .vacancy-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .vacancy-card__dsc {
        font-size: 15px;
        line-height: 20px; } }
  .vacancy-card__link {
    margin-top: 30px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #247CFF;
    display: flex;
    align-items: center; }
    .vacancy-card__link:hover {
      color: #0962E5;
      transition: .3s; }
      .vacancy-card__link:hover path {
        stroke: #0962E5;
        transition: .3s; }
    .vacancy-card__link svg {
      margin-left: 2px; }
      .vacancy-card__link svg path {
        transition: .3s; }

.principles-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  @media (max-width: 1139px) {
    .principles-row {
      margin-top: 60px; } }
  @media (max-width: 767px) {
    .principles-row {
      margin-top: 40px; } }
  .principles-row .col {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0 10px;
    margin-top: 80px; }
    @media (max-width: 1139px) {
      .principles-row .col {
        margin-top: 50px; } }
    @media (max-width: 767px) {
      .principles-row .col {
        flex-basis: 100%;
        max-width: 100%; } }
    .principles-row .col:first-child, .principles-row .col:nth-child(2) {
      margin-top: 0; }
    @media (max-width: 767px) {
      .principles-row .col {
        margin-top: 28px; }
        .principles-row .col:nth-child(2) {
          margin-top: 28px; } }

@media (max-width: 1139px) {
  .values-card {
    display: flex; } }

.values-card__img {
  width: 80px;
  height: 80px;
  border-radius: 15px; }
  @media (max-width: 1139px) {
    .values-card__img {
      width: 76px;
      height: 76px;
      margin-right: 20px; } }
  @media (max-width: 767px) {
    .values-card__img {
      width: 72px;
      height: 72px; } }

.values-card__title {
  font-weight: 500;
  margin-top: 40px; }
  @media (min-width: 350px) {
    .values-card__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .values-card__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .values-card__title {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 1139px) {
    .values-card__title {
      margin-top: 0; } }

.values-card__dsc {
  margin-top: 10px; }
  @media (min-width: 350px) {
    .values-card__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .values-card__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .values-card__dsc {
      font-size: 15px;
      line-height: 20px; } }

.certificate-row {
  margin-top: 40px; }

.certificate-card {
  background: #F4F4F4;
  border-radius: 15px;
  min-height: 340px;
  display: block;
  padding: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: unset; }
  .certificate-card:hover .download-icon {
    background: #247CFF;
    transition: .3s ease; }
    @media (min-width: 1024px) {
      .certificate-card:hover .download-icon::before {
        animation: arrowAnimation2 1s forwards; } }
  @media (max-width: 1139px) {
    .certificate-card {
      min-height: 252px;
      padding: 24px;
      margin-bottom: 16px; } }
  @media (max-width: 767px) {
    .certificate-card {
      min-height: 236px;
      padding: 20px;
      margin-bottom: 12px; } }
  .certificate-card__img {
    width: 130px;
    height: 60px; }
    @media (max-width: 1139px) {
      .certificate-card__img {
        width: 117px;
        height: 54px; } }
    @media (max-width: 767px) {
      .certificate-card__img {
        width: 104px;
        height: 48px; } }
    .certificate-card__img img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: left; }
  .certificate-card__title {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (min-width: 350px) {
      .certificate-card__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .certificate-card__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .certificate-card__title {
        font-size: 20px;
        line-height: 24px; } }
    .certificate-card__title span {
      color: #7E7E7F; }
    @media (max-width: 1139px) {
      .certificate-card__title {
        margin-top: 8px; } }

.news-count {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  display: block;
  color: #979797; }
  @media (min-width: 350px) {
    .news-count {
      font-size: 13px;
      line-height: 16px; } }
  @media (min-width: 768px) {
    .news-count {
      font-size: 13px;
      line-height: 16px; } }
  @media (min-width: 1140px) {
    .news-count {
      font-size: 15px;
      line-height: 18px; } }

.news-title {
  letter-spacing: -0.02em;
  margin-top: 10px;
  font-weight: 400; }
  @media (min-width: 350px) {
    .news-title {
      font-size: 26px;
      line-height: 30px; } }
  @media (min-width: 768px) {
    .news-title {
      font-size: 28px;
      line-height: 30px; } }
  @media (min-width: 1140px) {
    .news-title {
      font-size: 32px;
      line-height: 33px; } }

.news-item {
  margin-bottom: 40px; }
  .news-item:hover .news-item__title {
    color: #247CFF;
    transition: .3s; }
  @media (max-width: 1139px) {
    .news-item {
      margin-bottom: 36px; } }
  .news-item__img {
    height: 170px;
    display: block; }
    @media (max-width: 1139px) {
      .news-item__img {
        height: 227px; } }
    @media (max-width: 767px) {
      .news-item__img {
        height: 197px; } }
    .news-item__img img {
      width: 100%;
      object-fit: cover;
      display: block;
      height: inherit;
      border-radius: 15px; }
  .news-item__cat {
    margin-top: 10px;
    font-weight: 500;
    color: #247CFF;
    margin-top: 15px;
    display: inline-block; }
    @media (min-width: 350px) {
      .news-item__cat {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .news-item__cat {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .news-item__cat {
        font-size: 15px;
        line-height: 20px; } }
  .news-item__title {
    font-weight: 500;
    margin-top: 8px;
    display: block;
    transition: .3s; }
    @media (min-width: 350px) {
      .news-item__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .news-item__title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .news-item__title {
        font-size: 20px;
        line-height: 24px; } }
  .news-item__date {
    margin-top: 12px;
    color: #BFBFBF;
    display: flex;
    align-items: center; }
    @media (min-width: 350px) {
      .news-item__date {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .news-item__date {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .news-item__date {
        font-size: 15px;
        line-height: 20px; } }
    .news-item__date svg {
      margin: 0 5px; }

.pagination {
  display: flex;
  align-items: center;
  margin-top: 60px; }
  @media (max-width: 1139px) {
    .pagination {
      margin-top: 20px; } }
  @media (max-width: 767px) {
    .pagination {
      margin-top: 0; } }
  .pagination .disabled {
    display: none; }
  .pagination__item {
    margin-right: 2px; }
    .pagination__item a,
    .pagination__item span {
      width: 50px;
      height: 50px;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      cursor: pointer; }
      @media (min-width: 350px) {
        .pagination__item a,
        .pagination__item span {
          font-size: 15px;
          line-height: 17px; } }
      @media (min-width: 768px) {
        .pagination__item a,
        .pagination__item span {
          font-size: 15px;
          line-height: 17px; } }
      @media (min-width: 1140px) {
        .pagination__item a,
        .pagination__item span {
          font-size: 16px;
          line-height: 18px; } }
    .pagination__item--active a,
    .pagination__item--active span {
      background: #F4F4F4;
      color: #247CFF; }
  .pagination__prev a, .pagination__next a {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #247CFF; }
    @media (min-width: 350px) {
      .pagination__prev a, .pagination__next a {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .pagination__prev a, .pagination__next a {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .pagination__prev a, .pagination__next a {
        font-size: 15px;
        line-height: 20px; } }
  .pagination__prev {
    margin-right: 15px; }
    .pagination__prev svg {
      margin-right: 5px; }
  .pagination__next {
    margin-left: 15px; }
    .pagination__next svg {
      margin-left: 5px; }

.category {
  margin-left: 0 !important;
  position: relative;
  margin-top: 50px; }
  @media (max-width: 767px) {
    .category {
      margin-top: 36px; } }
  .category .swiper-container {
    border-radius: 50px;
    background: #F4F4F4 !important;
    width: calc(100% - 26px);
    margin-left: 0 !important;
    padding-right: 46px; }
    @media (max-width: 767px) {
      .category .swiper-container {
        width: 100%;
        padding-right: 20px; } }
  .category .swiper-wrapper {
    padding: 5px; }
  .category__item {
    display: inline-block !important;
    font-weight: 500;
    border-radius: 50px;
    padding: 14px 18px 14px 18px;
    color: #BFBFBF;
    background: #F4F4F4;
    flex-shrink: inherit !important;
    white-space: nowrap;
    width: fit-content;
    transition: .3s; }
    @media (min-width: 350px) {
      .category__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .category__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .category__item {
        font-size: 15px;
        line-height: 20px; } }
    .category__item:hover {
      color: #247CFF; }
    .category__item--active {
      background: #247CFF;
      color: #fff !important; }
  .category__next-btn {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    height: 58px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    outline: unset !important; }
    @media (max-width: 767px) {
      .category__next-btn {
        display: none; } }
    .category__next-btn::after {
      content: '';
      position: absolute;
      left: -26px;
      width: 26px;
      height: 100%;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }
  .category__prev-btn {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    height: 58px;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    outline: unset !important; }
    @media (max-width: 767px) {
      .category__prev-btn {
        display: none; } }
    .category__prev-btn::after {
      content: '';
      position: absolute;
      right: -26px;
      width: 26px;
      height: 100%;
      background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      transform: scale(-1, -1); }
  .category .swiper-notification {
    display: none; }

@media (max-width: 767px) {
  .category-slider-init .swiper-container {
    width: calc(100% + 16px);
    max-width: calc(100% + 16px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; } }

.category__prev-btn.swiper-button-disabled,
.category__next-btn.swiper-button-disabled {
  display: none; }

.article-banner {
  height: 450px; }
  @media (max-width: 1139px) {
    .article-banner {
      height: 292px; } }
  @media (max-width: 767px) {
    .article-banner {
      height: 197px; } }
  .article-banner img {
    width: 100%;
    height: inherit;
    display: block;
    object-fit: cover; }

.article-content-cnt {
  max-width: 767px;
  margin: 0 auto;
  margin-top: 50px; }
  @media (max-width: 1139px) {
    .article-content-cnt {
      margin-top: 40px;
      max-width: 600px; } }

.article__cat {
  font-weight: 500;
  color: #247CFF;
  display: block; }
  @media (min-width: 350px) {
    .article__cat {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .article__cat {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .article__cat {
      font-size: 20px;
      line-height: 24px; } }

.article__title {
  letter-spacing: -0.03em;
  margin-top: 20px;
  font-weight: 500; }
  @media (min-width: 350px) {
    .article__title {
      font-size: 36px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .article__title {
      font-size: 34px;
      line-height: 38px; } }
  @media (min-width: 1140px) {
    .article__title {
      font-size: 54px;
      line-height: 55px; } }
  @media (max-width: 1139px) {
    .article__title {
      margin-top: 16px; } }

.article__date {
  color: #BFBFBF;
  display: block;
  margin-top: 24px; }
  @media (min-width: 350px) {
    .article__date {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .article__date {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .article__date {
      font-size: 15px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    .article__date {
      margin-top: 20px; } }

.article__content {
  margin-top: 40px; }
  @media (max-width: 1139px) {
    .article__content {
      margin-top: 32px; } }
  .article__content ul {
    list-style: none;
    margin: 20px 0; }
    .article__content ul li {
      position: relative;
      padding-left: 20px; }
      @media (min-width: 350px) {
        .article__content ul li {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 768px) {
        .article__content ul li {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 1140px) {
        .article__content ul li {
          font-size: 20px;
          line-height: 27px; } }
      @media (max-width: 767px) {
        .article__content ul li {
          padding-left: 18px; } }
      .article__content ul li::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #090207;
        top: 10px;
        left: 5px; }
        @media (max-width: 1139px) {
          .article__content ul li::after {
            top: 8px; } }
  .article__content h2 + p {
    margin-top: 20px; }
  @media (min-width: 350px) {
    .article__content p {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .article__content p {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .article__content p {
      font-size: 20px;
      line-height: 27px; } }
  .article__content p + p {
    margin-top: 40px; }
    @media (max-width: 1139px) {
      .article__content p + p {
        margin-top: 32px; } }
  .article__content img {
    width: 100%;
    display: block;
    margin-top: 40px; }
    .article__content img + p,
    .article__content img + h2 {
      margin-top: 40px; }
      @media (max-width: 1139px) {
        .article__content img + p,
        .article__content img + h2 {
          margin-top: 32px; } }
    @media (max-width: 1139px) {
      .article__content img {
        margin-top: 32px; } }

.article .img-1row {
  margin-bottom: 20px; }

.article .img-2row {
  display: flex;
  margin: 0 -10px;
  margin-bottom: 20px; }
  .article .img-2row img {
    padding: 0 10px;
    flex-basis: 50%;
    max-width: 50%; }

.more-news {
  margin-top: 120px; }
  @media (max-width: 1139px) {
    .more-news {
      margin-top: 100px; } }
  @media (max-width: 767px) {
    .more-news {
      margin-top: 80px; } }
  .more-news .row {
    margin-top: 28px; }
    @media (max-width: 767px) {
      .more-news .row {
        margin-top: 36px; } }

.article-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 90px; }
  @media (max-width: 1139px) {
    .article-nav {
      margin-top: 76px; } }
  @media (max-width: 767px) {
    .article-nav {
      margin-top: 48px; } }
  .article-nav a {
    display: flex;
    align-items: center;
    font-weight: 500;
    transition: .3s; }
    @media (min-width: 350px) {
      .article-nav a {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .article-nav a {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .article-nav a {
        font-size: 15px;
        line-height: 20px; } }
    .article-nav a path {
      transition: .3s; }
    .article-nav a:hover {
      color: #247CFF;
      transition: .3s; }
      .article-nav a:hover path {
        transition: .3s;
        fill: #247CFF; }
    @media (max-width: 767px) {
      .article-nav a {
        font-size: 12px;
        line-height: 16px; } }
  .article-nav__prev svg {
    margin-right: 5px; }
  .article-nav__next svg {
    margin-left: 5px; }

.service-banner {
  background: #090207;
  padding-top: 182px;
  padding-bottom: 70px;
  min-height: 470px;
  overflow: hidden; }
  @media (max-width: 1139px) {
    .service-banner {
      padding-top: 150px;
      min-height: 400px; } }
  @media (max-width: 767px) {
    .service-banner {
      padding-top: 105px;
      padding-bottom: 0; } }
  .service-banner .cnt {
    position: relative;
    min-height: 260px; }
  .service-banner__title {
    color: #fff;
    letter-spacing: -0.02em;
    font-weight: 500; }
    @media (min-width: 350px) {
      .service-banner__title {
        font-size: 36px;
        line-height: 38px; } }
    @media (min-width: 768px) {
      .service-banner__title {
        font-size: 42px;
        line-height: 44px; } }
    @media (min-width: 1140px) {
      .service-banner__title {
        font-size: 72px;
        line-height: 72px; } }
  .service-banner__dsc {
    color: #7E7E7F;
    margin-top: 30px; }
    @media (min-width: 350px) {
      .service-banner__dsc {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .service-banner__dsc {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .service-banner__dsc {
        font-size: 20px;
        line-height: 24px; } }
    @media (max-width: 767px) {
      .service-banner__dsc {
        margin-top: 14px; } }
  .service-banner__img {
    width: 555px;
    height: 400px;
    position: absolute;
    right: -30px;
    bottom: -70px; }
    @media (max-width: 1139px) {
      .service-banner__img {
        width: 442px;
        height: 352px;
        right: 0;
        bottom: -104px; } }
    @media (max-width: 767px) {
      .service-banner__img {
        width: 100%;
        height: 275px;
        right: unset;
        bottom: unset;
        position: relative;
        margin-top: 28px;
        margin-left: -16px;
        margin-right: -16px;
        width: calc(100% + 32px); } }
    .service-banner__img img {
      display: block;
      object-fit: contain;
      object-position: bottom right;
      width: 100%;
      height: 100%; }
      @media (max-width: 767px) {
        .service-banner__img img {
          object-position: bottom center; } }

.services-values-card-row {
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .services-values-card-row {
      margin-top: 40px; } }
  @media (max-width: 1139px) {
    .services-values-card-row .col-sm-12 {
      margin-bottom: 40px; }
      .services-values-card-row .col-sm-12:last-child {
        margin-bottom: 0; } }

.advantages-work-card__img {
  width: 100%;
  height: 250px;
  border-radius: 15px;
  overflow: hidden; }
  @media (max-width: 1139px) {
    .advantages-work-card__img {
      height: 334px; } }
  @media (max-width: 767px) {
    .advantages-work-card__img {
      height: 290px; } }
  .advantages-work-card__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.advantages-work-card__title {
  font-weight: 500;
  margin-top: 25px; }
  @media (min-width: 350px) {
    .advantages-work-card__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .advantages-work-card__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .advantages-work-card__title {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    .advantages-work-card__title {
      margin-top: 16px; } }

.advantages-work-card__dsc {
  margin-top: 10px;
  color: #535354; }
  @media (min-width: 350px) {
    .advantages-work-card__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 768px) {
    .advantages-work-card__dsc {
      font-size: 14px;
      line-height: 18px; } }
  @media (min-width: 1140px) {
    .advantages-work-card__dsc {
      font-size: 15px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    .advantages-work-card__dsc {
      margin-top: 8px; } }

.white_small_img .advantages-work-card__img {
  width: 80px;
  height: 80px; }
  @media (max-width: 1139px) {
    .white_small_img .advantages-work-card__img {
      width: 76px;
      height: 76px; } }
  @media (max-width: 767px) {
    .white_small_img .advantages-work-card__img {
      width: 72px;
      height: 72px; } }

.stat .col-xs-9 {
  padding-right: 100px; }
  @media (max-width: 1139px) {
    .stat .col-xs-9 {
      orphans: 0; } }

.stat-item {
  border-top: 1px solid #BFBFBF;
  margin-bottom: 42px; }
  @media (max-width: 1139px) {
    .stat-item {
      margin-bottom: 34px;
      max-width: 190px;
      margin-left: auto; } }
  @media (max-width: 767px) {
    .stat-item {
      margin-bottom: 24px;
      max-width: 100%; }
      .stat-item:first-child {
        margin-top: 40px; } }
  .stat-item:last-child {
    margin-bottom: 0; }
  .stat-item__count {
    letter-spacing: -0.02em;
    font-weight: 500;
    margin-top: 20px; }
    @media (min-width: 350px) {
      .stat-item__count {
        font-size: 36px;
        line-height: 38px; } }
    @media (min-width: 768px) {
      .stat-item__count {
        font-size: 42px;
        line-height: 44px; } }
    @media (min-width: 1140px) {
      .stat-item__count {
        font-size: 72px;
        line-height: 72px; } }
    @media (max-width: 1139px) {
      .stat-item__count {
        margin-top: 16px; } }
    @media (max-width: 767px) {
      .stat-item__count {
        margin-top: 12px; } }
  .stat-item__dsc {
    color: #7E7E7F; }
    @media (min-width: 350px) {
      .stat-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .stat-item__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .stat-item__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .stat-item__dsc {
        margin-top: 4px; } }
    @media (max-width: 767px) {
      .stat-item__dsc {
        margin-top: 0; } }

.services-black {
  background: #090207;
  padding: 160px 0; }
  @media (max-width: 1139px) {
    .services-black {
      padding: 120px 0; } }
  @media (max-width: 767px) {
    .services-black {
      padding: 80px 0; } }
  @media (max-width: 1139px) {
    .services-black .col-sm-12 {
      margin-bottom: 0; } }
  @media (max-width: 767px) {
    .services-black .col-sm-12 {
      margin-bottom: 0; }
      .services-black .col-sm-12:first-child .values-card {
        margin-top: 0; } }
  .services-black .title {
    color: #fff; }
  .services-black .values-card {
    background: #282829;
    border-radius: 15px;
    margin-top: 20px;
    padding: 30px;
    display: flex; }
    @media (max-width: 1139px) {
      .services-black .values-card {
        padding: 24px; } }
    @media (max-width: 767px) {
      .services-black .values-card {
        padding: 20px; } }
  .services-black .values-card__img {
    width: 120px;
    height: 120px;
    margin-right: 30px; }
    @media (max-width: 1139px) {
      .services-black .values-card__img {
        width: 100px;
        height: 100px;
        margin-right: 20px; } }
    @media (max-width: 767px) {
      .services-black .values-card__img {
        width: 80px;
        height: 80px;
        margin-right: 16px; } }
  .services-black .values-card__title {
    color: #fff;
    margin-top: 0; }
  .services-black .values-card__dsc {
    color: #BFBFBF;
    margin-top: 10px; }
    @media (max-width: 767px) {
      .services-black .values-card__dsc {
        margin-top: 8px; } }

@media (max-width: 767px) {
  #competentionSlider {
    overflow: visible; }
    #competentionSlider .row {
      margin: 0; }
    #competentionSlider .swiper-wrapper {
      height: unset; }
    #competentionSlider .swiper-slide {
      padding: 0 !important;
      max-width: 98% !important;
      height: unset !important; }
    #competentionSlider .swiper-pagination {
      margin-top: 20px; } }

#competentionSlider .swiper-notification {
  display: none; }

.skills-card {
  border-radius: 15px;
  background: #F4F4F4;
  margin-bottom: 20px;
  padding: 35px;
  padding-bottom: 30px;
  padding-left: 30px;
  width: 100%;
  height: unset;
  min-height: 470px; }
  @media (max-width: 1139px) {
    .skills-card {
      padding: 30px;
      min-height: 376px; } }
  @media (max-width: 767px) {
    .skills-card {
      padding: 20px; } }
  .skills-card__title {
    letter-spacing: -0.02em; }
    @media (min-width: 350px) {
      .skills-card__title {
        font-size: 26px;
        line-height: 30px; } }
    @media (min-width: 768px) {
      .skills-card__title {
        font-size: 28px;
        line-height: 30px; } }
    @media (min-width: 1140px) {
      .skills-card__title {
        font-size: 32px;
        line-height: 33px; } }
  .skills-card__dsc {
    margin-top: 20px;
    max-width: 420px; }
    @media (min-width: 350px) {
      .skills-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .skills-card__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .skills-card__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .skills-card__dsc {
        margin-top: 12px; } }
  .skills-card-img {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px; }
    @media (max-width: 1139px) {
      .skills-card-img {
        margin-left: -8px;
        margin-right: -8px;
        margin-top: 32px; } }
    @media (max-width: 767px) {
      .skills-card-img {
        margin-left: -16px;
        margin-right: -16px;
        margin-top: 30px; } }
    .skills-card-img div {
      flex-basis: 132px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      padding-right: 10px;
      padding-left: 10px;
      filter: grayscale(1);
      transition: .3s; }
      @media (max-width: 1024px) {
        .skills-card-img div {
          filter: unset; } }
      .skills-card-img div:hover {
        filter: grayscale(0);
        transition: .3s; }
      @media (max-width: 1139px) {
        .skills-card-img div {
          padding-right: 4px;
          padding-left: 4px;
          flex-basis: 84px; } }
      @media (max-width: 767px) {
        .skills-card-img div {
          flex-basis: 104px;
          padding-right: 16px;
          padding-left: 16px;
          margin-bottom: 20px; } }
      .skills-card-img div img {
        display: block; }

.repair-block .img {
  position: relative;
  padding-bottom: 106%;
  width: 87%;
  border-radius: 15px;
  overflow: hidden; }
  @media (max-width: 1139px) {
    .repair-block .img {
      width: 92%;
      padding-bottom: 112%; } }
  @media (max-width: 767px) {
    .repair-block .img {
      width: 100%;
      padding-bottom: 122%;
      margin-top: 40px; } }

.repair-block__title {
  letter-spacing: -0.03em;
  font-weight: 500; }
  @media (min-width: 350px) {
    .repair-block__title {
      font-size: 36px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .repair-block__title {
      font-size: 34px;
      line-height: 38px; } }
  @media (min-width: 1140px) {
    .repair-block__title {
      font-size: 54px;
      line-height: 55px; } }

.repair-block__dsc {
  margin-top: 30px; }
  @media (min-width: 350px) {
    .repair-block__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .repair-block__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .repair-block__dsc {
      font-size: 20px;
      line-height: 27px; } }
  @media (max-width: 1139px) {
    .repair-block__dsc {
      margin-top: 24px; } }
  .repair-block__dsc span {
    background: -webkit-linear-gradient(0deg, #FFA53B 0.75%, #FF5E3B 44.75%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    display: inline; }
  .repair-block__dsc ul {
    margin-top: 8px; }
    .repair-block__dsc ul li {
      position: relative;
      padding-left: 20px; }
      @media (min-width: 350px) {
        .repair-block__dsc ul li {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 768px) {
        .repair-block__dsc ul li {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 1140px) {
        .repair-block__dsc ul li {
          font-size: 20px;
          line-height: 27px; } }
      @media (max-width: 767px) {
        .repair-block__dsc ul li {
          padding-left: 18px; } }
      .repair-block__dsc ul li::after {
        content: '';
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #090207;
        top: 10px;
        left: 5px; }
        @media (max-width: 1139px) {
          .repair-block__dsc ul li::after {
            top: 8px; } }

.repair-block__row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .repair-block__row {
      margin-left: 0;
      margin-right: 0;
      margin-top: 40px; } }
  .repair-block__row .col {
    padding: 0 10px;
    flex-basis: 50%;
    max-width: 50%; }
    @media (max-width: 1139px) {
      .repair-block__row .col {
        padding-left: 0;
        padding-right: 0;
        flex-basis: 100%;
        max-width: 100%;
        display: flex; }
        .repair-block__row .col + .col {
          margin-top: 28px; } }
    .repair-block__row .col img {
      width: 80px;
      height: 80px;
      border-radius: 15px;
      max-width: 80px; }
      @media (max-width: 1139px) {
        .repair-block__row .col img {
          width: 72px;
          height: 72px;
          margin-right: 16px; } }
    .repair-block__row .col span {
      margin-top: 20px; }
      @media (min-width: 350px) {
        .repair-block__row .col span {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 768px) {
        .repair-block__row .col span {
          font-size: 17px;
          line-height: 22px; } }
      @media (min-width: 1140px) {
        .repair-block__row .col span {
          font-size: 20px;
          line-height: 24px; } }
      @media (max-width: 1139px) {
        .repair-block__row .col span {
          margin-top: 0; } }

@media (max-width: 767px) {
  .repair-block .col-sm-12:first-child {
    order: 1; }
  .repair-block .col-sm-12:last-child {
    order: 0; } }

#servicesSliderMobile {
  overflow: visible; }
  #servicesSliderMobile .swiper-notification {
    display: none !important; }

@media (max-width: 767px) {
  .services-slider .row {
    margin-left: 0;
    margin-right: 0; } }

.services-slider .col {
  padding: 0 10px; }
  @media (max-width: 767px) {
    .services-slider .col {
      flex-basis: 100% !important;
      max-width: 100% !important; } }
  .services-slider .col:first-child {
    flex-basis: 65%;
    max-width: 65%; }
    @media (max-width: 1139px) {
      .services-slider .col:first-child {
        max-width: 60%;
        flex-basis: 60%; } }
    @media (max-width: 767px) {
      .services-slider .col:first-child {
        order: 1;
        margin-top: 32px; } }
  .services-slider .col:last-child {
    flex-basis: 35%;
    max-width: 35%; }
    @media (max-width: 1139px) {
      .services-slider .col:last-child {
        max-width: 40%;
        flex-basis: 40%; } }
    @media (max-width: 767px) {
      .services-slider .col:last-child {
        order: 0; } }
    @media (min-width: 767px) {
      .services-slider .col:last-child {
        display: block; } }

.services-slider .img {
  position: relative;
  max-width: 96%;
  padding-bottom: 60%;
  border-radius: 15px;
  overflow: hidden;
  animation: fadeIn .6s forwards; }
  @media (max-width: 1139px) {
    .services-slider .img {
      max-width: 100%;
      padding-bottom: 62%; } }
  @media (max-width: 767px) {
    .services-slider .img {
      max-width: 100%;
      padding-bottom: 63%; } }

.services-slider__item {
  border-radius: 15px;
  padding: 30px;
  padding-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  cursor: pointer;
  position: relative;
  transition: .3s ease;
  max-width: 374px;
  margin-left: auto;
  width: 100% !important; }
  @media (max-width: 1139px) {
    .services-slider__item {
      padding: 20px;
      padding-top: 0;
      padding-bottom: 0; } }
  @media (max-width: 767px) {
    .services-slider__item {
      max-width: 76%;
      min-width: 276px;
      padding-top: 20px;
      padding-bottom: 20px; } }
  .services-slider__item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #EAF2FF;
    z-index: -1;
    border-radius: 15px;
    opacity: 0;
    transition: .3s; }
  .services-slider__item-title {
    font-weight: 500;
    padding: 12.5px 0;
    transition: .3s ease; }
    @media (min-width: 350px) {
      .services-slider__item-title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .services-slider__item-title {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .services-slider__item-title {
        font-size: 20px;
        line-height: 24px; } }
    @media (max-width: 1139px) {
      .services-slider__item-title {
        padding: 8px 0; } }
    @media (max-width: 767px) {
      .services-slider__item-title {
        padding: 0; } }
  .services-slider__item-dsc {
    color: #535354;
    display: none; }
    @media (min-width: 350px) {
      .services-slider__item-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .services-slider__item-dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .services-slider__item-dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 767px) {
      .services-slider__item-dsc {
        display: block;
        margin-top: 8px; } }
  .services-slider__item—active {
    padding-top: 17.5px;
    padding-bottom: 30px;
    margin-bottom: 10px; }
    @media (max-width: 1139px) {
      .services-slider__item—active {
        padding-bottom: 20px; } }
    @media (max-width: 767px) {
      .services-slider__item—active {
        padding: 20px; } }
    @media (min-width: 767px) {
      .services-slider__item—active .services-slider__item-title {
        color: #247CFF;
        transition: .3s ease; } }
    .services-slider__item—active::after {
      opacity: 1;
      transition: .3s; }
    .services-slider__item—active .services-slider__item-dsc {
      display: block; }

.services-slider__item.swiper-slide-active {
  background: #EAF2FF; }

.person-banner {
  background: #090207;
  padding-top: 154px;
  padding-bottom: 60px; }
  @media (max-width: 1139px) {
    .person-banner {
      padding-top: 118px;
      padding-bottom: 48px; } }
  @media (max-width: 767px) {
    .person-banner {
      padding-bottom: 0;
      padding-top: 92px; } }
  .person-banner .cnt {
    position: relative; }
  .person-banner-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 320px;
    z-index: 2;
    position: relative; }
    @media (max-width: 1139px) {
      .person-banner-info {
        min-height: 280px; } }
    @media (max-width: 767px) {
      .person-banner-info {
        display: block;
        min-height: unset; } }
  .person-banner__name {
    font-weight: 500;
    letter-spacing: -0.03em;
    margin-top: 15px;
    color: #fff; }
    @media (min-width: 350px) {
      .person-banner__name {
        font-size: 36px;
        line-height: 38px; } }
    @media (min-width: 768px) {
      .person-banner__name {
        font-size: 34px;
        line-height: 38px; } }
    @media (min-width: 1140px) {
      .person-banner__name {
        font-size: 54px;
        line-height: 55px; } }
  .person-banner__dsc {
    color: #F4F4F4;
    margin-top: 30px;
    max-width: 496px;
    margin-bottom: 40px; }
    @media (min-width: 350px) {
      .person-banner__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .person-banner__dsc {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .person-banner__dsc {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .person-banner__dsc {
        margin-top: 20px; } }
    @media (max-width: 767px) {
      .person-banner__dsc {
        margin-top: 12px; } }
  .person-banner__phone {
    font-weight: 500;
    color: #247CFF;
    transition: .3s;
    display: flex; }
    @media (min-width: 350px) {
      .person-banner__phone {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 768px) {
      .person-banner__phone {
        font-size: 17px;
        line-height: 22px; } }
    @media (min-width: 1140px) {
      .person-banner__phone {
        font-size: 20px;
        line-height: 24px; } }
    .person-banner__phone:hover {
      transition: .3s;
      color: #0962E5; }
  .person-banner__img {
    width: 465px;
    height: 460px;
    position: absolute;
    right: 40px;
    bottom: -60px; }
    @media (max-width: 1139px) {
      .person-banner__img {
        width: 394px;
        height: 390px;
        bottom: -48px; } }
    @media (max-width: 767px) {
      .person-banner__img {
        position: relative;
        width: 257px;
        height: 254px;
        bottom: unset;
        right: unset;
        margin-top: 16px; } }
    .person-banner__img img {
      display: block;
      object-fit: contain;
      object-position: bottom;
      width: 100%;
      height: 100%; }

.tariff-calc + .table {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

@media (max-width: 767px) {
  .table-wrap {
    overflow-x: auto;
    margin-right: -16px;
    padding-right: 16px;
    margin-left: -16px;
    padding-left: 16px; } }

.table {
  background: #F4F4F4;
  border-radius: 15px;
  padding-bottom: 50px;
  padding-top: 40px;
  overflow: hidden; }
  @media (max-width: 1139px) {
    .table {
      padding-bottom: 36px; } }
  @media (max-width: 767px) {
    .table {
      padding-left: 16px;
      padding-right: 16px;
      width: 500px;
      padding-top: 0;
      margin-top: 32px;
      padding-bottom: 30px; } }
  .table-row {
    display: flex; }
    @media (max-width: 767px) {
      .table-row {
        flex-wrap: wrap; } }
  .table-col:first-child {
    flex-basis: 35.5%;
    max-width: 35.5%;
    padding-left: 40px;
    padding-right: 20px; }
    @media (max-width: 1139px) {
      .table-col:first-child {
        padding-left: 18px;
        padding-right: 10px; } }
    @media (max-width: 767px) {
      .table-col:first-child {
        flex-basis: 100%;
        max-width: 100%;
        text-align: center; }
        .table-col:first-child span {
          padding: 20px 0;
          font-weight: 500; } }
  .table-col:nth-child(2), .table-col:nth-child(3), .table-col:nth-child(4) {
    flex-basis: calc(64.5% / 3);
    max-width: calc(64.5% / 3);
    text-align: center;
    padding: 0 30px;
    border-left: 1px solid #BFBFBF; }
    @media (max-width: 1200px) {
      .table-col:nth-child(2), .table-col:nth-child(3), .table-col:nth-child(4) {
        padding: 0 15px; } }
    @media (max-width: 1139px) {
      .table-col:nth-child(2), .table-col:nth-child(3), .table-col:nth-child(4) {
        padding: 0 8px; } }
    @media (max-width: 767px) {
      .table-col:nth-child(2), .table-col:nth-child(3), .table-col:nth-child(4) {
        flex-basis: 156px;
        max-width: 156px;
        border-left: unset;
        border-top: 1px solid #BFBFBF;
        border-bottom: 1px solid #BFBFBF;
        display: flex;
        align-items: center;
        justify-content: center; } }
  @media (max-width: 767px) {
    .table-col:nth-child(3) {
      border-left: 1px solid #BFBFBF;
      border-right: 1px solid #BFBFBF; } }
  .table-col:nth-child(4) {
    margin-right: 30px; }
    @media (max-width: 1139px) {
      .table-col:nth-child(4) {
        margin-right: 20px; } }
    @media (max-width: 767px) {
      .table-col:nth-child(4) {
        margin-right: 0; } }
  .table-col span {
    padding: 15px 0;
    font-size: 16px;
    line-height: 20px; }
    @media (max-width: 1139px) {
      .table-col span {
        font-size: 14px;
        line-height: 18px; } }
  .table-col svg {
    margin-top: 12px; }
    @media (max-width: 767px) {
      .table-col svg {
        margin: 16px 0; } }
  .table-col .button {
    margin-top: 12px;
    width: 100%;
    white-space: pre-wrap; }
    @media (max-width: 1139px) {
      .table-col .button {
        font-size: 13px;
        line-height: 18px;
        padding-left: 5px;
        padding-right: 5px; } }
    @media (max-width: 767px) {
      .table-col .button {
        margin-top: 20px; } }
  .table-header .table-col {
    padding-bottom: 22px; }
    @media (max-width: 767px) {
      .table-header .table-col:first-child {
        display: none; } }
    @media (max-width: 767px) {
      .table-header .table-col {
        border-top: unset;
        padding: 20px 10px; } }
  .table-header span {
    padding: 0;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.01em; }
    @media (max-width: 1139px) {
      .table-header span {
        font-size: 16px;
        line-height: 21px; } }
    @media (max-width: 767px) {
      .table-header span {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #247CFF; } }
  @media (max-width: 767px) {
    .table-footer .table-col {
      border-top: unset;
      border-bottom: unset; } }

@media (min-width: 1024px) {
  .table-row:not(.table-footer):not(.table-header):hover {
    background: #E4E6ED; } }

.table-title {
  font-size: 24px;
  line-height: 28px;
  margin-top: 48px;
  display: none; }
  @media (max-width: 767px) {
    .table-title {
      display: block; } }

.faq-item {
  border-top: 1px solid #BFBFBF;
  padding-top: 40px;
  padding-bottom: 0px;
  border-bottom: unset;
  display: block; }
  @media (max-width: 1139px) {
    .faq-item {
      padding-top: 34px; } }
  @media (max-width: 767px) {
    .faq-item {
      padding-top: 32px; } }
  .faq-item:last-child {
    border-bottom: 1px solid #BFBFBF; }
  .faq-item .how-works-item__title {
    padding-bottom: 30px;
    max-width: 80%; }
    @media (max-width: 767px) {
      .faq-item .how-works-item__title {
        max-width: 90%;
        padding-right: 10px; } }
  .faq-item .how-works-item__icon {
    top: 46px; }
    @media (max-width: 1139px) {
      .faq-item .how-works-item__icon {
        top: 40px; } }
  .faq-item .how-works-item__dsc {
    margin-bottom: 35px; }
    @media (max-width: 767px) {
      .faq-item .how-works-item__dsc {
        margin-bottom: 30px; } }

.faq-item.how-works-item--active .how-works-item__title {
  color: #247CFF; }

.faq-item.how-works-item--active .how-works-item__icon path {
  fill: #247CFF; }

.how-works-item.faq-item .how-works-item__title {
  transition: .3s; }

.how-works-item.faq-item path {
  transition: .3s; }

.how-works-item.faq-item:hover .how-works-item__title {
  color: #0962E5;
  transition: .3s; }

.how-works-item.faq-item:hover path {
  fill: #0962E5;
  transition: .3s; }

.tarifs {
  animation: .6s forwards fadeIn; }

.tariff-calc {
  background: #282829;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
  padding: 40px;
  padding-right: 124px;
  padding-bottom: 25px;
  display: flex;
  margin-top: 50px; }
  @media (max-width: 1139px) {
    .tariff-calc {
      flex-wrap: wrap;
      padding: 36px; } }
  @media (max-width: 767px) {
    .tariff-calc {
      padding: 24px;
      padding-bottom: 48px;
      border-radius: 15px; } }
  .tariff-calc__subtitle {
    font-weight: 500;
    color: #7E7E7F; }
    @media (min-width: 350px) {
      .tariff-calc__subtitle {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .tariff-calc__subtitle {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .tariff-calc__subtitle {
        font-size: 15px;
        line-height: 20px; } }
  .tariff-calc__title {
    letter-spacing: -0.01em;
    color: #FFFFFF;
    max-width: 228px;
    margin-bottom: 18px; }
    @media (min-width: 350px) {
      .tariff-calc__title {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 768px) {
      .tariff-calc__title {
        font-size: 20px;
        line-height: 25px; } }
    @media (min-width: 1140px) {
      .tariff-calc__title {
        font-size: 24px;
        line-height: 30px; } }
    @media (max-width: 1139px) {
      .tariff-calc__title {
        max-width: 100%;
        margin-top: 20px; } }
    @media (max-width: 767px) {
      .tariff-calc__title {
        margin-bottom: 0; } }
  .tariff-calc-col:first-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 37%;
    max-width: 37%; }
    @media (max-width: 1139px) {
      .tariff-calc-col:first-child {
        flex-basis: 100%;
        max-width: 100%; } }
  .tariff-calc-col:last-child {
    flex-basis: 63%;
    max-width: 63%; }
    @media (max-width: 1139px) {
      .tariff-calc-col:last-child {
        flex-basis: 100%;
        max-width: 100%;
        margin-top: 45px; } }
  .tariff-calc .range-header__title {
    color: #F4F4F4; }
    @media (min-width: 350px) {
      .tariff-calc .range-header__title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .tariff-calc .range-header__title {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .tariff-calc .range-header__title {
        font-size: 15px;
        line-height: 20px; } }
    @media (max-width: 1139px) {
      .tariff-calc .range-header__title {
        margin-bottom: 12px !important; } }
  .tariff-calc .range-header__count {
    color: #F4F4F4; }
  .tariff-calc .range-row {
    margin: 0 -25px; }
    @media (max-width: 767px) {
      .tariff-calc .range-row {
        margin: 0; } }
  .tariff-calc .range-row .col {
    padding: 0  25px; }
    @media (max-width: 767px) {
      .tariff-calc .range-row .col {
        padding: 0;
        flex-basis: 100%;
        max-width: 100%; } }
  .tariff-calc .range-footer div:first-child span {
    margin-right: 4px; }
    @media (max-width: 1139px) {
      .tariff-calc .range-footer div:first-child span {
        margin-right: 9px; } }
    @media (max-width: 767px) {
      .tariff-calc .range-footer div:first-child span {
        margin-right: calc((100vw - 335px)/ 10); } }
  .tariff-calc .range-header__title {
    margin-bottom: 6px; }
  .tariff-calc .days {
    width: 40px;
    height: 40px; }
    @media (max-width: 1139px) {
      .tariff-calc .days {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center; } }
    @media (max-width: 767px) {
      .tariff-calc .days {
        margin-right: 14px; } }
    .tariff-calc .days input {
      display: none; }
  .tariff-calc .col:last-child .range-wrap {
    margin-top: 6px; }
    @media (max-width: 767px) {
      .tariff-calc .col:last-child .range-wrap {
        margin-top: 2px;
        margin-bottom: 0; } }
  @media (max-width: 1139px) {
    .tariff-calc .range-footer {
      margin-top: 14px !important; } }
  @media (max-width: 767px) {
    .tariff-calc .range-footer {
      margin-top: 20px !important; } }

.table.mt-50 {
  margin-top: 50px; }

.table-title + .table-wrap .table {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.policy-title {
  letter-spacing: -0.02em;
  font-weight: 400;
  padding-right: 40px;
  margin-bottom: 50px; }
  @media (min-width: 350px) {
    .policy-title {
      font-size: 26px;
      line-height: 30px; } }
  @media (min-width: 768px) {
    .policy-title {
      font-size: 28px;
      line-height: 30px; } }
  @media (min-width: 1140px) {
    .policy-title {
      font-size: 32px;
      line-height: 33px; } }

.information-block__title {
  font-weight: 700;
  margin-bottom: 30px; }
  @media (min-width: 350px) {
    .information-block__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .information-block__title {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .information-block__title {
      font-size: 20px;
      line-height: 27px; } }
  @media (max-width: 767px) {
    .information-block__title {
      margin-bottom: 24px; } }
  .information-block__title + p {
    margin-top: 30px; }
    @media (max-width: 1139px) {
      .information-block__title + p {
        margin-top: 24px; } }

@media (min-width: 350px) {
  .information-block p {
    font-size: 17px;
    line-height: 22px; } }

@media (min-width: 768px) {
  .information-block p {
    font-size: 17px;
    line-height: 22px; } }

@media (min-width: 1140px) {
  .information-block p {
    font-size: 20px;
    line-height: 27px; } }

.information-block p + p {
  margin-top: 22px; }

.information-block + .information-block {
  margin-top: 52px; }
  @media (max-width: 1139px) {
    .information-block + .information-block {
      margin-top: 40px; } }

.information-block-nav {
  padding-right: 100px; }
  @media (max-width: 767px) {
    .information-block-nav {
      padding-right: 0;
      background: #F4F4F4;
      box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      position: absolute;
      top: 62px;
      padding: 12px;
      display: none; } }
  .information-block-nav__item {
    font-weight: 500;
    margin-bottom: 20px;
    color: #535354; }
    @media (min-width: 350px) {
      .information-block-nav__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 768px) {
      .information-block-nav__item {
        font-size: 14px;
        line-height: 18px; } }
    @media (min-width: 1140px) {
      .information-block-nav__item {
        font-size: 15px;
        line-height: 20px; } }
    .information-block-nav__item:hover {
      color: #0962E5; }
    .information-block-nav__item-active {
      color: #247CFF; }
    @media (max-width: 767px) {
      .information-block-nav__item {
        margin-bottom: 24px; }
        .information-block-nav__item:last-child {
          margin-bottom: 0; } }

@media (min-width: 767px) {
  .privacy-nav {
    top: 90px;
    position: sticky; } }

.policy-filter {
  position: relative; }
  @media (max-width: 767px) {
    .policy-filter {
      margin-bottom: 32px; } }
  .policy-filter .policy-filter__item {
    display: none; }
    @media (max-width: 767px) {
      .policy-filter .policy-filter__item {
        display: block;
        border-bottom: 1px solid #BFBFBF;
        font-size: 14px;
        line-height: 18px;
        color: #535354;
        padding-bottom: 10px;
        position: relative; }
        .policy-filter .policy-filter__item::after {
          content: '';
          position: absolute;
          width: 22px;
          height: 22px;
          right: -4px;
          top: -2px;
          background: url(../img/policy-arrow.svg) no-repeat center; } }

.information-block-nav--show {
  display: block; }

.policy-filter__item::after {
  transition: .3s; }

.policy-filter__item.active::after {
  transform: rotate(180deg);
  transition: .3s; }

.errorr {
  width: 600px;
  margin: 0 auto;
  text-align: center; }
  @media (max-width: 767px) {
    .errorr {
      width: 100%; } }
  .errorr__title {
    display: block;
    margin: 0 auto;
    font-size: 288px;
    line-height: 290px;
    font-weight: 700; }
    @media (max-width: 1139px) {
      .errorr__title {
        font-size: 190px;
        line-height: 190px; } }
    @media (max-width: 767px) {
      .errorr__title {
        font-size: 146px;
        line-height: 128px; } }
    .errorr__title span {
      color: #0962E6; }
      .errorr__title span:nth-child(2) {
        color: #fff;
        position: relative; }
        .errorr__title span:nth-child(2)::after {
          content: '';
          position: absolute;
          width: 610px;
          height: 397px;
          top: 50%;
          left: 64%;
          transform: translate(-50%, -50%);
          background: url(../img/errorr-bg.png) no-repeat;
          background-size: contain !important;
          z-index: -1;
          animation: 6s ease-in-out .2s infinite alternate pop;
          background-position: center; }
          @media (max-width: 1139px) {
            .errorr__title span:nth-child(2)::after {
              height: 292px;
              width: 394px; } }
          @media (max-width: 767px) {
            .errorr__title span:nth-child(2)::after {
              height: 190px;
              width: 80vw; } }
  @media (min-width: 350px) {
    .errorr__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .errorr__dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .errorr__dsc {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 1139px) {
    .errorr__dsc {
      margin-top: 10px; } }
  .errorr a {
    margin-top: 60px; }
    @media (max-width: 767px) {
      .errorr a {
        margin-top: 44px; } }

@keyframes pop {
  0%,
  40%,
  80% {
    transform: translate(-50%, -50%); }
  100%,
  20%,
  60% {
    transform: translate(-50%, -57%); } }

body {
  font-family: "Suisse Int";
  font-weight: 400;
  color: #090207; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: auto; }

.cnt {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 40px; }
  @media (max-width: 850px) {
    .cnt {
      padding: 0 16px; } }

p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0; }
  p span,
  h1 span,
  h2 span,
  h3 span,
  h4 span,
  h5 span {
    display: inline; }

span {
  display: inline-block; }

a {
  text-decoration: unset;
  color: inherit; }

input,
textarea {
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0; }

button {
  outline: none;
  transition: .3s;
  border: unset;
  cursor: pointer;
  white-space: nowrap;
  padding: 0;
  transform: translate3d(0, 0, 0);
  cursor: pointer; }
  button:hover {
    transition: .3s; }

ul {
  padding-left: 0;
  list-style: none;
  margin: 0; }

.ov-h {
  overflow: hidden; }

.dn {
  display: none; }

@media (max-width: 767px) {
  .mobile-none {
    display: none !important; } }

@media (min-width: 767px) and (max-width: 1260px) {
  .tablet-none {
    display: none !important; } }

@media (min-width: 1260px) {
  .desktop-none {
    display: none; } }

.show-mobile-f {
  display: none; }
  @media (max-width: 767px) {
    .show-mobile-f {
      display: flex; } }

.button-wrap {
  text-align: center;
  margin-top: 40px; }
  @media (max-width: 767px) {
    .button-wrap {
      margin-top: 0; } }

.pos-r {
  position: relative; }

.hide {
  display: none !important; }

.swiper-slide img {
  -webkit-transform: translateZ(0) !important;
  -webkit-backface-visibility: hidden !important; }

.disabled {
  pointer-events: none; }

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

.swiper-container-fade .swiper-slide-active {
  z-index: 10; }

.swiper-container.swiper-fade .swiper-slide {
  opacity: 0 !important; }

.swiper-container.swiper-fade .swiper-slide-active {
  opacity: 1 !important; }

.swiper-pagination {
  z-index: 8 !important; }

.sticky {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9; }

.text-gradient,
.title span,
.title-big span {
  background: -webkit-linear-gradient(0deg, #FFA53B 0.75%, #FF5E3B 44.75%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  display: inline; }

.mt-160 {
  margin-top: 160px; }
  @media (max-width: 1139px) {
    .mt-160 {
      margin-top: 120px; } }
  @media (max-width: 767px) {
    .mt-160 {
      margin-top: 80px; } }

.mt-120 {
  margin-top: 120px; }
  @media (max-width: 1139px) {
    .mt-120 {
      margin-top: 80px; } }

.mt-100 {
  margin-top: 100px; }
  @media (max-width: 1139px) {
    .mt-100 {
      margin-top: 80px; } }
  @media (max-width: 767px) {
    .mt-100 {
      margin-top: 52px; } }

.subtitle {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #7E7E7F; }
  @media (min-width: 350px) {
    .subtitle {
      font-size: 13px;
      line-height: 16px; } }
  @media (min-width: 768px) {
    .subtitle {
      font-size: 13px;
      line-height: 16px; } }
  @media (min-width: 1140px) {
    .subtitle {
      font-size: 15px;
      line-height: 18px; } }

.subtitle.mt-28 {
  margin-top: 28px; }
  @media (max-width: 1139px) {
    .subtitle.mt-28 {
      margin-top: 24px; } }
  @media (max-width: 767px) {
    .subtitle.mt-28 {
      margin-top: 0; } }

.mt-80 {
  margin-top: 80px; }
  @media (max-width: 1139px) {
    .mt-80 {
      margin-top: 60px; } }
  @media (max-width: 767px) {
    .mt-80 {
      margin-top: 40px; } }

.title {
  letter-spacing: -0.03em;
  font-weight: 500; }
  @media (min-width: 350px) {
    .title {
      font-size: 36px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .title {
      font-size: 34px;
      line-height: 38px; } }
  @media (min-width: 1140px) {
    .title {
      font-size: 54px;
      line-height: 55px; } }

.title-big {
  letter-spacing: -0.02em;
  font-weight: 500; }
  @media (min-width: 350px) {
    .title-big {
      font-size: 36px;
      line-height: 38px; } }
  @media (min-width: 768px) {
    .title-big {
      font-size: 42px;
      line-height: 44px; } }
  @media (min-width: 1140px) {
    .title-big {
      font-size: 72px;
      line-height: 72px; } }
  @media (max-width: 767px) {
    .title-big {
      font-size: 36px;
      line-height: 38px; } }
  .title-big span {
    display: inline; }

@media (max-width: 767px) {
  .mb-mt-20 {
    margin-top: 20px; } }

.title-big.mt-24 {
  margin-top: 24px; }
  @media (max-width: 767px) {
    .title-big.mt-24 {
      margin-top: 20px; } }

@media (min-width: 767px) {
  .sw-sl-mobile {
    width: unset;
    height: unset;
    flex-shrink: unset; } }

@media (min-width: 767px) {
  .sw-m {
    height: unset;
    flex-wrap: wrap;
    width: unset; } }

@media (max-width: 767px) {
  .sw-m {
    flex-wrap: nowrap !important; } }

@media (min-width: 767px) and (max-width: 1100px) {
  .md-mb-40 {
    margin-bottom: 40px; } }

.ov-v {
  overflow: visible; }

@media (max-width: 1139px) {
  .ov-v-t {
    overflow: visible; } }

@media (max-width: 767px) {
  .ov-v-m {
    overflow: visible; } }

.swiper-pagination-progressbar {
  position: relative; }

.swiper-pagination-progressbar {
  background: #F4F4F4;
  height: 8px !important; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #247CFF;
  height: 4px;
  margin-top: 2px; }

@media (max-width: 767px) {
  .sm-mt-40 {
    margin-top: 40px; } }

.mt-40 {
  margin-top: 40px; }
  @media (max-width: 767px) {
    .mt-40 {
      margin-top: 36px; } }

.section-black {
  background: #090207;
  padding-top: 140px;
  padding-bottom: 150px; }
  @media (max-width: 1139px) {
    .section-black {
      padding-top: 120px;
      padding-bottom: 120px; } }
  @media (max-width: 767px) {
    .section-black {
      padding-top: 80px;
      padding-bottom: 68; } }
  .section-black .title-big {
    color: #FFFFFF; }
  .section-black .button {
    margin-top: 50px; }
    @media (max-width: 1139px) {
      .section-black .button {
        margin-top: 40px; } }

.button.mt-50 {
  margin-top: 50px; }
  @media (max-width: 1139px) {
    .button.mt-50 {
      margin-top: 40px; } }

.button.mt-40 {
  margin-top: 40px; }

.show {
  display: block !important; }

.dsb {
  pointer-events: none; }

.hidden-opacity {
  opacity: 0;
  transition: .3s;
  visibility: hidden; }
  @media (max-width: 767px) {
    .hidden-opacity {
      display: none; } }

.df {
  display: flex; }

.mt-130 {
  margin-top: 130px; }

.pt-150 {
  padding-top: 150px; }
  @media (max-width: 1139px) {
    .pt-150 {
      padding-top: 110px; } }
  @media (max-width: 767px) {
    .pt-150 {
      padding-top: 100px; } }

.background-black {
  background: #090207; }

.mt-150 {
  margin-top: 150px; }
  @media (max-width: 1139px) {
    .mt-150 {
      margin-top: 110px; } }
  @media (max-width: 767px) {
    .mt-150 {
      margin-top: 100px; } }

.title-big-dsc {
  color: #7E7E7F;
  margin-top: 30px;
  max-width: 700px; }
  @media (min-width: 350px) {
    .title-big-dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .title-big-dsc {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .title-big-dsc {
      font-size: 20px;
      line-height: 24px; } }
  @media (max-width: 1139px) {
    .title-big-dsc {
      margin-top: 24px; } }
  @media (max-width: 767px) {
    .title-big-dsc {
      margin-top: 20px; } }

.subtitle-min {
  color: #7E7E7F; }
  @media (min-width: 350px) {
    .subtitle-min {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 768px) {
    .subtitle-min {
      font-size: 17px;
      line-height: 22px; } }
  @media (min-width: 1140px) {
    .subtitle-min {
      font-size: 20px;
      line-height: 24px; } }

.color-white {
  color: #fff; }

.z-2 {
  z-index: 2;
  position: relative; }

.filter.mt-50 {
  margin-top: 50px; }
  @media (max-width: 767px) {
    .filter.mt-50 {
      margin-top: 36px; } }

@media (min-width: 767px) {
  .mb-0 {
    margin-bottom: 0; } }

.adaptive-img {
  top: 0;
  height: 100%;
  width: 100%;
  max-height: 100%;
  position: absolute;
  max-width: 100%; }

@media (max-width: 767px) {
  .row.swiper-wrapper {
    flex-wrap: nowrap; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animated {
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.fadeInDownCustom {
  animation-name: fadeInDownCustom;
  -webkit-animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1); }

@keyframes fadeInDownCustom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
