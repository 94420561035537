@import "../../var";
@import "../../mix";

.client-item {
    background: #F4F4F4;
    border-radius: 15px;
    padding: 30px;
    min-height: 260px;
    margin-bottom: 20px;
    height: unset;
    width: 100%;

    @include respond-to(lg) {
        min-height: 318px;
        padding: 24px;
    }

    @include respond-to(md) {
        padding: 20px;
        padding-right: 17px;
        min-height: 328px;
        margin-bottom: 12px;
    }


    &__category-row {
        display: flex;
        flex-wrap: wrap;
    }

    &__category {
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        letter-spacing: 0.01em;
        color: $_main_blue;

        border: 1px solid #247CFF;
        border-radius: 50px;
    
        padding: 7px 12px;
        margin-right: 5px;

        margin-bottom: 8px;

        @include respond-to(lg) {
            padding: 4px 12px;

        }
    

        &+.clients-item__category {
            margin-right: 5px;
        }
        

    }

    &__name {
        @include font-size-get(p2);
        font-weight: 500;
        margin-top: 12px;
    }   

    &__dsc {
        @include font-size-get(p1);
        margin-top: 10px;
        color: #000000;
        @include respond-to(lg) {
            margin-top: 8px;
        }
    }

}

