
@import "../../var";
@import "../../mix";

.services-black {
    background: $_black;
    padding: 160px 0;
    @include respond-to(lg) {
        padding: 120px 0;
    }
    @include respond-to(md) {
        padding: 80px 0;
    }

    .col-sm-12 {
        @include respond-to(lg) {
            margin-bottom: 0;
        }
        @include respond-to(md) {
            margin-bottom: 0;
            &:first-child {
                .values-card {
                    margin-top: 0;
                }
            }
        }
      
    }

    .title  {
        color: #fff;
    }
    .values-card {
        background: #282829;
        border-radius: 15px;
        margin-top: 20px;
        padding: 30px;
        display: flex;
        @include respond-to(lg) {
            padding: 24px;
        }
        @include respond-to(md) {
            padding: 20px;
        }
    }
    .values-card__img {
        width: 120px;
        height: 120px;
        margin-right: 30px;

        @include respond-to(lg) {
            width: 100px;
            height: 100px;
            margin-right: 20px;
        }

        @include respond-to(md) {
            width: 80px;
            height: 80px;
            margin-right: 16px;
        }

    }
    .values-card__title {
        color: #fff;
        margin-top: 0;
    }
    .values-card__dsc {
        color: #BFBFBF;
        margin-top: 10px;        
        @include respond-to(md) {
            margin-top: 8px;
        }
    }

}