@import "../../var";
@import "../../mix";


.contact {
    &__title {
        @include font-size-get(p1);
        color: $_gray_60;
        margin-bottom: 30px;
    }

    &__link {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $_white;
        margin-bottom: 16px;
        transition: .3s;

        &:hover {
            color: $_main_blue;
            transition: .3s;
        }

        @include respond-to(lg) {
            font-size: 16px;
            line-height: 18px;
        }

        @include respond-to(md) {
            margin-bottom: 18px;
        }


    }

    &__link-socials {
        a {
            @include flex-c;

            svg {
                margin-right: 8px;
                transform: translate(0px, -3px);
            }
        }
    }

}

.contacts-row {
    max-width: 950px;

    .contact__link {
        color: $_black;
    }

    .footer__list-dsc {
        color: #090207;
    }

    .contact__link-socials {
        path {
            &:last-child {
                fill: $_white;
            }

            &:nth-child(1) {
                fill: $_black;
            }
        }
    }

    .col-xs-4 {
        margin-bottom: 64px;
        @include respond-to(md) {
            margin-bottom: 30px;
        }
    }

    .col-sm-12 {
        @include respond-to(md) {
            &:nth-last-child(-n+2) {
                flex-basis: 50% !important;
                max-width: 50% !important;

            }
        }
    }

    @include respond-to(md) {

        .footer__title {
            margin-bottom: 20px;
        }

        .contact__link {
            margin-bottom: 8px;
        }
    }

}

.contacts-bg {
    position: absolute;
    right: 0;
    top: -90px;
    z-index: -1;
    pointer-events: none;
    @include respond-to(md) {
        top: -75px;
    }
}


.footer {
    path {
        transition: .3s;
    }
  
}
.contact__link-socials {
    path {
        transition: .3s;
    }
    &:hover {
        path {
            transition: .3s;
            &:first-child {
                fill: $_main_blue;
            }
        }
    }
}
.contact__link {
    &:hover {
        color: $_main_blue;
    }
}