@import "../var";
@import "../mix";

.principles-card {
    border-radius: 15px;
    background: $_gray_100;
    padding: 40px 50px 50px 40px;
    height: 440px;
    color: $_white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to(lg) {
        padding: 40px 32px;
    }
    
    @include respond-to(md) {
        height: 300px;
        padding: 24px;
        padding-right: 32px;
        margin-bottom: 12px;
    }

    &--big {
        margin-bottom: 10px;
        padding-right: 125px;
        flex-direction: unset;
        @include respond-to(lg) {
            padding-right: 40px;
            height: 400px;
        }
        @include respond-to(md) {
            height: unset;
            flex-wrap: wrap;
            padding: 24px;
            padding-right: 32px;
            padding-bottom: 32px;
            .principles-card__title {
                margin-top: 24px;
            }
        }
        .col {
          
        }
        .col-1 {
            display: flex;
            flex-direction: column;
        
            justify-content: space-between;
            flex-basis: 60%;
            max-width: 60%;
            @include respond-to(lg) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @include respond-to(md) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
        .col-2 {
            flex-basis: 40%;
            max-width: 40%;
        
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
            @include respond-to(lg) {
                flex-basis: 50%;
                max-width: 50%;
            }
            @include respond-to(md) {
                flex-basis: 100%;
                max-width: 100%;
                margin-top: 86px;
                align-items: flex-start;
            }
        }
        .principles-card__title {
            max-width: 100%;
        }
    }

    &__subtitle {
        font-weight: 500;
        @include font-size-get(p1);
    }
    &__title {
        @include font-size-get(p4);
        letter-spacing: -0.01em;
        max-width: 425px;
    }

    &__numbers-title {
        font-weight: 500;
        font-size: 115px;
        line-height: 115px;
        letter-spacing: -0.01em;
        @include respond-to(lg) {
            font-size: 82px;
            line-height: 82px;
        }
        @include respond-to(md) {
            font-size: 80px;
            line-height: 80px;
        }
    }
    &__numbers {
        &:first-child {
            @include respond-to(md) {
                margin-bottom: 40px;
            }
        }
    }
    &__numbers-dsc {
        @include font-size-get(p1);
        font-weight: 500;
        color: $_gray_60;
        @include respond-to(lg) {
            margin-top: 5px;
        }
        @include respond-to(md) {
            margin-top: 0;
        }

        
    }

}


.principles-card__numbers {
    @include respond-to(lg) {
        max-width: 250px;
        margin-left: auto;
        margin-right: 0;
        width: 100%;
    }
}