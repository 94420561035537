
@import "../../var";
@import "../../mix";

.services-values-card-row {
    margin-top: 80px;
    @include respond-to(lg) {
        margin-top: 40px;
    }
    .col-sm-12 {
        @include respond-to(lg) {
            margin-bottom: 40px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

}