@import "../var";
@import "../mix";




.footer {

    padding-top: 150px;

    margin-top: 200px;
    position: relative;

    @include respond-to(lg) {
        padding-top: 160px;
    }

    @include respond-to(md) {
        margin-top: 130px;
        padding-top: 80px;
    }

    .main-banner__path,
    .animation-item {
        @include respond-to(md) {
            display: none !important;
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -2;
        background: $_black;
    }

    .cnt {
        position: relative;
    }

    .row {
        justify-content: space-between;
    }

    .col {
        padding: 0 10px;
    }

    .col-1 {
        flex-basis: 274px;
        max-width: 274px;

        @include respond-to(lg) {
            flex-basis: 30%;
            max-width: 30%;
            order: 0;
        }

        @include respond-to(md) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    .col-2 {
        flex-basis: 310px;
        max-width: 310px;

        @include respond-to(lg) {
            flex-basis: 40%;
            max-width: 40%;
            order: 1;
        }

        @include respond-to(md) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }

    .col-3 {
        flex-basis: 276px;
        max-width: 276px;

        @include respond-to(lg) {
            flex-basis: 100%;
            max-width: 100%;
            display: flex;
            justify-content: space-between;
            order: 3;
            width: 100%;
            padding: 0;

            @include respond-to(md) {
                flex-basis: 100%;
                max-width: 100%;
                flex-wrap: wrap;
                margin-top: 40px;
            }

            .footer-block {
                margin-top: 46px;
                padding: 0 10px;
                @include respond-to(lg) {
                    max-width: 30%;
                    flex-basis: 30%;
                }

                @include respond-to(md) {
                    margin-top: 0;
                    flex-basis: 50%;
                    max-width: 50%;
                    margin-bottom: 60px;
                }

                &:first-child {
                    flex-basis: 30%;
                    max-width: 30%;

                    @include respond-to(md) {
                        flex-basis: 50%;
                        max-width: 50%;
                        order: 1;

                    }
                }

                &:nth-child(2) {
                    flex-basis: 40%;
                    max-width: 40%;

                    @include respond-to(md) {
                        flex-basis: 50%;
                        max-width: 50%;
                        order: 0;


                    }
                }

                &:nth-child(3) {
                    flex-basis: 30%;
                    max-width: 30%;

                    @include respond-to(md) {
                        flex-basis: 50%;
                        max-width: 50%;
                        order: 2;

                    }
                }

                &:nth-child(4) {
                    
                    flex-basis: 30%;
                    max-width: 30%;
                    padding-left: 40px;

                    @include respond-to(md) {
                        flex-basis: 50%;
                        max-width: 50%;
                        order: 3;
                        padding-left: 0;

                    }
                }
            }
        }

    }

    .col-4 {
        flex-basis: 160px;
        max-width: 160px;

        @include respond-to(lg) {
            flex-basis: 30%;
            max-width: 30%;
            order: 2;
            padding-left: 40px;

        }

        @include respond-to(md) {
            flex-basis: 50%;
            max-width: 50%;
            padding-left: 0;
        }
    }


    &__title {
        @include font-size-get(p1);
        color: $_gray_60;
        margin-bottom: 30px;
    }

    &__link {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: $_white;
        margin-bottom: 16px;
        transition: .3s ease;

        &:hover {
            color: $_main_blue !important;
            transition: .3s ease;
        }

        @include respond-to(lg) {
            font-size: 16px;
            line-height: 18px;
        }

        @include respond-to(md) {
            margin-bottom: 18px;
        }


    }

    &-block {
        &+.footer-block {
            margin-top: 50px;
        }

        @include respond-to(md) {
            margin-top: 54px;
        }
    }

    &__list-dsc {
        @include font-size-get(p1);
        color: $_white;
        transform: translate(0px, -6px);
        display: block;
        color: $_gray_40;
    }


    &__link-socials {
        a {
            @include flex-c;

            svg {
                margin-right: 8px;
            }
        }
    }

    .footer__svg {
        position: absolute;
        width: 134%;
        right: -74%;
            bottom: -30px;
        display: flex;
        z-index: -1;

        @include respond-to(lg) {
            right: -95%;
            width: 180%;
        }
        @include respond-to(md) {
            right: 0px;
            width: 100%;
            picture {
                width: 100%;
            }
            img {
                display: block;
                margin-left: auto;
                object-fit: contain;
                width: 100%;
            }
        }
    }


    .main-banner__path-2 {
        position: absolute;
        left: 166px;
        bottom: 70px;
        opacity: 0;
        visibility: hidden;
    }

    .animation-item {
        position: absolute;
        width: 822px;
        height: 682px;
        top: -140px;
        left: 0px;
        z-index: -1;
        pointer-events: none;
        @include respond-to(md) {
            display: none;
        }
    }

}

.copyright-row {
    @include flex-sb;
    font-size: 12px;
    line-height: 18px;
    margin-top: 64px;
    padding-bottom: 30px;

    @include respond-to(lg) {
        margin-top: 160px;
    }

    @include respond-to(md) {
        margin-top: 0;

        align-items: flex-end;
    }

    div {
        @include respond-to(md) {
            &:first-child {
                display: flex;
                flex-direction: column;
            }

            &:last-child {}
        }
    }

    .copyright {
        color: $_gray_60;

        @include respond-to(md) {
            order: 1;
        }

        span {
            color: $_white;
        }
    }

    .copyright__politic {

        color: #FFFFFF;
        margin-left: 40px;

        @include respond-to(md) {
            margin-left: 0;
            order: 0;
            margin-bottom: 25px;

        }

    }

    .made-in {
        color: $_gray_60;

        a {
            color: $_white;
        }
    }
}



