@import "../../var";
@import "../../mix";

.faq-item {
    border-top: 1px solid #BFBFBF;

    padding-top: 40px;
    padding-bottom: 0px;

    border-bottom: unset;
    display: block;

    @include respond-to(lg) {
        padding-top: 34px;
    }


    @include respond-to(md) {
        padding-top: 32px;
    }


    &:last-child {
        border-bottom: 1px solid #BFBFBF;

    }

    .how-works-item__title {
        padding-bottom: 30px;
        max-width: 80%;
        @include respond-to(md) {
            max-width: 90%;
            padding-right: 10px;
        }

    }

    .how-works-item__icon {
        top: 46px;
        @include respond-to(lg) {
            top: 40px;
        }
    }

    .how-works-item__dsc {
        margin-bottom: 35px;
        @include respond-to(md) {
            margin-bottom: 30px;
        }
    }

}

.faq-item.how-works-item--active .how-works-item__title {
    color: $_main_blue;
}

.faq-item.how-works-item--active .how-works-item__icon path {
    fill: $_main_blue;
}

.how-works-item.faq-item {
    .how-works-item__title {
        transition: .3s;
    }
    path {
        transition: .3s;
    }
    &:hover {
        .how-works-item__title {
            color: $_dark_blue;
            transition: .3s;
        }
        path {
            fill: $_dark_blue;
            transition: .3s;
        }
    }
}