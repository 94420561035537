@import "../var";
@import "../mix";


.radio-button {
    display: block;
    position: relative;
    padding-left: 24px;

    margin-bottom: 15px;

    cursor: pointer;

    @include font-size-get(p1);
    

    color: $_gray_60;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover {
        color: #247CFF;
        .checkmark {
            border-color: #247CFF;
        }
    }



}

/* Hide the browser's default radio button */
.radio-button input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio-button .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #BFBFBF;
    transition: .3s;
    @include respond-to(md) {
        width: 16px;
        height: 16px;
    }

}

/* On mouse-over, add a grey background color */
.radio-button:hover input~.checkmark {
}

/* When the radio button is checked, add a blue background */
.radio-button input:checked~.checkmark {
    background-color: #247CFF;
    border: 1px solid #247CFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-button .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-button input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-button .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;

    @include respond-to(md) {
        top: 3px;
        left: 3px;    
    }

}