@import "../mix.scss";
@import "../var.scss";

.errorr {

    width: 600px;
    margin: 0 auto;
    text-align: center;

    @include respond-to(md) {
        width: 100%;
    }

    &__title {
        display: block;
        margin: 0 auto;
        font-size: 288px;
        line-height: 290px;
        font-weight: 700;

        @include respond-to(lg) {
            font-size: 190px;
            line-height: 190px;

        }

        @include respond-to(md) {
            font-size: 146px;
            line-height: 128px;
        }


        span {
            color: #0962E6;
            &:nth-child(2) {
                color: #fff;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    width: 610px;
                    height: 397px;
                    top: 50%;
                    left: 64%;
                    transform: translate(-50%,-50%);
                    background: url(../img/errorr-bg.png) no-repeat;
                    background-size: contain !important;
                    z-index: -1;
                    animation: 6s ease-in-out .2s infinite alternate pop;
                    background-position: center;

                    @include respond-to(lg) {
                        height: 292px;
                        width: 394px;
                    }   
                    @include respond-to(md) {
                        height: 190px;
                        width: 80vw;

                    }
                }
            }
        }
    }
    &__dsc {
        @include font-size-get(p2);
        @include respond-to(lg) {
            margin-top: 10px;
        }
    }
    a {
        margin-top: 60px;
        @include respond-to(md) {
            margin-top: 44px;
        }
    }
}


@keyframes pop {

    0%,
    40%,
    80% {
        transform: translate(-50%,-50%);

    }

    100%,
    20%,
    60% {
        transform: translate(-50%,-57%);

    }
}