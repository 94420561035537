@import "../../var";
@import "../../mix";



.about-page-banner {

    padding-bottom: 150px;
    @include respond-to(lg) {
        padding-bottom: 120px;
    }
    @include respond-to(md) {
        padding-bottom: 80px;
    }
  
    &__img {
        
        height: 580px;

        @include respond-to(lg) {
            height: 377px;
        }

        @include respond-to(md) {
            height: 413px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }

}

.ui-img {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}