@import "../var";
@import "../mix";


.advantages-card {
    position: relative;
    padding-top: 20px;

    @include respond-to(md) {
        padding-top: 24px;
        margin-bottom: 36px;
    }

    @include respond-to(lg) {
        &:nth-child(1) {

        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        background: $_gray_40;
        width: calc(100% - 15px);
    }
    &__title {
        @include font-size-get(p2);
        font-weight: 500;
    }
    &__dsc {
        @include font-size-get(p1);
        color: $_gray_60;
        margin-top: 10px;
    }
}

.advantages-slider {
    @include respond-to(md) {
        .swiper-slide {
            padding: 0;
            max-width: 244px !important;
        }
        .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}