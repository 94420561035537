@import "../var";
@import "../mix";


.main-banner {
    padding-top: 100px;
    // padding-bottom: 90px;
    overflow: hidden;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: $_gray_20;
        z-index: -2;
    }

    .cnt {
        position: relative;
    }

    @include respond-to(lg) {
        padding-top: 38px;
        // padding-bottom: 76px;
    }

    &__title {
        @include font-size-get(h1);
        margin-top: 32px;

        @include respond-to(lg) {
            margin-top: 82px;
        }

        @include respond-to(md) {
            margin-top: 55px;
        }
    }

    &__dsc {
        @include font-size-get(h5);
        max-width: 700px;
        letter-spacing: -0.02em;
    }

    &__buttons {
        display: flex;
        margin-top: 40px;
        padding-bottom: 90px;

        @include respond-to(lg) {
            padding-bottom: 76px;
        }

        @include respond-to(md) {
            flex-wrap: wrap;
            margin-top: 50px;
        }

        .button {
            margin-right: 15px;

            @include respond-to(md) {
                margin-right: 0;
                margin-bottom: 8px;
                width: 100%;
            }

            &:last-child {
                margin-right: 0;
                margin-bottom: 0;
            }
        }

    }

    &__info {
        margin-top: 216px;

        @include respond-to(lg) {
            margin-top: 240px;
        }

        @include respond-to(md) {
            margin-top: 255px;
        }
    }

    &__svg {
        position: absolute;
        width: 1148px;
        right: -41%;
        bottom: 0;
        display: flex;
        z-index: -1;
        opacity: 0;
        animation: fadeIn 1s .3s forwards;

        picture {
            display: flex;
        }
        @include respond-to(lg) {
            width: 647px;
            right: 0;
        }
        @include respond-to(md) {
            width: 316px;
            right: 0;
            bottom: unset;
            top: -90px;
        
        }
    }

    .main-banner__path {
        position: absolute;
        left: 166px;
        bottom: 70px;
        opacity: 0;
        visibility: hidden;
        @include respond-to(lg) {
            left: 100px;
        }
    }

    .animation-item {
        position: absolute;
        width: 822px;
        height: 682px;
        top: -140px;
        left: -150px;
        z-index: -1;
        pointer-events: none;
        @include respond-to(md) {
            left: unset;
            right: 0;
            width: unset;
            height: unset;
            top: 70px;
            animation: mainPageAnimationItem 4s infinite linear;
        }
    }
}


@keyframes mainPageAnimationItem {
    0% {
        transform: translateY(0px);
    }   
    50% {
        transform: translateY(-250px);
    } 
    100% {
        transform: translateY(0px);
    }
}