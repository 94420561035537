@import "../var";
@import "../mix";


.form-nav .wrap > div {
    display: flex;
}

.form-nav {
    margin-top: 50px;
    @include respond-to(md) {
        margin-top: 30px;
    }
    &__item {
        @include font-size-get(p1);
        font-weight: 500;
        color: #BFBFBF;
        margin-right: 15px;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
        transition: .3s;

        @include respond-to(md) {
            margin-right: 25px;
        }

        span {
            @include respond-to(md) {
               
            }
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            width: 100%;
            height: 2px;
            background: #247CFF;
            border-radius: 4px;
            opacity: 0;
            transition: .3s;
        }

        span {
            &:first-child {
                margin-right:  2px;
            }
            &:last-child {
               @include respond-to(md) {
                display: none;
               }
            }
        }

        &--active {
            color: #247CFF;
            transition: .3s;
            pointer-events: none;

            span {
                @include respond-to(md) {
                    &:last-child {
                        display: inline;
                    }
                }
            }

            &::after {
                opacity: 1;
                transition: .3s;
            }
        }

    }
}

.price-tab {
    &__dsc {
        @include font-size-get(p1);
        color: #535354;
        margin-top: 20px;
        margin-bottom: 50px;
        @include respond-to(md) {
            margin-bottom: 32px;
        }
    }
}

.modal-footer {
    display: flex;
    justify-content: space-between;

    &__title {
        @include font-size-get(p3);
        color: $_gray_80;
        display: block;
    }
    &__price {
        display: block;
        @include font-size-get(p3);
        font-weight: 500;
        margin-top: 1px;
    }
    &-buttons {
        display: flex;
        align-items: end;

        @include respond-to(md) {
            transform: unset;
            margin-top: 16px;
        }

        .button-prev {
            margin-right: 10px;
        }

    }
}

.modal__container-price {
    // min-height: 594px;
    position: relative;
    .modal-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 80px);
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 40px;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include respond-to(md) {
            width: calc(100% - 48px);
            margin-left: 24px;
            margin-right: 24px;
            margin-bottom: 20px;


            .button {
                padding-left: 25px;
                padding-right: 25px;
            }
        }

    }
}

.conditions {
    margin-top: 40px;

    &__dsc {
        padding-left: 22px;
        font-size: 12px;
        line-height: 16px;
        color: #7E7E7F;

        a {
            color: #247CFF;
        }

    }
}

.form-price {
   .form-wrap {
    padding-bottom: 80px;
   }
}