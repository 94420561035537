@import "../../var";
@import "../../mix";


.person-card {
    display: flex;
    margin-bottom: 10px;
    background: #090207;
    border-radius: 15px;
    overflow: hidden;
    min-height: 360px;
    height: unset;
    width: 100%;

    @include respond-to(lg) {
        flex-wrap: wrap;
    }

    @include respond-to(md) {
        display: block;
    }

    &:hover {
        .arrow-icon {
            background: $_main_blue;
            transition: .3s ease;
            &::after {
                @media (min-width: 1024px) {
                    animation: arrowAnimation 1s forwards;
                }
            }
        }
    }
    &__img {
        flex-basis: 46.5%;
        max-width: 46.5%;
        display: flex;
        align-items: end;
        picture {
            height: inherit;
            width: 100%;
        }
        @include respond-to(lg) {
            flex-basis: 100%;
            max-width: 100%;
            height: 219px;


          

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;

            }

        }

    
    }

    &__info {
        flex-basis: 53.5%;
        max-width: 53.5%;
        padding: 35px 20px 30px 30px;


        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include respond-to(lg) {
            flex-basis: 100%;
            max-width: 100%;
            padding: 24px;
            padding-right: 38px;

            height: calc(100% - 219px)
        }
        @include respond-to(md) {
            height: unset
        }

    

    }

    &__expirience {
        @include font-size-get(h2);
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: $_gray_60;
    }

    &__name {
        @include font-size-get(h5);
        margin-top: 10px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        font-weight: 400;
        @include respond-to(md) {
            margin-top: 16px;
        }
    }

    &__dsc {
        margin-top: 20px;
        @include font-size-get(p1);
        color: $_gray_20;
        margin-bottom: 20px;
        @include respond-to(lg) {
            margin-top: 16px;
            margin-bottom: 32px;
        }
        @include respond-to(md) {
            margin-top: 12px;
        }


    }
}
