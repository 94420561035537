@import "../../var";
@import "../../mix";


#servicesSliderMobile {
    overflow: visible;

    .swiper-notification {
        display: none !important;
    }
}

.services-slider {


    .row {
        @include respond-to(md) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .col {
        padding: 0 10px;

        @include respond-to(md) {
            flex-basis: 100% !important;
            max-width: 100% !important;
        }

        &:first-child {
            flex-basis: 65%;
            max-width: 65%;

            @include respond-to(lg) {
                max-width: 60%;
                flex-basis: 60%;
            }

            @include respond-to(md) {
                order: 1;
                margin-top: 32px;
            }
        }

        &:last-child {
            flex-basis: 35%;
            max-width: 35%;

            @include respond-to(lg) {
                max-width: 40%;
                flex-basis: 40%;
            }

            @include respond-to(md) {
                order: 0;
            }

            @media (min-width: 767px) {
                display: block;
            }
        }
    }

    .img {
        position: relative;

        max-width: 96%;
        padding-bottom: 60%;
        border-radius: 15px;
        overflow: hidden;

        animation: fadeIn .6s forwards;

        @include respond-to(lg) {
            max-width: 100%;
            padding-bottom: 62%;

        }

        @include respond-to(md) {
            max-width: 100%;
            padding-bottom: 63%;

        }

    }
}

.services-slider__item {
    border-radius: 15px;
    padding: 30px;
    padding-bottom: 0;
    // background: #EAF2FF;
    padding-top: 0;
    padding-bottom: 0;
    cursor: pointer;
    position: relative;

    transition: .3s ease;

    max-width: 374px;
    margin-left: auto;
    width: 100% !important;

    @include respond-to(lg) {
        padding: 20px;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include respond-to(md) {

        max-width: 76%;
        min-width: 276px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #EAF2FF;
        z-index: -1;
        border-radius: 15px;
        opacity: 0;
        transition: .3s;

    }

    &-title {
        @include font-size-get(p2);
        font-weight: 500;
        padding: 12.5px 0;
        transition: .3s ease;

        @include respond-to(lg) {
            padding: 8px 0;
        }

        @include respond-to(md) {
            padding: 0;
        }

    }

    &-dsc {

        @include font-size-get(p1);
        color: $_gray_80;
        display: none;
                        
        @include respond-to(md) {
            display: block;
            margin-top: 8px;
        }
                   
    }

    &—active {

        padding-top: 17.5px;
        padding-bottom: 30px;
        margin-bottom: 10px;

        @include respond-to(lg) {
            padding-bottom: 20px;
        }

        @include respond-to(md) {
            padding: 20px;
        }

        .services-slider__item-title {
            @media (min-width: 767px) {
                color: $_main_blue;
                transition: .3s ease;
            }
        }

        &::after {
            opacity: 1;
            transition: .3s;
        }

        .services-slider__item-dsc {
            display: block;

        }
    }
}

.services-slider__item.swiper-slide-active {
    background: #EAF2FF;
}
