@import "../var";
@import "../mix";


.client-card {
    border-radius: 15px;    
    background: $_gray_20;
    padding: 54px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    
    @include respond-to(lg) {
        padding: 32px;
    }
    @include respond-to(md) {
        padding: 24px;
        padding-top: 32px;
        
    }
    .row {
        &:nth-child(2) {
            position: relative;
            padding-top: 20px;
            margin-top: 86px;
            @include respond-to(md) {
                padding-top: 0;
                margin-top: 40px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 15px;
                width: calc(100% - 30px);
                background: $_gray_40;
                height: 1px;
                @include respond-to(md) {
                    display: none;
                }
            }
        }
    }
    &__dsc {
        @include font-size-get(p3);
        
        @include respond-to(md) {
            margin-top: 50px;
        }
    }
    &__logo {
        @include respond-to(lg) {
            padding-right: 20px;
        }
        @include respond-to(md) {
            width: 60%;
        }
    }
    &-advantages {
        max-width: 230px;
        @include respond-to(md) {
            margin-top: 20px;

        }
        &__title {
            font-weight: 700;
            @include font-size-get(p2);
            
        }   
        &__dsc {
            @include font-size-get(p1);
            color: $_gray_60;
            margin-top: 10px;
            display: block;
            @include respond-to(md) {
                margin-top: 6px;
            }
        }
    }
}

.client-slider {
    margin-top: 80px;
    @include respond-to(lg) {
        margin-top: 60px;
    }
    .swiper-slide {
        max-width: 970px;
        transform: scale(.97);
        transition: 1s ease;
        transform-origin: center;

        display: flex;
        height: unset;

        @media (max-width:900px) {
            max-width: 700px;
        }

        @include respond-to(lg) {
            transform-origin: left;
        }
        @include respond-to(md) {
            max-width: 98% !important;
        }
        @include respond-to(md) {
            transform: scale(1);
        }
    }
    .swiper-slide-active {
        transform: scale(1);
        transition: 1s ease;
    }
    a {
        display: block;
    }
}