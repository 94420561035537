@import "../../var";
@import "../../mix";


.vacancy-card {
    width: 100%;
    height: unset;
    min-height: 420px;
    background:  $_gray_20 ;
    margin-bottom: 20px;
    padding: 35px 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(md) {
        margin-bottom: 12px;
    }


    @include respond-to(md) {
        padding: 28px 20px;
    }

    &__name {
        @include font-size-get(p6);
        font-weight: 500;
        letter-spacing: -0.01em;

    }
    &__price {
        @include font-size-get(p6);
        font-weight: 500;
        letter-spacing: -0.01em;

    }
    &__dsc {
        @include font-size-get(p1);
        margin-top: 15px;
    }
    &__link {
        margin-top: 30px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #247CFF;

        @include flex-c;
        
        
        &:hover {
            color: $_dark_blue;
            transition: .3s;
            path {  
                stroke: $_dark_blue;
                transition: .3s;
            }
        }

        svg {
            margin-left: 2px;
            path {
                transition: .3s;
            }
        }

    }


}