@import "../../var";
@import "../../mix";


.repair-block  {
    .img {
        position: relative;
        padding-bottom: 106%;
        width: 87%;
        border-radius: 15px;
        overflow: hidden;
        @include respond-to(lg) {
            width: 92%;
            padding-bottom: 112%;
        }
        @include respond-to(md) {
            width: 100%;
            padding-bottom: 122%;
            margin-top: 40px;
        }
    }
    &__title {
        @include font-size-get(h4);
        letter-spacing: -0.03em;
        font-weight: 500;
    }
    &__dsc {
        @include font-size-get(p3);
        margin-top: 30px;
        @include respond-to(lg) {
            margin-top: 24px;
        }
        span {
            background: -webkit-linear-gradient(0deg, #FFA53B 0.75%, #FF5E3B 44.75%);
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;
            display: inline;
        }
        ul {
            margin-top: 8px;
            li {
                position: relative;
                padding-left: 20px;
                @include font-size-get('p3');
                @include respond-to(md) {
                    padding-left: 18px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $_black;
                    top: 10px;
                    left: 5px;
                    @include respond-to(lg) {
                        top: 8px;
                    }

                }
            }
        }

    }
    &__row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        margin-top: 80px;
        @include respond-to(lg) {
            margin-left: 0;
            margin-right: 0;
            margin-top: 40px;
        }
        .col {
            padding: 0 10px;
            flex-basis: 50%;
            max-width: 50%;
            @include respond-to(lg) {
                padding-left: 0;
                padding-right: 0;

                flex-basis: 100%;
                max-width: 100%;

                display: flex;
                
                &+.col {
                    margin-top: 28px;
                }

            }
            img {
                width: 80px;
                height: 80px;
                border-radius: 15px;
                max-width: 80px;
                @include respond-to(lg) {
                    width: 72px;
                    height: 72px;
                    margin-right: 16px;
                }
            }
            span {
                @include font-size-get(p2);
                margin-top: 20px;
                @include respond-to(lg) {
                    margin-top: 0;
                }
            }
        }
    }
    .col-sm-12 {
       @include respond-to(md) {
        &:first-child {
            order: 1;
        }
        &:last-child {
            order: 0;   
        }
       }
    }
}