.row {
    margin: 0 -10px;
}

.row-offset-10 {
    margin: 0 -5px;

    .col-xs-1,
    .col-xs-2,
    .col-xs-3,
    .col-xs-4,
    .col-xs-5,
    .col-xs-6,
    .col-xs-7,
    .col-xs-8,
    .col-xs-9,
    .col-xs-10,
    .col-xs-11,
    .col-xs-12 {
        padding: 0 5px;
    }

}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    padding: 0 10px;
}

@import "mix";
@import "var";
@import "fonts";
@import "ui";

@import "header";
@import "buttons";
@import "components/main-banner.scss";
@import "components/services-card.scss";
@import "components/advantages-card.scss";
@import "components/about-banner.scss";
@import "components/principles-card.scss";
@import "components/client-card.scss";
@import "components/how-works-item.scss";
@import "components/input.scss";
@import "components/form-audit.scss";
@import "components/radiobutton.scss";
@import "components/footer.scss";
@import "components/range.scss";
@import "components/days.scss";
@import "components/modalPrice.scss";
@import "components/checkbox.scss";
@import "components/filter.scss";
@import "mobileMenu.scss";
@import "modal.scss";

// pages

@import "pages/contacts/contacts.scss";

@import "pages/client/client-card.scss";
@import "pages/client/map.scss";

@import "pages/about/about.scss";
@import "pages/about/person-card.scss";
@import "pages/about/vaccancy.scss";
@import "pages/about/values.scss";
@import "pages/about/certificate.scss";


@import "pages/blog/blog.scss";
@import "pages/blog/paginate.scss";
@import "pages/blog/category.scss";
@import "pages/blog/article.scss";

@import "pages/service/service-banner.scss";
@import "pages/service/services.scss";
@import "pages/service/advantages-work-card.scss";
@import "pages/service/stat-item.scss";
@import "pages/service/services-black.scss";
@import "pages/service/skills-card.scss";
@import "pages/service/repair-block.scss";
@import "pages/service/services-slider.scss";

@import "pages/team/team.scss";

@import "pages/tarif/table.scss";
@import "pages/tarif/faq.scss";
@import "pages/tarif/tariff-calc";

@import "components/privacy.scss";

@import "pages/404.scss";

body {
    font-family: $font-family-base;
    font-weight: 400;
    color: $_black;
}




html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: auto;
}





.cnt {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 40px;

    @media (max-width: 850px) {
        padding: 0 16px;
    }


}


p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;

    span {
        display: inline;
    }
}

span {
    display: inline-block;
}

a {
    text-decoration: unset;
    color: inherit;
}

input,
textarea {
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;
}

button {
    outline: none;
    transition: .3s;
    border: unset;
    cursor: pointer;
    white-space: nowrap;
    padding: 0;
    transform: translate3d(0, 0, 0);
    cursor: pointer;

    &:hover {
        transition: .3s;
    }
}

ul {
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.ov-h {
    overflow: hidden;
}

.dn {
    display: none;
}




.mobile-none {
    @include respond-to(md) {
        display: none !important;
    }
}

.tablet-none {
    @media (min-width:767px) AND (max-width: 1260px) {
        display: none !important;
    }
}

.desktop-none {
    @media (min-width: 1260px) {
        display: none;
    }
}

.show-mobile-f {
    display: none;

    @include respond-to(md) {
        display: flex;
    }
}



.button-wrap {
    text-align: center;
    margin-top: 40px;

    @include respond-to(md) {
        margin-top: 0;
    }
}



.pos-r {
    position: relative;
}


.hide {
    display: none !important;
}







.swiper-slide img {
    -webkit-transform: translateZ(0) !important;
    -webkit-backface-visibility: hidden !important;
}

.disabled {
    pointer-events: none;
}

.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
}

.swiper-container-fade .swiper-slide-active {
    z-index: 10
}

.swiper-container.swiper-fade {
    .swiper-slide {
        opacity: 0 !important;
    }

    .swiper-slide-active {
        opacity: 1 !important;
    }

}






.swiper-pagination {
    z-index: 8 !important;
}

.sticky {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
}


.text-gradient,
.title span,
.title-big span {
    background: -webkit-linear-gradient(0deg, #FFA53B 0.75%, #FF5E3B 44.75%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    display: inline;
}


.mt-160 {
    margin-top: 160px;

    @include respond-to(lg) {
        margin-top: 120px;
    }

    @include respond-to(md) {
        margin-top: 80px;
    }
}
.mt-120 {
    margin-top: 120px;
    @include respond-to(lg) {
        margin-top: 80px;
    }

   
}

.mt-100 {
    margin-top: 100px;
    @include respond-to(lg) {
        margin-top: 80px;
    }

    @include respond-to(md) {
        margin-top: 52px;
    }
}

.subtitle {
    @include font-size-get(h2);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $_gray_60;
}

.subtitle.mt-28 {
    margin-top: 28px;

    @include respond-to(lg) {
        margin-top: 24px;
    }

    @include respond-to(md) {
        margin-top: 0;
    }
}

.mt-80 {
    margin-top: 80px;

    @include respond-to(lg) {
        margin-top: 60px;
    }

    @include respond-to(md) {
        margin-top: 40px;
    }
}

.title {
    @include font-size-get(h4);
    letter-spacing: -0.03em;
    font-weight: 500;
}

.title-big {
    @include font-size-get(h3);
    letter-spacing: -0.02em;
    font-weight: 500;

    @include respond-to(md) {
        font-size: 36px;
        line-height: 38px;

    }
	span {
		display: inline;
	}

}

.mb-mt-20 {
    @include respond-to(md) {
        margin-top: 20px;
    }
}

.title-big.mt-24 {
    margin-top: 24px;

    @include respond-to(md) {
        margin-top: 20px;
    }
}


.sw-sl-mobile {
    @media (min-width: 767px) {
        width: unset;
        height: unset;
        flex-shrink: unset;
    }
}

.sw-m {
    @media (min-width: 767px) {
        height: unset;
        flex-wrap: wrap;
        width: unset;
    }

    @media (max-width: 767px) {
        flex-wrap: nowrap !important;
    }
}

.md-mb-40 {
    @media (min-width:767px) AND (max-width: 1100px) {
        margin-bottom: 40px;
    }
}


.ov-v {
    overflow: visible;
}

.ov-v-t {
    @include respond-to(lg) {
        overflow: visible;
    }
}

.ov-v-m {
    @include respond-to(md) {
        overflow: visible;
    }
}

.swiper-pagination-progressbar {
    position: relative;
}

.swiper-pagination-progressbar {
    background: $_gray_20;
    height: 8px !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: $_main_blue;
    height: 4px;
    margin-top: 2px;
}


.sm-mt-40 {
    @include respond-to(md) {
        margin-top: 40px;
    }
}

.mt-40 {
    margin-top: 40px;

    @include respond-to(md) {
        margin-top: 36px;
    }
}


.section-black {
    background: $_black;
    padding-top: 140px;
    padding-bottom: 150px;

    @include respond-to(lg) {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    @include respond-to(md) {
        padding-top: 80px;
        padding-bottom: 68;
    }

    .title-big {
        color: $_white;
    }

    .button {
        margin-top: 50px;

        @include respond-to(lg) {
            margin-top: 40px;
        }
    }
}


.button.mt-50 {
    margin-top: 50px;

    @include respond-to(lg) {
        margin-top: 40px;
    }
}

.button.mt-40 {
    margin-top: 40px;
}

.show {
    display: block !important;
}

.dsb {
    pointer-events: none;
}

.hidden-opacity {
    opacity: 0;
    transition: .3s;
    visibility: hidden;
    @include respond-to(md) {
        display: none;
    }
}


.df {
    display: flex;
}

.mt-130 {
    margin-top: 130px;

    @include respond-to(lg) {}

    @include respond-to(md) {}
}

.pt-150 {
    padding-top: 150px;

    @include respond-to(lg) {
        padding-top: 110px;

    }

    @include respond-to(md) {
        padding-top: 100px;

    }
}

.background-black {
    background: $_black;
}



.mt-150 {
    margin-top: 150px;

    @include respond-to(lg) {
        margin-top: 110px;

    }

    @include respond-to(md) {
        margin-top: 100px;

    }
}

.title-big-dsc {
    @include font-size-get(p2);
    color: $_gray_60;
    margin-top: 30px;
    max-width: 700px;

    @include respond-to(lg) {
        margin-top: 24px;
    }

    @include respond-to(md) {
        margin-top: 20px;
    }
}

.subtitle-min {
    @include font-size-get(p2);
    color: $_gray_60;
}

.color-white {
    color: #fff;
}

.z-2 {
    z-index: 2;
    position: relative;
}

.filter.mt-50 {
    margin-top: 50px;
    @include respond-to(md) {
        margin-top: 36px;
    }
}

.mb-0 {
    @media (min-width:767px) {
        margin-bottom: 0;
    }
}

.adaptive-img {
    top: 0;
    height: 100%;
    width: 100%;
    max-height: 100%;
    position: absolute;
    max-width: 100%;
}

.row.swiper-wrapper {
    @include respond-to(md) {
        flex-wrap: nowrap;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animated {
    opacity: 0;
    -webkit-animation-duration: 1s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

}

.fadeInDownCustom {
    animation-name: fadeInDownCustom;
    -webkit-animation-timing-function: cubic-bezier(.22,1,.36,1);
    animation-timing-function: cubic-bezier(.22,1,.36,1)
}

@keyframes fadeInDownCustom {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        transform: translateY(40px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}
