@import "var";
@import "mix";

.disable-scroll {
    position: relative;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
}

.modal {
    --transition-time: 0.3s;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity var(--transition-time), visibility var(--transition-time);

    &__title {
        @include font-size-get(h5);
        letter-spacing: -0.02em;

    }

    &__recall {
        width: 100px;
    }

    .message-success {
        margin-top: 70px;
        @include respond-to(lg) {
            margin-top: 54px;
        }
        @include respond-to(md) {
            margin-top: 96px;
        }

        &__title {
            @include font-size-get(p4);
            font-weight: 500;
            letter-spacing: -0.01em;

        }

        &__dsc {
            @include font-size-get(p1);
            margin-top: 15px;
            max-width: 409px;
        }

        .button {
            margin-top: 30px;
        }
    }

}

.modal::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;

    @include respond-to(md) {
        display: none;
    }
}

.modal__container {
    position: relative;
    width: 1000px;
    margin: 50px auto;
    display: inline-block;
    vertical-align: middle;
    background-color: #fff;
    border-radius: 15px;
    padding: 40px;
    padding-bottom: 50px;
    cursor: default;
    text-align: left;
    display: none;


    @include respond-to(md) {
        margin-left: 16px;
        margin-right: 16px;
        width: calc(100% - 32px);
    }

    &-recall {
        width: 955px;

        @include respond-to(lg) {
            width: 685px;
        }

        @include respond-to(md) {
            width: calc(100% - 32px);

        }
    }


    &-price {
        width: 682px;
        @include respond-to(md) {
            width: calc(100% - 32px);
        }
    }


    @include respond-to(lg) {
        padding: 32px;
    }
    @include respond-to(md) {
        padding: 24px;
    }

}


.modal__close {
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    padding: 0;
    width: 35px;
    height: 35px;
    cursor: pointer;

    &:hover {
        path {
            stroke: $_dark_blue;
        }
    }

    @include respond-to(lg) {
        right: 10px;
        top: 10px;
    }



}

.modal.is-open {
    opacity: 1;
    visibility: visible;
    transition: opacity var(--transition-time), visibility var(--transition-time);
}

.modal__container.modal-open {
    display: inline-block;
}

.fade {
    opacity: 0;
    transition: opacity var(--transition-time);
}

.fade.animate-open {
    opacity: 1;
    transition: opacity var(--transition-time);
}

.fadeInUp {
    opacity: 0;
    transform: translateY(-100px);
    transition: transform var(--transition-time), opacity var(--transition-time);
}

.fadeInUp.animate-open {
    opacity: 1;
    transform: translateY(0);
    transition: transform var(--transition-time), opacity var(--transition-time);
}

.price-tab#tab-2,
.price-tab#tab-3 {
    @include respond-to(md) {
        padding-bottom: 74px;
    }
}

.modal__container-recall {
    .message-success {
        .title-big {
            @include font-size-get(p4);
            font-weight: 500;
        }
        .message-success__dsc {
            @include font-size-get(p1);

        }   
    }
}


section {
    .message-success__dsc {
        display: none;
    }
}