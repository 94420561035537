@import "../var";
@import "../mix";

.days-row {
    display: flex;
    margin-top: 6px;
    flex-wrap: wrap;

            margin-right: -14px;

    @include respond-to(md) {
        margin-top: 0;
    }

}
.days {
    height: 36px;
    width: 36px;
    border: 1px solid #7E7E7F;
    border-radius: 10px;
  

    position: relative;
    display: block;
    margin-right: 4px;
    transition: .3s;

    margin-bottom: 16px;

    @include flex-c-c;

    cursor: pointer;

    input {
        display: none;
    }

    @include respond-to(lg) {
        width: 48px;
        height: 48px;
    }
    @include respond-to(md) {
        margin-right: 14px;
        margin-bottom: 14px;
    }

    label {
        font-size: 14px;
        line-height: 16px;  
        color: #7E7E7F; 
     
        transition: .3s;
        pointer-events: none;
        @include respond-to(lg) {
        
        }
        @include respond-to(md) {
        }

    }
    &:hover {
       @media (min-width:767px) {
        border: 1px solid #247CFF;
        transition: .3s;

        label {
            color: #247CFF;
            transition: .3s;

        }
       }

    }
}
.days.active {
    border: 1px solid #247CFF;
    background: #247CFF;
    label {
        color: #fff;
    }
}