@import "../../var";
@import "../../mix";

#map {
    width: 100%;
    height: 650px;
    border-radius: 15px;
    overflow: hidden;

    margin-top: 70px;


    @include respond-to(lg) {
        height: 422px;
        margin-top: 50px;
    }
    @include respond-to(md) {
        height: 480px;
        margin-top: 32px;
    }
}



.object-balloon {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    border-radius: 10px;
    padding: 20px 40px 20px 20px;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.04);


    .close {
        position: absolute;
        display: flex;
        top: 10px;
        right: 0px;
        cursor: pointer;
        width: 36px;

        &:hover {
            path {
                stroke: $_dark_blue
            }
        }
    }

    &_title {
        @include font-size-get(p1);
        font-weight: 500;
    }

  

    &_description {
       margin-top: 5px;
       @include font-size-get(p1);
    }


    &::before {
        content: '';
        position: absolute;
        right: 50%;
        bottom: -16px;
        background: #fff;

        border-radius: 4px;

        transform: rotate(45deg) translateX(-50%);

        width: 28px;
        height: 28px;
        
        bottom: -22px;

    }


}


#map.loading {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        background: url(../img/loading-icon.png) no-repeat;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-size: contain;
        animation: rotateLoading 2s infinite linear;
        z-index: 2;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #FFFFFF;
        opacity: 0.5;
        z-index: 1;
    }

}


@keyframes rotateLoading {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}