@import "../var";
@import "../mix";


.filter {
    background: $_gray_20;
    border-radius: 50px;

    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    
    padding: 5px;

    max-width: fit-content;

    @include respond-to(md) {
        // width: calc(100% + 16px);
        // max-width: calc(100% + 16px);
        // border-bottom-right-radius: 0;
        // border-top-right-radius: 0;
    }

    .wrap,
    .wrap > div {
        display: flex;
    }
    .wrap > div {
        @include respond-to(md) {
            // padding-right: 16px;
        }
    }

    &__btn {
        white-space: nowrap;

        display: inline-block !important;

        font-weight: 500;
        @include font-size-get(p1);
        border-radius: 50px;
        padding: 14px 20px;

        color: $_gray_40;
        background: $_gray_20;

        // width: fit-content;


        cursor: pointer;

        transition: .3s;

        &:hover {
            transition: .3s;
            color: $_main_blue;
        }

        &--active {
            background: #247cff;
            color: #fff !important;
        
        }

    }

}