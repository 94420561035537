@import "var";
@import "mix";


.arrow-icon {
    width: 40px;
    height: 40px;
    border-radius: 13px;
    background: $_gray_100;
    position: relative;
    transition: .3s ease;

    @include respond-to(lg) {
        width: 50px;
        height: 50px;
    }


    &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 30px;
        height: 30px;
        background: url(../img/icoon-btn-arrow-1.svg) no-repeat;

        @include respond-to(lg) {
            top: 10px;
            left: 10px;
            background: url(../img/icoon-btn-arrow-2.svg) no-repeat;

        }


        background-position: center !important;


    }
}

@keyframes arrowAnimation {
    0% {
        transform: rotate(0deg) translate(0, 0);
        opacity: 1;
    }

    50% {
        transform: rotate(0deg) translate(10px, 0);
        opacity: 0;
    }

    51% {
        opacity: 0;
        transform: rotate(-45deg) translate(-10px, 0px);
    }

    100% {
        opacity: 1;
        transform: rotate(-45deg) translate(0, 0);

    }
}


.download-icon {
    width: 40px;
    height: 40px;
    border-radius: 13px;
    background: $_gray_80;
    position: relative;
    transition: .3s ease;

    @include respond-to(lg) {
        width: 50px;
        height: 50px;
    }


    &::after {
        content: '';
        position: absolute;
        bottom: 10px;
        left: 9px;

        width: 23px;
        height: 9px;

        background: url(../img/download-icon-2.svg) no-repeat;

        @include respond-to(lg) {
            // top: 10px;
            // left: 10px;
            // background: url(../img/icoon-btn-arrow-2.svg) no-repeat;
            display: none;
        }


        background-position: center !important;


    }

    &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 17px;

        background: url(../img/download-icon-1.svg) no-repeat;

        left: 12px;
        top: 7px;



        background-position: center !important;
        background-size: contain;

        @include respond-to(lg) {
            width: 50px;
            height: 50px;
            top: 0;
            left: 0;
            background: url(../img/download-icon.svg);
        }


    }
}



@keyframes arrowAnimation2 {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    50% {
        transform: translate(0px, 10px);
        opacity: 0;
    }

    51% {
        opacity: 0;
        transform: translate(0px, -10px);
    }

    100% {
        opacity: 1;
        transform: translate(0, 0);

    }
}
