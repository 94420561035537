@import "../../var";
@import "../../mix";


.service-banner {
    background: #090207;
    padding-top: 182px;
    padding-bottom: 70px;
    min-height: 470px;
    overflow: hidden;
    @include respond-to(lg) {
        padding-top: 150px;
        min-height: 400px;
    
    }
    @include respond-to(md) {
        padding-top: 105px;
        padding-bottom: 0;
    }


    .cnt {
        position: relative;
        min-height: 260px;
    }

    &__title {
        color: #fff;
        @include font-size-get(h3);
        letter-spacing: -0.02em;
        font-weight: 500;

    }

    &__dsc {
        @include font-size-get(p2);
        color: $_gray_60;
        margin-top: 30px;
        @include respond-to(md) {
            margin-top: 14px;
        }
    }

    &__img {
        width: 555px;
        height: 400px;
        position: absolute;
        right: -30px;
        bottom: -70px;

        @include respond-to(lg) {
            width: 442px;
            height: 352px;
            right: 0;
            bottom: -104px;

        }

        @include respond-to(md) {
            width: 100%;
            height: 275px;
            right: unset;
            bottom: unset;
            position: relative;
            margin-top: 28px;
            
            margin-left: -16px;
            margin-right: -16px;
            width: calc(100% + 32px);
        }

        img {
            display: block;
            object-fit: contain;
            object-position: bottom right; 
            width: 100%;
            height: 100%;
            @include respond-to(md) {
                object-position: bottom center;
            }
        }
    }

}
