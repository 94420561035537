@import "../../var";
@import "../../mix";

.advantages-work-card {
   
    &__img {
        width: 100%;
        height: 250px;
        border-radius: 15px;
        overflow: hidden;
        @include respond-to(lg) {
            height: 334px;
        }
        @include respond-to(md) {
            height: 290px;
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        @include font-size-get(p2);
        font-weight: 500;
        margin-top: 25px;
      
        @include respond-to(md) {
            margin-top: 16px;
        }

    }
    &__dsc {
        margin-top: 10px;
        @include font-size-get(p1);
        color: $_gray_80;
        @include respond-to(md) {
            margin-top: 8px;
        }
    }
}

.white_small_img {
    .advantages-work-card__img {
        width: 80px;
        height: 80px;
        @include respond-to(lg) {
            width: 76px;
            height: 76px;
        }
        @include respond-to(md) {
            width: 72px;
            height: 72px;
        }

    }
}