@import "var";
@import "mix";



.mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 1200;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    -webkit-overflow-scrolling: touch;


    z-index: 9999;


    padding: 16px;

    padding-top: 0;


    display: none;

    // opacity: 1;
    // visibility: visible;


    background: #1D1D1D;

    &::after {
        content: '';
        position: fixed;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: url(../img/mobile-menu-bg.png) no-repeat;
        background-position: right bottom;
        background-size: cover;
        z-index: 1;
    }

    .mobile-menu-header {
        display: flex;
        z-index: 2;
        margin-bottom: 70px;

        height: 48px;
        position: relative;
    }



    .close {
        width: 40px;
        height: 40px;
        // transform: translate(15px,-15px);
        display: flex;
        align-items: center;


        position: absolute;

        top: 4px;

        transform: unset;
        right: 0px;


    }

}

.menu-content {
    display: flex;
    flex-direction: column;
    z-index: 2;
    position: relative;

    &__link {
        @include font-size-get(p4);
        letter-spacing: -0.01em;
        color: #FFFFFF;
        margin-bottom: 36px;

        svg {
            transition: .3s ease;
        }

        &-services {
            display: flex;
            align-items: center;

            svg {
                margin-left: 8px;
            }
        }

        &--small {
            @include font-size-get(p2);
            color: $_gray_20;
            margin-bottom: 24px;

        }

    }

    &__dropdown {
        margin-top: -10px;
        margin-bottom: 10px;
        display: none;
    }
}

.open {
    opacity: 1 !important;
    transition: .3s;
    visibility: visible;
    display: block;

    .menu-content .menu-content__link {
        animation-duration: .6s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-name: fadeInUp;



    }


}



@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.menu-content__link.active {
    svg {
        transform: rotate(180deg);
    }
}