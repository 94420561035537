@import "../var";
@import "../mix";

.form-audit {
    margin-top: 80px;   
    @include respond-to(lg) {
        margin-top: 60px;
    }
}
.form-audit,
.form-feedback {
    &__title {
        @include font-size-get('p2');
        font-weight: 500;
        margin-bottom: 30px;
    }
    .input-wrap,
    .textarea-wrap {
        padding-right: 90px;
        position: relative;
        @include respond-to(lg) {
            padding-right: 70px;
        }
        @include respond-to(md) {
            padding-right: 0;
        }
    }
    &__dsc {
        font-size: 12px;
        line-height: 16px;
        color:  $_gray_40;
        transform: translate(0px, -5px);

    }
}

.form-button-wrap {
    @include flex-c;


    @include respond-to(md) {
        align-items: unset;
        flex-wrap: wrap;
    }


    .button {
        @include respond-to(md) {
            width: 100%;
            text-align: center;
            max-width: 100%;
            margin-top: 40px;
        }
    }

    span {
        padding-left: 20px;
        font-size: 12px;
        line-height: 16px;
        color:  $_gray_60;
        max-width: 282px;

        @include respond-to(md) {
            padding-left: 0;
            margin-top: 10px;
            max-width: 260px;
        }

        a {

            color: $_main_blue;
            transition: .3s ease;

            &:hover {
                color: $_dark_blue;
                transition: .3s ease;
            }
        }
    }
}


.modal {
    .form-audit__title {
        @include font-size-get(p1);
        font-weight: 500;
        margin-bottom: 20px;
        @include respond-to(md) {
            margin-bottom: 24px;
        }
      
    }
    .form-audit {

        margin-top: 40px;
      
    }
    .input-wrap,
    .textarea-wrap {
        margin-bottom: 18px;
        @include respond-to(lg) {
            margin-bottom: 26px;
        }
        @include respond-to(md) {}
    }

 
    .form-audit .input-wrap, .form-audit .textarea-wrap {
        padding-right: 20px;
        @include respond-to(md) {
            padding-right: 0;
        }
    }
    .form-button-wrap .button {
        @include respond-to(md) {
            margin-top: 10px;
        }
    }
    .input,.textarea {
        @include font-size-get(p1);

    }
    .textarea {
        min-height: 38px;
        padding-bottom: 0;
        @include respond-to(md) {
            height: 32px;
        }
    }
    .form-button-wrap {
        margin-top: 10px;
    }
    .col-xs-4 {
        padding-left: 30px;
        padding-left: 34px;
        .form-audit__title {
            margin-bottom: 30px;
            @include respond-to(lg) {
                margin-bottom: 26px;
            }
            @include respond-to(md) {
                margin-bottom: 30px;
            }
        }
        @include respond-to(lg) {
            padding-left: 14px;
        }
        @include respond-to(md) {
            padding-left: 10px;
            margin-top: 24px;
        }
    }

    
}