@import "../../var";
@import "../../mix";


.tariff-calc+.table {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.table-wrap {
    @include respond-to(md) {
        overflow-x: auto;
        margin-right: -16px;
        padding-right: 16px;
        margin-left: -16px;
        padding-left: 16px;
    }
}

.table {
    background: $_gray_20;
    border-radius: 15px;
    padding-bottom: 50px;
    padding-top: 40px;
    overflow: hidden;

    @include respond-to(lg) {
        padding-bottom: 36px;
    }

    @include respond-to(md) {
        padding-left: 16px;
        padding-right: 16px;
        width: 500px;
        padding-top: 0;
        margin-top: 32px;
        padding-bottom: 30px;
    }

    &-row {
        display: flex;

        @include respond-to(md) {
            flex-wrap: wrap;
        }
    }

    &-col {
        &:first-child {
            flex-basis: 35.5%;
            max-width: 35.5%;
            padding-left: 40px;
            padding-right: 20px;

            @include respond-to(lg) {
                padding-left: 18px;
                padding-right: 10px;
            }

            @include respond-to(md) {
                flex-basis: 100%;
                max-width: 100%;
                text-align: center;

                span {
                    padding: 20px 0;
                    font-weight: 500;
                }
            }
        }

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            flex-basis: calc(64.5% / 3);
            max-width: calc(64.5% / 3);
            text-align: center;
            padding: 0 30px;
            border-left: 1px solid #BFBFBF;

            @media (max-width: 1200px) {
                padding: 0 15px;
            }

            @include respond-to(lg) {
                padding: 0 8px;
            }

            @include respond-to(md) {
                flex-basis: 156px;
                max-width: 156px;
                border-left: unset;

                border-top: 1px solid #BFBFBF;
                border-bottom: 1px solid #BFBFBF;

                display: flex;
                align-items: center;
                justify-content: center;


            }

        }

        &:nth-child(3) {
            @include respond-to(md) {
                border-left: 1px solid #BFBFBF;
                border-right: 1px solid #BFBFBF;
            }
        }

        &:nth-child(4) {
            margin-right: 30px;

            @include respond-to(lg) {
                margin-right: 20px;
            }

            @include respond-to(md) {
                margin-right: 0;
            }
        }

        span {
            padding: 15px 0;
            font-size: 16px;
            line-height: 20px;

            @include respond-to(lg) {
                font-size: 14px;
                line-height: 18px;

            }

        }

        svg {
            margin-top: 12px;

            @include respond-to(md) {
                margin: 16px 0;
            }
        }

        .button {
            margin-top: 12px;
            width: 100%;
            white-space: pre-wrap;

            @include respond-to(lg) {
                font-size: 13px;
                line-height: 18px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @include respond-to(md) {
                margin-top: 20px;
            }
        }

    }

    &-header {
        .table-col {
            padding-bottom: 22px;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                
            }

            &:first-child {
                @include respond-to(md) {
                    display: none;
                }
            }
            @include respond-to(md) {
                border-top: unset;
                padding: 20px 10px;
            }
        }



        span {
            padding: 0;
            font-size: 22px;
            line-height: 28px;

            letter-spacing: -0.01em;

            @include respond-to(lg) {
                font-size: 16px;
                line-height: 21px;
            }

            @include respond-to(md) {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: $_main_blue;
              
            }

        }
    }
    &-footer {
        .table-col {
            @include respond-to(md) {
                border-top: unset;
                border-bottom: unset;
            }
        }
    }


}

.table-row:not(.table-footer):not(.table-header) {
    &:hover {
        @media (min-width: 1024px) {
            background: #E4E6ED;

        }
    }
}

.table-title {
    font-size: 24px;
line-height: 28px;
margin-top: 48px;
display: none;
@include respond-to(md) {
    display: block;
}
}