@import "../../var";
@import "../../mix";

.pagination {
    display: flex;
    align-items: center;
    margin-top:60px;

    @include respond-to(lg) {
        margin-top: 20px;
    }
    @include respond-to(md) {
        margin-top: 0;
    }

    .disabled {
        display: none;
    }

    &__item {
        margin-right: 2px;

        a,
        span {
            width: 50px;
            height: 50px;
            border-radius: 13px;
            @include flex-c-c;
            @include font-size-get(p5);
            font-weight: 500;
            cursor: pointer;

        }

        &--active {

            a,
            span {
                background: $_gray_20;
                color: $_main_blue;
            }
        }
    }

    &__prev,
    &__next {
        a {
            display: flex;
            align-items: center;
            @include font-size-get(p1);
            font-weight: 500;
            color: $_main_blue;
        }

    }
    &__prev {
        margin-right: 15px;
svg {
margin-right: 5px;
}
    }
    &__next {
            margin-left: 15px;
            svg {
                margin-left: 5px;
            }
    }

}
