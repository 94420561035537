@import "../../var";
@import "../../mix";



.news-count {
    @include font-size-get(h2);
    letter-spacing: 0.04em;
    text-transform: uppercase;
    display: block;
    color: #979797;

}

.news-title {
    @include font-size-get(h5);
    letter-spacing: -0.02em;
    margin-top: 10px;
    font-weight: 400;
}

.news-item {

    margin-bottom: 40px;


    &:hover {
        .news-item__title {
            color: $_main_blue;
            transition: .3s;
        }
    }

    @include respond-to(lg) {
        margin-bottom: 36px;
    }

    &__img {
        height: 170px;
        display: block;

        @include respond-to(lg) {
            height: 227px;
        }

        @include respond-to(md) {
            height: 197px;
        }

        img {
            width: 100%;
            object-fit: cover;
            display: block;
            height: inherit;
            border-radius: 15px;

        }
    }

    &__cat {
        @include font-size-get(p1);
        margin-top: 10px;
        font-weight: 500;
        color: $_main_blue;
        margin-top: 15px;
        display: inline-block;
    }

    &__title {
        @include font-size-get(p2);
        font-weight: 500;
        margin-top: 8px;
        display: block;
        transition: .3s;

    }

    &__date {
        @include font-size-get(p1);
        margin-top: 12px;
        color: $_gray_40;
        display: flex;
        align-items: center;
        svg {
            margin: 0 5px;
        }
    }
}
