@import "../../var";
@import "../../mix";

.person-banner {

    background: #090207;
    padding-top: 154px;
    padding-bottom: 60px;

    @include respond-to(lg) {
        padding-top: 118px;
        padding-bottom: 48px;
    }
    @include respond-to(md) {
        padding-bottom: 0;
        padding-top: 92px;
    }

    .cnt {
        position: relative;
    }


    &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 320px;
        z-index: 2;
        position: relative;
    
        @include respond-to(lg) {
            min-height: 280px;
        }
        @include respond-to(md) {
            display: block;
            min-height: unset
        }

    }




    &__name {
        @include font-size-get(h4);
        font-weight: 500;
        letter-spacing: -0.03em;
        margin-top: 15px;
        color: #fff;
    }

    &__dsc {
        color: $_gray_20;
        @include font-size-get(p1);
        margin-top: 30px;
        max-width: 496px;
        margin-bottom: 40px;
        @include respond-to(lg) {
            margin-top:20px;
        }
        @include respond-to(md) {
            margin-top: 12px;
        }
    }

    &__phone {
        @include font-size-get(p2);
        font-weight: 500;
        color: $_main_blue;
        transition: .3s;
        display: flex;
        &:hover {
            transition: .3s;
            color: $_dark_blue;
        }
    }

    &__img {
        width: 465px;
        height: 460px;
        position: absolute;
        right: 40px;
        bottom: -60px;

        @include respond-to(lg) {
            width: 394px;
            height: 390px;
            bottom: -48px;
        }
        @include respond-to(md) {
            position: relative;
            width: 257px;
            height: 254px;
            bottom: unset;
            right: unset;
            margin-top: 16px;
        }

        img {
            display: block;
            object-fit: contain;
            object-position: bottom;
            width: 100%;
            height: 100%;
        }


    }
}
