@import "../mix.scss";
@import "../var.scss";



.policy-title {
    @include font-size-get(h5);
    letter-spacing: -0.02em;
    font-weight: 400;
    padding-right: 40px;
    margin-bottom: 50px;

}


.information-block {
    &__title {
        @include font-size-get('p3');
        font-weight: 700;
        margin-bottom: 30px;
        @include respond-to(md) {
            margin-bottom: 24px;
        }

        +p {
            margin-top: 30px;

            @include respond-to(lg) {
                margin-top: 24px;
            }
        }
    }

    p {
        @include font-size-get('p3');

        

        +p {
            margin-top: 22px;

            @include respond-to(xl) {
                margin-top: 20px;
            }

        }

        span {
            // color: $_blue;
        }
    }
}

.information-block {
    +.information-block {
        margin-top: 52px;



        @include respond-to(lg) {
            margin-top: 40px;
        }

    }
}




.information-block-nav {
    padding-right: 100px;
    @include respond-to(md) {
        padding-right: 0;
        background: #F4F4F4;
        box-shadow: 0px 7px 12px rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        position: absolute;
        top: 62px;

        padding: 12px;


        display: none;
    }
    &__item {

        @include font-size-get('p1');
        font-weight: 500;
        margin-bottom: 20px;
        color: $_gray_80;

        &:hover {
            color: $_dark_blue;
        }

        &-active {
            color: $_main_blue;
        }

        @include respond-to(md) {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }

    }
}

.privacy-nav {
   

    @media (min-width: 767px) {
        top: 90px;
        position: sticky;
    }
}


.policy-filter {
    position: relative;

    @include respond-to(md) {
        margin-bottom: 32px;
    }

    .policy-filter__item {
        display: none;
        @include respond-to(md) {
            display: block;
            border-bottom: 1px solid #BFBFBF;
            font-size: 14px;
            line-height: 18px;
            color: #535354;
            padding-bottom: 10px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 22px;
                height: 22px;
                right: -4px;
                top: -2px;
                background: url(../img/policy-arrow.svg) no-repeat center;
            }

        }
    }
}

.information-block-nav--show {
    display: block;
}


.policy-filter__item {
    &::after {
        transition: .3s;
    }
}

.policy-filter__item.active {
    &::after {
        transform: rotate(180deg);
        transition: .3s;
    }
}