@import "../var";
@import "../mix";

.how-works-block {
    border-top: 1px solid $_gray_40;
    margin-top: 80px;

    @include respond-to(lg) {
        margin-top: 60px;
    }
}

.how-works-item {
    display: flex;
    position: relative;
    border-bottom: 1px solid $_gray_40;
    cursor: pointer;

    &--active {
        .how-works-item__title {
            color: #ff5e3b;
            transition: .3s ease;
        }

        .how-works-item__count {
            color: #ff5e3b;
            transition: .3s ease;

        }

        .how-works-item__icon {
            path {
                fill: #ff5e3b;
                transition: .3s ease;
            }

            svg {
                transition: .3s ease;
                transform: rotate(180deg);
            }
        }

    }

    .col {
        &:first-child {
            flex-basis: 25%;
            max-width: 25%;
            padding-top: 43px;
            padding-bottom: 33px;
            @include respond-to(lg) {
                padding-top: 34px;
            }
            @include respond-to(md) {
                flex-basis: 34px;
                max-width: 34px;
            }
        }

        &:last-child {
            flex-basis: 75%;
            max-width: 75%;
            padding-top: 30px;
            padding-bottom: 40px;
            @include respond-to(lg) {
                padding-top: 22px;
                padding-bottom: 34px;
            }
            @include respond-to(md) {
                flex-basis: calc(100% - 34px);
                max-width: calc(100% - 34px);
                padding-top: 25px;
                padding-bottom: 6px;

            }
        }
    }

    &__count {
        font-weight: 500;
        @include font-size-get(p1);
        transition: .3s ease;
    }

    &__title {
        @include font-size-get(p4);
        letter-spacing: -0.01em;
        padding-bottom: 15px;
        padding-right: 40px;
        transition: .3s ease;

    }

    &__icon {
        position: absolute;
        right: 0;
        top: 42px;
        @include respond-to(lg) {
            top: 32px
        }
    }


    &__dsc {
        @include font-size-get(p1);
        color: $_gray_60;
        padding-right: 40px;
        max-width: 740px;
        display: none;
        @include respond-to(md) {
            margin-bottom: 14px;
        }
    }

    path,
    svg {
        transition: .3s ease;

    }

}