@import "../var";
@import "../mix";



.input-wrap {
    margin-bottom: 28px;
    position: relative;
    @include respond-to(md) {
        margin-bottom: 36px;
    }

    .error {
        position: absolute;
        bottom: -14px;
        left: 0;
        font-size: 12px;
        line-height: 12px;
        font-style: normal;
        color: $_red;
    }

}

.textarea-wrap {
    margin-bottom: 40px;
    @include respond-to(lg) {
        margin-bottom: 56px;
    }
}

.input,
.textarea {
    width: 100%;
    border: unset;
    border-radius: unset;
    border-bottom: 1px solid #BFBFBF;
    padding: 9px 0;
    @include font-size-get('p2');
    color: $_gray_100;

    @include respond-to(md) {
        padding: 7px 0;
    }

    &::placeholder {
        color: $_gray_40;
    }
}

.textarea {
    resize: unset;
    height: 45px;
    overflow: hidden;
    @include respond-to(md) {
        height: 36px;
    }
}

.inputError {
    border-bottom: 1px solid $_red;
    &::placeholder {
        color: $_red;
    }
}