@import "../../var";
@import "../../mix";

@import "../../var";
@import "../../mix";

.article {
    &-banner {
        height: 450px;

        @include respond-to(lg) {
            height: 292px;
        }

        @include respond-to(md) {
            height: 197px;
        }

        img {
            width: 100%;
            height: inherit;
            display: block;
            object-fit: cover;
        }
    }


    &-content-cnt {
        max-width: 767px;
        margin: 0 auto;
        margin-top: 50px;

        @include respond-to(lg) {
            margin-top: 40px;
            max-width: 600px
        }
    }

    &__cat {
        @include font-size-get(p2);
        font-weight: 500;
        color: $_main_blue;
        display: block;
    }

    &__title {
        @include font-size-get(h4);
        letter-spacing: -0.03em;
        margin-top: 20px;
        font-weight: 500;

        @include respond-to(lg) {
            margin-top: 16px;
        }
    }

    &__date {
        @include font-size-get(p1);
        color: $_gray_40;
        display: block;
        margin-top: 24px;

        @include respond-to(md) {
            margin-top: 20px;
        }
    }

    &__content {
        margin-top: 40px;

        @include respond-to(lg) {
            margin-top: 32px;
        }

        ul {
            list-style: none;
            margin: 20px 0;

            li {
                position: relative;
                padding-left: 20px;
                @include font-size-get('p3');
                @include respond-to(md) {
                    padding-left: 18px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $_black;
                    top: 10px;
                    left: 5px;
                    @include respond-to(lg) {
                        top: 8px;
                    }
                }
            }
        }

        h2 {
            &+p {
                margin-top: 20px;
            }
        }

        p {
            @include font-size-get('p3');

            &+p {
                margin-top: 40px;

                @include respond-to(lg) {
                    margin-top: 32px;
                }
            }
        }

        img {
            width: 100%;
            display: block;
            margin-top: 40px;

            &+p,
            &+h2 {
                margin-top: 40px;

                @include respond-to(lg) {
                    margin-top: 32px;
                }
            }

            @include respond-to(lg) {
                margin-top: 32px;
            }
        }
    }

    .img-1row {
        margin-bottom: 20px;
    }

    .img-2row {
        display: flex;
        margin: 0 -10px;
        margin-bottom: 20px;
        img {
            padding: 0 10px;
            flex-basis: 50%;
            max-width: 50%;
        }
    }

}


.more-news {
    margin-top: 120px;

    @include respond-to(lg) {
        margin-top: 100px;
    }

    @include respond-to(md) {
        margin-top: 80px;
    }

    .row {
        margin-top: 28px;

        @include respond-to(md) {
            margin-top: 36px;
        }
    }
}

.article-nav {
    @include flex-c-sb;
    margin-top: 90px;

    @include respond-to(lg) {
        margin-top: 76px;
    }

    @include respond-to(md) {
        margin-top: 48px;

    }

    a {
        display: flex;
        align-items: center;
        @include font-size-get(p1);
        font-weight: 500;

        transition: .3s;

        path {
            transition: .3s;
        }

        &:hover {
            color: $_main_blue;
            transition: .3s;

            path {
                transition: .3s;
                fill: $_main_blue
            }
        }

        @include respond-to(md) {
            font-size: 12px;
            line-height: 16px;

        }
    }

    &__prev {
        svg {
            margin-right: 5px;
        }
    }

    &__next {
        svg {
            margin-left: 5px;
        }
    }
}
