@font-face {
    font-family: 'Suisse Int';
    src: url('../fonts/SuisseIntl-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int';
    src: url('../fonts/SuisseIntl-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Suisse Int';
    src: url('../fonts/SuisseIntl-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

